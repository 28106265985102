import React from 'react';
import clsx from 'clsx';
import { forceLayout } from 'js/helpers/dom';
import styles from './Collapsable.module.css';

interface Props {
  isVisible: boolean;
  children: React.ReactNode;
}

interface State {
  isMounted: boolean;
}

export class Collapsable extends React.PureComponent<Props, State> {
  public state: State = {
    isMounted: false,
  };

  private containerRef: React.RefObject<HTMLDivElement> = React.createRef();

  private childContainerRef: React.RefObject<
    HTMLDivElement
  > = React.createRef();

  public componentDidMount(): void {
    this.applyCurrentVisibilityStyle();
    this.setState({ isMounted: true });
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.isVisible !== this.props.isVisible) {
      this.updateCollapse(!this.props.isVisible);
    }
  }

  private updateCollapse(isCollapsed: boolean): void {
    if (!this.containerRef.current || !this.childContainerRef.current) {
      return;
    }

    if (isCollapsed) {
      this.containerRef.current.style.height = `${this.childContainerRef.current.clientHeight}px`;
      forceLayout(this.containerRef.current);
      this.containerRef.current.style.height = '0';
    } else {
      this.containerRef.current.style.height = `${this.childContainerRef.current.clientHeight}px`;
    }
  }

  private onTransitionEnd = (): void => {
    if (!this.containerRef.current) {
      return;
    }

    this.applyCurrentVisibilityStyle();
  };

  private applyCurrentVisibilityStyle(): void {
    if (!this.containerRef.current) {
      return;
    }

    this.containerRef.current.style.height = this.props.isVisible
      ? 'auto'
      : '0';
  }

  public render(): React.ReactNode {
    return (
      <div
        ref={this.containerRef}
        className={clsx(styles.container, {
          [styles.expanded]: !this.state.isMounted && this.props.isVisible,
          [styles.collapsed]: !this.state.isMounted && !this.props.isVisible,
        })}
        onTransitionEnd={this.onTransitionEnd}
      >
        <div ref={this.childContainerRef} className={styles.childrenContainer}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
