import React from 'react';
import { Text } from '@treatwell/ui';
import styles from './VenueTypeLinks.module.css';

interface Props {
  heading: string;
  venueTypes: {
    label: string;
    uri: string;
  }[];
  location: {
    label: string;
    uri: string;
  };
}

export function VenueTypeLinks(props: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <Text as="h2" type="caption" className={styles.heading}>
        {props.heading}
      </Text>
      <Text as="p" type="caption" className={styles.typeBrowsePages}>
        {props.venueTypes.map(venueType => (
          <a href={venueType.uri} key={venueType.uri}>
            {venueType.label}
          </a>
        ))}
      </Text>
      <a href={props.location.uri} className={styles.locationBrowsePage}>
        <Text type="caption">{props.location.label}</Text>
      </a>
    </div>
  );
}
