import { AccoladeOutput } from 'js/model/rainbow/browse-page/AccoladeOutput';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import { LocationPoint } from 'js/model/rainbow/LocationPoint';
import { PlatformUriOutput } from 'js/model/rainbow/PlatformUriOutput';
import { AmenityGroup } from 'js/model/rainbow/venue/AmenityGroup';
import { VenueOpeningHoursOutput } from 'js/model/rainbow/venue/VenueOpeningHoursOutput';
import { VenueMenuItemTypeOutput } from 'js/model/rainbow/venue/VenueMenuItemTypeOutput';
import { EmployeeOutput } from 'js/model/rainbow/venue/EmployeeOutput';
import { VenueTypeOutput } from 'js/model/rainbow/venue/VenueTypeOutput';
import { DistanceOutput } from 'js/model/rainbow/DistanceOutput';
import { TreatmentBadgeOutput } from 'js/model/rainbow/TreatmentBadgeOutput';
import { Address } from '../Address';
import { PriceRangeOutput } from '../PriceRangeOutput';
import { TreatmentCategoryGroupItem } from '../TreatmentCategoryGroupOutput';

export enum VenueStatusType {
  LIVE = 'live',
  CLOSED = 'closed',
  CREATED = 'created',
  DUPLICATE = 'duplicate',
  PENDING = 'pending',
  WAITING_REVIEW = 'waiting_review',
  TEMPORARILY_DISABLED = 'temporarily_disabled',
  DISABLED = 'disabled',
  ARCHIVED = 'archived',
  CHURNED = 'churned',
}

export enum LocationType {
  neighborhood = 'neighborhood',
  area = 'area',
  place = 'place',
  postalArea = 'postal_area',
  city = 'city',
  region = 'region',
  country = 'country',
  multiNational = 'multi_national',
}

export interface BaseVenueOutput {
  id: number;
  name: string;
  description?: string;
  primaryImage: ImageOutput;
  images: ImageOutput[];
  location: {
    address: Address;
    map: {
      lat: number;
      lon: number;
      zoom: number;
    };
    point: LocationPoint;
    tree: {
      countryCode: string;
      id: number;
      name: string;
      normalisedName: string;
      point: {
        lat: number;
        lon: number;
      };
      radius: DistanceOutput;
      type: LocationType;
    };
  };

  chain?: VenueChain;

  rating: VenueRating;

  // This needs to be optional until the api response from rainbow is finished
  // otherwise the pact tests fail. Once this is done, the property should be moved
  // to mandatory again.
  amenityGroups?: AmenityGroup[];

  uri: PlatformUriOutput;

  type?: VenueTypeOutput;

  subTypes?: VenueTypeOutput[];

  accolades: AccoladeOutput[];

  openingHours: VenueOpeningHoursOutput[];

  treatmentBadges: TreatmentBadgeOutput[];

  priceRange?: PriceRangeOutput;

  // A Facebook Business Extension (FBE) pixel ID.
  // It will only be present for venues that are
  // configured for FBE.
  pixelId?: string;
  widgetSettings?: {
    treatwellBrandedWidgetEnabled: boolean;
    widgetEnabled: boolean;
  };
}

export interface VenueBrowseOutput extends BaseVenueOutput {
  menuHighlights: VenueMenuItemTypeOutput[];
  newVenue: boolean;
}

export interface Menu {
  highlights?: MenuGroup;
  menuGroups: MenuGroup[];
  treatmentCategoryGroups?: TreatmentCategoryGroupItem[];
}

export interface VenueOutput extends BaseVenueOutput {
  normalisedName: string;
  employees: EmployeeOutput[];
  menu: Menu;
}

export interface MenuGroup {
  id: number;
  name: string;
  menuItems: VenueMenuItemTypeOutput[];
  priceRange: PriceRangeOutput;
  description?: string;
  treatmentCategoryGroupId?: number;
}

export interface VenueRating {
  average: number;
  count: number;
  dimensions?: {
    name: string;
    average: number;
    count: number;
  }[];
  displayAverage?: string;
}

export interface VenueChain {
  id: number;
  name: string;
  uri: {
    desktopUri: string;
  };
}
