import React from 'react';
import { ProgressBar } from 'js/components/generic/ProgressBar';
import { Checkbox } from 'js/components/form/Checkbox/Checkbox';
import { Rating } from '@treatwell/ui';
import styles from './RatingCheckbox.module.css';

interface Props {
  checked: boolean;
  rating: string;
  count: number;
  totalReviews: number;
  onChange: (isChecked: boolean) => void;
}

export function RatingCheckbox({
  rating,
  checked,
  count = 0,
  totalReviews,
  onChange,
}: Props): React.ReactElement {
  const showMin = count > 0;
  const ratingLabel: React.ReactNode = (
    <>
      <Rating rating={rating} size={16} showRatingValue={false} />
      <div className={styles.progressBar}>
        <ProgressBar val={count} max={totalReviews} showMin={showMin} />
      </div>
      <div className={styles.count}>{count}</div>
    </>
  );

  return (
    <div className={styles.ratingContainer}>
      <Checkbox
        isChecked={checked}
        name={rating}
        label={ratingLabel}
        onChange={onChange}
      />
    </div>
  );
}
