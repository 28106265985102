import React, { ReactNode } from 'react';

import clsx from 'clsx';
import styles from './CollapseText.module.css';

interface Props {
  children: string;
  collapseLabel: string;
  expandLabel: string;
  onExpand?: () => void;
}

interface State {
  collapsed: boolean;
  showMoreLink: boolean;
}

export class CollapseText extends React.PureComponent<Props, State> {
  private content: HTMLDivElement | null = null;

  private container: HTMLDivElement | null = null;

  public state = {
    collapsed: true,
    showMoreLink: false,
  };

  public componentDidMount(): void {
    window.addEventListener('resize', () => {
      this.forceUpdate();
    });
    this.componentUpdate();
  }

  public componentDidUpdate(): void {
    this.componentUpdate();
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', () => {
      this.forceUpdate();
    });
  }

  private componentUpdate(): void {
    if (!this.content || !this.container) {
      return;
    }

    if (
      this.state.collapsed &&
      !this.state.showMoreLink &&
      this.content.scrollHeight > this.container.offsetHeight
    ) {
      this.setState({ showMoreLink: true });
      this.forceUpdate();
    }
  }

  private toggleText = (): void => {
    this.setState(
      {
        collapsed: !this.state.collapsed,
      },
      () => {
        if (this.props.onExpand) {
          this.props.onExpand();
        }
      }
    );
  };

  public render(): ReactNode {
    const classes = clsx(styles.collapse, {
      [styles.expanded]: !this.state.collapsed,
    });

    const showMoreLinkText: string = this.state.collapsed
      ? this.props.expandLabel
      : this.props.collapseLabel;

    const readMeStyle = { display: this.state.showMoreLink ? 'block' : 'none' };

    return (
      <div
        className={classes}
        ref={(ref: HTMLDivElement) => {
          this.container = ref;
        }}
      >
        <div
          className={styles.content}
          ref={(ref: HTMLDivElement) => {
            this.content = ref;
          }}
          dangerouslySetInnerHTML={{ __html: this.props.children }}
        />
        <span className={styles.readMore} style={readMeStyle}>
          {this.state.collapsed ? <span>...</span> : null}
          <span className={styles.text} onClick={this.toggleText}>
            {showMoreLinkText}
          </span>
        </span>
      </div>
    );
  }
}
