import React from 'react';
import clsx from 'clsx';
import { LazyImage } from '@treatwell/ui';
import styles from './GalleryImage.module.css';

interface Props {
  imageUrl: string;
  isSelected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const GalleryImage = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>) => {
    const { imageUrl, isSelected = false, onClick, ...rest } = props;
    return (
      <div className={styles.container} onClick={onClick} {...rest} ref={ref}>
        <LazyImage
          className={clsx(styles.image, {
            [styles.selected]: isSelected,
          })}
          src={imageUrl}
        />
      </div>
    );
  }
);
