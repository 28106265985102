import React from 'react';

function createRootElementWithId(id: string): HTMLDivElement {
  const rootElement = document.createElement('div');
  rootElement.setAttribute('id', id);
  return rootElement;
}

export function useContainer(id: string): HTMLDivElement {
  const containerRef = React.useRef<HTMLDivElement>();

  React.useLayoutEffect(() => {
    const existingRootElement = document.getElementById(id);
    const rootElement = existingRootElement ?? createRootElementWithId(id);

    if (!existingRootElement && document.body.lastElementChild) {
      document.body.insertBefore(
        rootElement,
        document.body.lastElementChild.nextElementSibling
      );
    }

    if (containerRef.current) {
      rootElement.appendChild(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.remove();
      }
      if (!rootElement.childNodes.length) {
        rootElement.remove();
      }
    };
  }, []);

  if (!containerRef.current) {
    containerRef.current = document.createElement('div');
  }

  return containerRef.current;
}
