import React from 'react';
import {
  Stack,
  Inline,
  Text,
  Viewport,
  Rating,
  StarSize,
  useDevice,
} from '@treatwell/ui';

import { createBestTreatmentsBadgeLabel } from 'js/helpers/btt';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import { CmsCommon } from 'js/model/cms/cms-common';
import {
  BestTreatmentsBadge,
  BestTreatmentsBadgeStyle,
} from 'js/components/BestTreatmentsBadge';

import { choiceFormatter } from 'js/helpers/choice-formatter';
import { scrollToElement } from 'js/helpers/dom';
import { CmsVenuePage } from 'js/model/cms/cms-venue-page';
import { Actions } from 'js/pages/VenuePage/VenueHeader/Actions/Actions';
import styles from './style.module.css';

interface Props {
  bttLabels: CmsCommon['labels'];
  reviewCountTemplate: string;
  venue: VenueOutput;
  cmsHeaderNavigation: CmsVenuePage['page']['venue']['header-navigation'];
  hasReviews: boolean;
  isActiveVenue?: boolean;
}

export function Details1(props: Props): React.ReactElement {
  const { bttLabels, reviewCountTemplate, venue, isActiveVenue } = props;
  const { isMobile } = useDevice();

  function venueName(): React.ReactElement {
    return (
      <>
        <Viewport serverRender={isMobile} device="mobile">
          <Text type="lgHeader" as="h1" className={styles.name}>
            {venue.name}
          </Text>
        </Viewport>
        <Viewport serverRender={!isMobile} device={['desktop', 'tablet']}>
          <Text type="smHero" as="h1" className={styles.name}>
            {venue.name}
          </Text>
        </Viewport>
      </>
    );
  }

  function actionButtons(): React.ReactElement | null {
    if (!isActiveVenue) {
      return null;
    }

    return (
      <div className={styles.actions}>
        <Actions
          cms={props.cmsHeaderNavigation}
          hasMenu={venue.menu.menuGroups.length > 0}
          hasReviews={props.hasReviews}
        />
      </div>
    );
  }

  function bestTreatmentBadge(): React.ReactNode | null {
    if (!isActiveVenue) {
      return null;
    }

    const bestTreatmentsBadgeLabel = createBestTreatmentsBadgeLabel(
      (venue.treatmentBadges &&
        venue.treatmentBadges.map(
          treatmentBadge => treatmentBadge.treatmentName
        )) ||
        [],
      bttLabels['btt-badge-singular'],
      bttLabels['btt-badge-plural']
    );

    return (
      bestTreatmentsBadgeLabel !== undefined && (
        <BestTreatmentsBadge
          label={bestTreatmentsBadgeLabel}
          style={BestTreatmentsBadgeStyle.Solid}
          tooltip={bttLabels['btt-badge-tooltip']}
        />
      )
    );
  }

  function scrollToReviews(): void {
    scrollToElement('#reviews');
  }

  function reviewsSummary(size: StarSize): React.ReactElement | null {
    if (!isActiveVenue) {
      return null;
    }

    const reviewCountClassname =
      venue.rating.count > 0 ? styles.reviewCount : styles.reviewCountZero;

    return (
      <Inline align="center" space="xs">
        <Rating
          size={size}
          rating={venue.rating.displayAverage || '0'}
          showRatingValue
        />

        <Text
          type="smHeader"
          className={reviewCountClassname}
          onClick={scrollToReviews}
        >
          {choiceFormatter(reviewCountTemplate, venue.rating.count)}
        </Text>
      </Inline>
    );
  }

  return (
    <>
      <Viewport serverRender={isMobile} device="mobile">
        <Stack space="xs">
          <Stack space="md" className={styles.stackAlignLeft}>
            {bestTreatmentBadge()}
            {venueName()}
          </Stack>

          {reviewsSummary(16)}
        </Stack>
      </Viewport>

      <Viewport serverRender={!isMobile} device={['tablet', 'desktop']}>
        <Stack space="sm">
          <Inline>{bestTreatmentBadge()}</Inline>

          <Inline space="xl" align="start" justify="between">
            {venueName()}
            {actionButtons()}
          </Inline>

          {reviewsSummary(24)}
        </Stack>
      </Viewport>
    </>
  );
}
