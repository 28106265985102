import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import get from 'lodash.get';
import { trackMenuGroupSelectEvent } from 'js/pages/VenuePage/tracking';
import {
  sortTreatmentTypes,
  filterGroupsByActiveTreatmentType,
  getPseudoTreatmentTypes,
} from 'js/helpers/venue-menu';
import clsx from 'clsx';
import { ALL_TREATMENT_TYPES_ID } from 'js/constants/treatment-types';
import {
  getVenueMenu,
  getSelectedMenuGroups,
} from 'js/redux-modules/venue-page';
import {
  unselectVenueMenuGroup,
  unselectAllVenueMenuGroups,
  selectVenueMenuGroup,
} from 'js/redux-modules/venue-page/menu-groups';
import { Inline, Stack, useDevice, useViewport } from '@treatwell/ui';
import {
  selectMenuOption,
  toggleVenueMenuService,
  setActiveTreatmentType,
  updateTreatmentTypeMenuGroupSelected,
} from 'js/redux-modules/venue-page/venue-menu';
import { CollapseText } from 'js/components/generic/CollapseText';
import {
  Menu,
  MenuGroup as MenuGroupType,
} from 'js/model/rainbow/venue/VenueOutput';
import { StateData } from 'js/model/rainbow/StateData';
import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';
import { VenueMenuItemTypeOutput } from 'js/model/rainbow/venue/VenueMenuItemTypeOutput';
import { scrollWindowTo } from 'js/helpers/animations';
import { useIsMounted } from 'js/hooks/useIsMounted';
import styles from './VenueMenu.module.css';
import { MenuGroup } from './MenuGroup/MenuGroup';
import { MenuGroupItems } from './MenuGroup/MenuGroupItems';
import { VenueMenuTitle } from './VenueMenuTitle';
import { TreatmentTypeBar } from './TreatmentTypeBar/TreatmentTypeBar';
import { hasSearchCriteria } from './searchCriteria';
import { SUGGESTED_SERVICES_MENU_GROUP_ID } from './createSuggestedServicesGroup';
import { HighlightedServices } from './HighlightedServices/HighlightedServices';

const MENU_TOP_ADJUSTMENT = 50;

type VenueMenuState = {
  filteredMenuGroups: {
    selected: number;
    groups: [
      {
        id: number;
      }
    ];
  };
  activeTreatmentTypeId: number;
};

export type Props = PropsFromRedux & {
  menu: Menu;
  isWidget: boolean;
  venueId: number;
  pageData: StateData;
  i18n: (key: string, count?: number | string) => string;
  selectedMenuGroups: {};
  venueMenu: VenueMenuState;
  selectVenueMenuGroup: {};
  unselectVenueMenuGroup: {};
  unselectAllVenueMenuGroups: {};
  selectMenuOption: {};
  toggleVenueMenuService: {};
  setActiveTreatmentType: {};
  updateTreatmentTypeMenuGroupSelected: {};
};

function VenueMenuComponent(props: Props): React.ReactElement {
  const menuNode = React.useRef<HTMLDivElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [treatmentTypes] = React.useState(getSortedTreatmentTypes());
  const {
    pageData,
    i18n,
    isWidget,
    menu,
    venueMenu: { filteredMenuGroups, activeTreatmentTypeId },
    unselectAllVenueMenuGroups,
    selectVenueMenuGroup,
  } = props;
  const isChurned = pageData.venue.venue.status === 'churned';
  const currentActiveID = activeTreatmentTypeId || ALL_TREATMENT_TYPES_ID;
  const currentGroups =
    get(filteredMenuGroups, `${currentActiveID}.groups`) || menu.menuGroups;
  const { isMobile: isMobileDevice } = useDevice();
  const isMobile = useViewport({
    device: 'mobile',
    serverRender: isMobileDevice,
  });
  const isMounted = useIsMounted();

  const isSearch = hasSearchCriteria({
    isChurned,
    pageParameters: pageData.pageParameters,
  });
  const showHighlightedServices = !isWidget || (isWidget && isSearch);

  useEffect(() => {
    unselectAllVenueMenuGroups();

    if (!isMobile) {
      const menuGroupId =
        filteredMenuGroups[activeTreatmentTypeId].selected ||
        filteredMenuGroups[activeTreatmentTypeId].groups[0].id;

      // unselect all open menuGroups then open single menuGroup
      unselectAllVenueMenuGroups();
      selectVenueMenuGroup(menuGroupId);
      trackMenuGroupSelectEvent(
        getMenuGroupById(props.menu.menuGroups, menuGroupId)!
      );
    }
  }, [
    activeTreatmentTypeId,
    filteredMenuGroups,
    isMobile,
    props.menu.menuGroups,
    selectVenueMenuGroup,
    unselectAllVenueMenuGroups,
  ]);

  function getMenuGroupById(
    menuGroups: MenuGroupType[],
    id: number
  ): MenuGroupType | undefined {
    if (id.toString() === SUGGESTED_SERVICES_MENU_GROUP_ID) {
      return ([
        {
          id,
          name: id.toString(),
          menuItems: [] as VenueMenuItemTypeOutput[],
          priceRange: {} as PriceRangeOutput,
        },
      ] as unknown) as MenuGroupType;
    }
    return menuGroups.find(menuGroup => menuGroup.id === id);
  }

  function toggleMenuGroup(id: number): void {
    if (props.selectedMenuGroups[id]) {
      props.unselectVenueMenuGroup(id);
    } else {
      props.selectVenueMenuGroup(id);
      trackMenuGroupSelectEvent(getMenuGroupById(props.menu.menuGroups, id)!);
    }
  }

  function handleMenuGroupClick(id: number): void {
    if (isMobile) {
      toggleMenuGroup(id);
    } else {
      // close all menu groups
      props.unselectAllVenueMenuGroups();
      // open the menu group that was clicked
      props.selectVenueMenuGroup(id);
      // keep track of which menuGroup is open for treatmentTypeFilter
      props.updateTreatmentTypeMenuGroupSelected(id);
      trackMenuGroupSelectEvent(getMenuGroupById(props.menu.menuGroups, id)!);

      // scroll to top of menu
      if (menuNode.current) {
        const selectedService = document.querySelector(
          '[class*="MenuGroup-module--selected--]'
        );

        const menuTop = selectedService?.getBoundingClientRect().top;

        if (menuTop && menuTop < 0) {
          const newPosY = window.scrollY - MENU_TOP_ADJUSTMENT + menuTop;

          window.scrollTo(0, newPosY);
        }
      }
    }
  }

  function getSortedTreatmentTypes(): { id: number; name: string }[] {
    const {
      menu: { treatmentCategoryGroups },
    } = props;
    const { cms } = props.pageData;

    const treatmentTypes = [
      ...getPseudoTreatmentTypes(cms.page.venue.menu),
      ...(treatmentCategoryGroups ?? []).map(({ id, name }) => ({
        id,
        name,
      })),
    ];
    const order = (cms.page.venue.menu['treatment-type-bar-order'] as string)
      .split(',')
      .map(id => parseInt(id, 10));

    return sortTreatmentTypes(treatmentTypes, order);
  }

  function handleTreatmentTypeSelected(treatmentTypeId: number): void {
    const {
      menu: { menuGroups },
      venueMenu: { activeTreatmentTypeId, filteredMenuGroups },
    } = props;
    let selectedMenuGroup;
    if (treatmentTypeId === ALL_TREATMENT_TYPES_ID) {
      selectedMenuGroup =
        get(filteredMenuGroups, `${activeTreatmentTypeId}.selected`) ||
        menuGroups[0].id;
      props.setActiveTreatmentType(
        treatmentTypeId,
        menuGroups,
        menuGroups[0].id
      );
    } else {
      const filteredGroups =
        get(filteredMenuGroups, `${treatmentTypeId}.groups`) ||
        filterGroupsByActiveTreatmentType(menuGroups, treatmentTypeId);
      selectedMenuGroup =
        get(filteredMenuGroups, `${treatmentTypeId}.selected`) ||
        filteredGroups[0].id;
      props.setActiveTreatmentType(
        treatmentTypeId,
        filteredGroups,
        selectedMenuGroup
      );
    }
    props.unselectAllVenueMenuGroups();
    // keep all menuGroups collapsed on mobile
    if (!isMobile) {
      props.selectVenueMenuGroup(selectedMenuGroup);
    }
    if (menuRef.current) {
      scrollWindowTo(menuRef.current.getBoundingClientRect().top);
    }
  }

  function renderTreatmentTypeBar(activeID: number): React.ReactNode {
    const {
      venueMenu: { selectedTreatmentTypes },
    } = props;
    const treatmentTypeCount = menu.treatmentCategoryGroups?.length ?? 0;

    return treatmentTypeCount > 1 ? (
      <TreatmentTypeBar
        selectedTreatmentTypes={selectedTreatmentTypes}
        treatmentTypes={treatmentTypes}
        activeTreatmentTypeId={activeID}
        treatmentTypeSelected={handleTreatmentTypeSelected}
        isChurned={isChurned}
      />
    ) : null;
  }

  function renderActiveGroupItems(groups: MenuGroupType[]): React.ReactNode {
    if (isMobile) {
      return null;
    }

    const { pageData, i18n, selectedMenuGroups } = props;
    const { channel } = pageData;

    const activeGroup = groups.find(group => !!selectedMenuGroups[group.id]);

    const expandLabel = i18n('common.labels.read-more');
    const collapseLabel = i18n('common.labels.read-less');

    if (!activeGroup) {
      return null;
    }

    return (
      <Stack space="sm" className={styles.menuGroupItems}>
        {!!activeGroup.description && (
          <CollapseText collapseLabel={collapseLabel} expandLabel={expandLabel}>
            {activeGroup.description}
          </CollapseText>
        )}

        <MenuGroupItems
          group={activeGroup}
          isActive
          isSuggestedGroup={
            activeGroup.id.toString() === SUGGESTED_SERVICES_MENU_GROUP_ID
          }
          channel={channel}
          trackingGroupType="venue_menu"
        />
      </Stack>
    );
  }

  function renderGroups(groups: MenuGroupType[]): React.ReactNode {
    const { i18n, selectedMenuGroups, pageData, venueMenu } = props;
    const { channel } = pageData;

    const expandLabel = i18n('common.labels.read-more');
    const collapseLabel = i18n('common.labels.read-less');
    return (
      <Stack className={styles.menuGroups}>
        {groups.map(group => (
          <MenuGroup
            key={group.id}
            group={group}
            isSuggestedGroup={
              group.id.toString() === SUGGESTED_SERVICES_MENU_GROUP_ID
            }
            isActive={!!selectedMenuGroups[group.id]}
            channel={channel}
            isSelected={
              isMounted &&
              !!venueMenu.selected[group.id] &&
              !!Object.keys(venueMenu.selected[group.id]).length
            }
            onClick={() => handleMenuGroupClick(group.id)}
            isMobile={isMobile}
            expandLabel={expandLabel}
            collapseLabel={collapseLabel}
          />
        ))}
      </Stack>
    );
  }

  const subtitle =
    isSearch && showHighlightedServices
      ? i18n('venue.menu.title.subtitle')
      : '';

  return (
    <div className={styles.venueMenus} data-cy="venue-menu">
      <HighlightedServices />
      <VenueMenuTitle
        ref={menuRef}
        title={i18n('venue.menu.title.title')}
        subtitle={subtitle}
        isWidget={isWidget}
      />
      <div>{renderTreatmentTypeBar(currentActiveID)}</div>
      <div
        className={clsx({
          [styles.widgetVenueMenu]: isWidget,
          [styles.venueMenu]: !isWidget,
        })}
        id="menu"
      >
        <div ref={menuNode}>
          <Inline space="lg" align="start" className={styles.menu}>
            {renderGroups(currentGroups)}
            {renderActiveGroupItems(currentGroups)}
          </Inline>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: VenueMenuState & {}) => ({
  selectedMenuGroups: getSelectedMenuGroups(state),
  venueMenu: getVenueMenu(state),
});

const actions = {
  selectVenueMenuGroup,
  unselectVenueMenuGroup,
  unselectAllVenueMenuGroups,
  selectMenuOption,
  toggleVenueMenuService,
  setActiveTreatmentType,
  updateTreatmentTypeMenuGroupSelected,
};

const connector = connect(mapStateToProps, actions);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const VenueMenu = connector(VenueMenuComponent);
