import { DiscountSet } from 'js/model/rainbow/SalesPriceDiscountType';
import { VenueMenuItemTypeOutput } from 'js/model/rainbow/venue/VenueMenuItemTypeOutput';
import { MenuGroup } from 'js/model/rainbow/venue/VenueOutput';

export const getGroupId = (
  services: VenueMenuItemTypeOutput[],
  serviceId: string
) => {
  const service = services.find(s => s.data.id === serviceId);

  if (service) {
    return service.originalGroupId;
  }
  return undefined;
};

export const getGroupDiscountTypes = (
  services: VenueMenuItemTypeOutput[]
): string[] => {
  const discountTypesObject = services.reduce((discountTypes, service) => {
    const result: DiscountSet = { ...discountTypes };

    for (const discountType of service.data.priceRange.yieldDiscountTypes) {
      result[discountType] = true;
    }

    return result;
  }, {});

  return Object.keys(discountTypesObject);
};

export const SUGGESTED_SERVICES_MENU_GROUP_ID = 'suggested-services';

export const getTreatmentCategoryGroupId = (
  services: VenueMenuItemTypeOutput[],
  serviceId: string
) => {
  const service = services.find(s => s.data.id === serviceId);

  if (service) {
    return service.treatmentCategoryGroupId;
  }
  return undefined;
};

export const getServices = (
  menuGroups: MenuGroup[],
  highlights?: MenuGroup
): VenueMenuItemTypeOutput[] => {
  const mergedServices: VenueMenuItemTypeOutput[] = [];
  const highlightsServiceIds: string[] = [];
  const highlightItems: VenueMenuItemTypeOutput[] = highlights?.menuItems || [];

  menuGroups.forEach(({ id, menuItems, treatmentCategoryGroupId }) => {
    menuItems.forEach(service => {
      mergedServices.push({
        ...service,
        treatmentCategoryGroupId,
        originalGroupId: id,
      });
    });
  });

  const highlightsServices: VenueMenuItemTypeOutput[] = highlightItems.map(
    service => {
      // keep track of service IDs added from the "highlights" group
      // so we can remove them from regular menu groups to avoid duplicates
      highlightsServiceIds.push(service.data.id);

      return {
        ...service,
        treatmentCategoryGroupId: getTreatmentCategoryGroupId(
          mergedServices,
          service.data.id
        ),
        originalGroupId: getGroupId(mergedServices, service.data.id),
        highlights: true,
      };
    }
  );

  return [
    ...highlightsServices,
    ...mergedServices.filter(
      service => !highlightsServiceIds.includes(service.data.id)
    ),
  ];
};
