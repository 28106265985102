import { RequestData } from 'js/helpers/service';
import {
  PARAM_SERVICES,
  PARAM_TREATMENTS,
  PARAM_TREATMENT_TYPE,
} from 'js/pages/VenuePage/uri';

export interface SearchCriteria {
  serviceIds: string[];
  treatmentIds: number[];
  treatmentTypeId?: number;
}

export const getSearchCriteria = (
  pageParameters: RequestData['pageParameters']
): SearchCriteria => {
  const serviceIds = pageParameters && pageParameters[PARAM_SERVICES];
  const treatmentIds = pageParameters && pageParameters[PARAM_TREATMENTS];
  const treatmentTypeId =
    pageParameters && pageParameters[PARAM_TREATMENT_TYPE];

  return {
    serviceIds: (serviceIds && serviceIds[0].split(',')) ?? [],
    treatmentIds:
      (treatmentIds &&
        treatmentIds[0].split(',').map(id => parseInt(id, 10))) ??
      [],
    treatmentTypeId: treatmentTypeId
      ? parseInt(treatmentTypeId[0], 10)
      : undefined,
  };
};

export const hasSearchCriteria = ({
  searchCriteria,
  isChurned,
  pageParameters,
}: {
  searchCriteria?: SearchCriteria;
  isChurned?: boolean;
  pageParameters?: RequestData['pageParameters'];
} = {}): boolean => {
  const criteria = !isChurned
    ? searchCriteria || getSearchCriteria(pageParameters)
    : false;

  return (
    criteria &&
    (criteria.serviceIds.length > 0 ||
      criteria.treatmentIds.length > 0 ||
      criteria.treatmentTypeId !== undefined ||
      criteria.treatmentTypeId === 0)
  );
};
