import React from 'react';
import { Logo } from 'js/pages/VenuePage/Widget/Logo/Logo';
import { Divider } from 'js/components/generic/Divider';
import { CmsWidgetFooter } from 'js/model/cms/cms-footer';
import styles from './VenueFooter.module.css';
import { WidgetFooterLinks } from './WidgetFooterLinks';

interface Props {
  cms: CmsWidgetFooter;
}

export function VenueFooter({ cms }: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <WidgetFooterLinks cms={cms} />
      <Divider />
    </div>
  );
}
