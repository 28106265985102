import PropTypes from 'prop-types';
import React, { Component } from 'react';
import clsx from 'clsx';

import { Button } from 'js/components/Button';
import { getWindowDeviceType } from 'js/helpers/dom';

import styles from './Popover.module.css';

const HEADER_HEIGHT = 48;
const ACTIONS_HEIGHT = 72;
const ARROW_HEIGHT = 40;
const VERTICAL_MARGIN = 16 * 2;

export default class Popover extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    submitBtnText: PropTypes.string.isRequired,
    cancelBtnText: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    position: PropTypes.shape({
      top: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      arrowPosition: PropTypes.number,
    }),
    children: PropTypes.object.isRequired,
  };

  state = {
    scrollable: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.resize);

    this.resize();
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
    window.removeEventListener('resize', this.resize);
  }

  resize = () => {
    // reset height before doing any calculations
    this.contentEl.style.height = 'auto';
    document.body.style.overflow = 'auto';

    const isMobile = getWindowDeviceType() === 'mobile';
    const maxHeight = window.innerHeight - VERTICAL_MARGIN;
    const currentHeight = this.popoverEl.clientHeight;

    if (isMobile) {
      document.body.style.overflow = 'hidden';

      if (currentHeight > maxHeight) {
        this.setState({ scrollable: true });
        this.contentEl.style.height = `${maxHeight -
          HEADER_HEIGHT -
          ACTIONS_HEIGHT}px`;
      } else {
        this.setState({ scrollable: false });
      }
    }
  };

  handleOverlayClick = () => {
    if (getWindowDeviceType() !== 'mobile') {
      this.props.onClose();
    }
  };

  getPosition = () => {
    if (getWindowDeviceType() !== 'mobile') {
      const { top, left } = this.props.position || {};

      return { top, left };
    }

    return {};
  };

  render() {
    const position = this.getPosition();
    const arrowPositionStyle =
      typeof this.props.position.arrowPosition !== 'undefined'
        ? {
            top: this.props.position.arrowPosition - ARROW_HEIGHT / 2,
          }
        : {};

    return (
      <div className={styles.wrapper} data-cy="popover">
        <div
          className={styles.overlay}
          onClick={event => {
            event.stopPropagation();
            this.handleOverlayClick();
          }}
        />
        <div
          ref={el => {
            this.popoverEl = el;
          }}
          className={clsx(styles.popover, {
            [styles.scrollable]: this.state.scrollable,
          })}
          onClick={event => {
            event.stopPropagation();
          }}
          style={{ ...position }}
        >
          <div className={styles.header}>{this.props.title}</div>
          <div
            ref={el => {
              this.contentEl = el;
            }}
            className={styles.content}
          >
            {this.props.children}
          </div>
          <div className={styles.actions}>
            <div className={styles.button}>
              <Button
                label={this.props.submitBtnText}
                onClick={this.props.onSubmit}
                data-cy="SubmitEmployeeLevelPopover"
              />
            </div>
            <div className={styles.cancel}>
              <a onClick={this.props.onClose}>{this.props.cancelBtnText}</a>
            </div>
          </div>
          <div className={styles.arrow} style={arrowPositionStyle} />
        </div>
      </div>
    );
  }
}
