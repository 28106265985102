import React from 'react';
import { useViewport } from '@treatwell/ui';
import clsx from 'clsx';

import styles from './Dots.module.css';
import { DotType, renderableDots } from './renderableDots';

interface Props {
  maxDotCount: number;
  pageCount: number;
  // The 0-based number of the current page. Should be less than `pageCount`.
  page: number;
}

function Dot({ type }: { type: DotType }): JSX.Element {
  const classes = clsx(styles.dot, {
    [styles.selected]: type === 'selectedDot',
    [styles.small]: type === 'smallDot',
  });

  return <div className={classes} />;
}

// See the unit tests in the same directory for examples of
// various rendering scenarios.
export function Dots({
  maxDotCount,
  pageCount,
  page,
}: Props): React.ReactElement | null {
  const isMobile = useViewport({ device: 'mobile' });
  const [currentPage, setCurrentPage] = React.useState(0);
  const [previousPage, setPreviousPage] = React.useState(0);
  const [
    previousSelectedDotPosition,
    setPreviousSelectedDotPosition,
  ] = React.useState(0);

  const dots = renderableDots({
    totalDots: pageCount,
    visibleDots: maxDotCount,
    currentIndex: currentPage,
    previousIndex: previousPage,
    previousSelectedDotPosition,
  });

  React.useEffect(() => {
    if (page !== currentPage) {
      setPreviousSelectedDotPosition(dots.selectedDotPosition);
      setPreviousPage(currentPage);
      setCurrentPage(page);
    }
  }, [page, currentPage]);

  // Don't show dots when there's only one page.
  if (pageCount < 2) {
    return null;
  }

  // dotSize and dotGap are different depending on viewport size.
  const dotSize = parseInt(
    isMobile ? styles.mobileDotSize : styles.desktopDotSize,
    10
  );
  const dotGap = parseInt(isMobile ? styles.mobileGap : styles.desktopGap, 10);

  // The number of dots visible at a time is normally dictated by the
  // maxDot prop. But if there are fewer pages that that, then number of
  // dots visible will be the same as the number of pages.
  const visibleDotCount = Math.min(maxDotCount, pageCount);

  const containerClasses = clsx(styles.container, {
    [styles.desktop]: !isMobile,
  });

  const visibleDotsWidth = visibleDotCount * dotSize;
  const visibleDotGapsWidth = (visibleDotCount - 1) * dotGap;
  const containerWidth = visibleDotsWidth + visibleDotGapsWidth;

  const translate = dots.firstVisibleIndex * (dotSize + dotGap);

  /*
   * The width of the outer element is limited to the number of dots that
   * are to be visible. This is so that it can clip the dots that are in
   * the inner element.
   *
   * Dots for all pages are rendered in the inner element. The element is
   * translated on the the X-axis so that the desired dots (including the
   * one for the current page) are visible within the outer element.
   *
   *        ┌─────────┐
   *     ○ ○│○ ○ ● ○ ○│○ ○ ○ ○
   *        └─────────┘
   */
  return (
    <div className={containerClasses} style={{ width: `${containerWidth}px` }}>
      <div
        className={styles.dots}
        style={{ transform: `translateX(-${translate}px)` }}
      >
        {dots.dots.map((dot, index) => (
          <Dot key={`${index}-${dot}`} type={dot} />
        ))}
      </div>
    </div>
  );
}
