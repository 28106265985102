import React from 'react';
import clsx from 'clsx';
import { Inline, Viewport } from '@treatwell/ui';
import gridStyles from 'assets/style/grid.module.css';
import { VenueScore } from 'js/components/VenueScore';
import { Divider } from 'js/pages/VenuePage/Divider';
import { PaginationOutput } from 'js/model/rainbow/PaginationOutput';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { RequestData } from 'js/helpers/service';
import { VenuePage } from 'js/model/rainbow/page/VenuePageOutput';
import { PropsType } from 'js/types/react';
import { VenueTypeLinks } from 'js/pages/VenuePage/VenueReviewsSection/VenueReviews/VenueTypeLinks';
import { useIntersectionObserver } from 'js/hooks/useIntersectionObserver';
import { trackStructuredEvent } from 'js/helpers/google-tag-manager';
import { Reviews } from './Reviews';
import { VenueCriteria } from './VenueCriteria';

import styles from './VenueReviews.module.css';

interface Props {
  requestData: RequestData;
  venue: VenuePage;
  channelData: Channel;
  i18n: (key: string, count?: number | string) => string;
  pagination?: PaginationOutput;
  generatePaginationUri?: (pageNumber: number) => string;
  venueTypeLinks?: PropsType<typeof VenueTypeLinks>;
}

export const VenueReviews = ({
  requestData,
  venue,
  channelData,
  i18n,
  pagination,
  generatePaginationUri,
  venueTypeLinks,
}: Props): React.ReactElement => {
  const { rating } = venue.venue;
  const venueScoreExtraClasses = {
    stars: styles['venue-score-stars'],
  };
  const venueReviewRowClasses = clsx(gridStyles.row, styles.venueReviewRow);
  const venueScoreClasses = clsx(gridStyles['col-sm-4'], styles.venueScoreCol);

  const [isSeen, setIsSeen] = React.useState<boolean>(false);

  const ref = React.useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, { threshold: 0 });
  const isVisible = !!entry?.isIntersecting;

  React.useEffect(() => {
    if (isVisible && !isSeen) {
      trackStructuredEvent({
        category: 'venue',
        property: 'reviews',
        action: 'view',
      });
      setIsSeen(true);
    }
  }, [isVisible, isSeen]);

  function renderOptionalVenueCriterias(): React.ReactNode {
    const dimensions = rating.dimensions;
    if (dimensions === undefined) {
      return;
    }
    const midPoint = Math.ceil(dimensions.length / 2);

    return (
      <>
        <VenueCriteria dimensions={dimensions.slice(0, midPoint)} />
        <VenueCriteria dimensions={dimensions.slice(midPoint)} />
      </>
    );
  }

  function renderVenueReviewRowContent(): React.ReactNode {
    return (
      <>
        <div className={venueScoreClasses}>
          <VenueScore
            displayAverage={rating.displayAverage}
            average={rating.average}
            count={rating.count}
            extraStyles={venueScoreExtraClasses}
            channelData={channelData}
          />
        </div>
        {renderOptionalVenueCriterias()}
      </>
    );
  }

  return (
    <div ref={ref}>
      <div className={venueReviewRowClasses}>
        <Viewport serverRender device="mobile">
          {renderVenueReviewRowContent()}
        </Viewport>
        <Viewport device={['tablet', 'desktop']}>
          <Inline splitAfter={0} align="center">
            {renderVenueReviewRowContent()}
          </Inline>
        </Viewport>
      </div>

      <div className={styles.divider}>
        <Divider />
      </div>

      <Reviews
        i18n={i18n}
        channelData={channelData}
        requestData={requestData}
        venue={venue}
        pagination={pagination}
        generatePaginationUri={generatePaginationUri}
        venueTypeLinks={venueTypeLinks}
      />
    </div>
  );
};
