import React from 'react';
import Popover from 'js/components/generic/Popover';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { EmployeeLevelItem } from 'js/model/rainbow/venue/EmployeeOutput';
import { createPortal } from 'react-dom';
import { EmployeeLevelList } from './EmployeeLevelList';
import styles from './EmployeeLevelPopover.module.css';

const ADD_ICON_SIZE = 24;

interface Props {
  addBtnEl: HTMLDivElement | null;
  options: EmployeeLevelItem[];
  showDuration: boolean;
  onClose: () => void;
  onSubmit: (id: string) => void;
  channel: ChannelOutput;
  i18n: (key: string, count?: number | string) => string;
}

export function EmployeeLevelPopover(props: Props): React.ReactElement {
  const [top, setTop] = React.useState<number>(0);
  const [left, setLeft] = React.useState<number>(0);
  const [arrowPosition, setArrowRelativeTop] = React.useState<number>(0);
  const [popoverRef, setPopover] = React.useState<typeof Popover>();
  const [employeeId, setSelectedEmployeeId] = React.useState<string>(
    props.options[0].id
  );
  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, []);

  React.useEffect(() => {
    reposition();
  }, [popoverRef, windowWidth]);

  function resize(): void {
    setWindowWidth(window.innerWidth);
  }

  function calculatePopoverTopPosition(
    addBtnElRect: DOMRect,
    viewportOffsetTop: number,
    popoverHeight: number
  ): number {
    const addBtnAbsoluteOffsetTop = viewportOffsetTop + addBtnElRect.top;
    const fitsFromTop =
      addBtnElRect.top - popoverHeight / 2 + ADD_ICON_SIZE / 2 >= 0;
    const fitsFromBottom =
      addBtnElRect.top + popoverHeight / 2 + ADD_ICON_SIZE / 2 <
      window.innerHeight;

    if (!fitsFromTop) {
      // calculate popover top position based on if addBtnEl top position
      // is in the viewport (plus some buffer) or not
      const elemOffsetTop = addBtnElRect.top < 20 ? 10 : addBtnElRect.top - 10;

      return addBtnAbsoluteOffsetTop - elemOffsetTop;
    }

    if (!fitsFromBottom) {
      // calculate popover top position based on if addBtnEl bottom position
      // is in the viewport (plus some buffer) or not
      const bodyOffsetTop =
        addBtnElRect.bottom > window.innerHeight - 20
          ? viewportOffsetTop + (addBtnElRect.bottom - window.innerHeight + 10)
          : viewportOffsetTop - 10;

      return window.innerHeight + bodyOffsetTop - popoverHeight;
    }

    return addBtnAbsoluteOffsetTop + ADD_ICON_SIZE / 2 - popoverHeight / 2;
  }
  function reposition(): void {
    if (!popoverRef || !props.addBtnEl) {
      return;
    }
    const bodyRect = document.body.getBoundingClientRect();
    const addBtnElRect = props.addBtnEl.getBoundingClientRect();
    const viewportOffsetTop = bodyRect.top * -1;
    const offsetLeft = addBtnElRect.left;

    const popoverAbsoluteTop = calculatePopoverTopPosition(
      addBtnElRect,
      viewportOffsetTop,
      popoverRef.popoverEl.offsetHeight
    );

    const arrowRelativeTop =
      addBtnElRect.top -
      (popoverAbsoluteTop - viewportOffsetTop) +
      ADD_ICON_SIZE / 2;

    setTop(popoverAbsoluteTop);
    setLeft(offsetLeft - popoverRef.popoverEl.offsetWidth - ADD_ICON_SIZE);
    setArrowRelativeTop(arrowRelativeTop);
  }

  const submit = () => {
    props.onSubmit(employeeId);
  };

  const { i18n } = props;

  return createPortal(
    <div className={styles.employeeLevelPopover}>
      <Popover
        ref={setPopover}
        title={i18n('venue.menu.choose-expert.title')}
        submitBtnText={i18n('venue.menu.choose-expert.add')}
        cancelBtnText={i18n('venue.menu.choose-expert.cancel')}
        onClose={props.onClose}
        onSubmit={submit}
        position={{ top, left, arrowPosition }}
      >
        <EmployeeLevelList
          selectId={setSelectedEmployeeId}
          items={props.options}
          showDuration={props.showDuration}
          channel={props.channel}
          i18n={i18n}
        />
      </Popover>
    </div>,
    document.body
  );
}
