import React from 'react';
import {Modal, ModalProps} from '../Modal/Modal';
import {useModal} from '../Modal/useModal';
import {Text} from '../../Atoms/Text';
import {Inline, Stack} from '../../Layout';
import {TreatsLogo} from '../../Atoms/TreatsLogo';
import {MediaObject} from '../MediaObject';
import styles from './LoyaltyInfoModal.module.css';
import BookIcon from './assets/book.inline.svg';
import EarnIcon from './assets/earn.inline.svg';
import RedeemIcon from './assets/redeem.inline.svg';
import LineIcon from './assets/line.inline.svg';
import {Button} from '../../Atoms/Button/Button';
import {useViewport} from '../../Atoms/Viewport';
import clsx from 'clsx';

type LoyaltyInfoModalTitleProps = {
  children: React.ReactNode;
};

const Title = ({children}: LoyaltyInfoModalTitleProps) => {
  return (
    <Stack align="center" className={clsx(styles.title, styles.text)}>
      {children}
    </Stack>
  );
};

type LoyaltyInfoModalProps = {
  onToggle: (isOpen: boolean) => void;
  labels: {
    title: React.ReactNode;
    instructionSteps: InstructionStepsProps;
    cta: {label: string};
    closeButton: {label: string};
  };
  onCTAClick: () => void;
} & Pick<ModalProps, 'isOpen'>;

type StepProps = {
  icon: React.ReactElement;
  label: string;
  description: string | React.ReactElement;
};

const Step = ({icon, label, description}: StepProps) => {
  return (
    <MediaObject space="md" align="center">
      {icon}
      <Stack>
        <Text as="p" type="mdHeader" className={styles.text}>
          {label}
        </Text>
        <Text as="p" type="caption" className={styles.text}>
          {description}
        </Text>
      </Stack>
    </MediaObject>
  );
};

export type InstructionStepsProps = {
  book: Pick<StepProps, 'label' | 'description'>;
  earn: Pick<StepProps, 'label' | 'description'>;
  redeem: Pick<StepProps, 'label' | 'description'>;
};

export const InstructionSteps = ({
  book,
  earn,
  redeem,
}: InstructionStepsProps) => {
  return (
    <Stack as="ol" className={styles.list}>
      <li>
        <Step
          icon={<BookIcon width={60} height={60} aria-hidden="true" />}
          label={book.label}
          description={book.description}
        />
        <LineIcon />
      </li>
      <li>
        <Step
          icon={<EarnIcon width={60} height={60} aria-hidden="true" />}
          label={earn.label}
          description={earn.description}
        />
        <LineIcon />
      </li>
      <li>
        <Step
          icon={<RedeemIcon width={60} height={60} aria-hidden="true" />}
          label={redeem.label}
          description={redeem.description}
        />
      </li>
    </Stack>
  );
};

export const LoyaltyInfoModal = ({
  onToggle,
  labels,
  onCTAClick,
  ...props
}: LoyaltyInfoModalProps) => {
  const {modalProps} = useModal({
    onToggle,
  });
  const isMobile = useViewport({device: 'mobile'});
  modalProps.isOpen = props.isOpen;

  return (
    <Modal {...modalProps} className={styles.root} data-cy="LoyaltyInfoModal">
      <Inline justify="end" className={styles.closeButton}>
        <Modal.CloseButton closeLabel={labels.closeButton.label} />
      </Inline>
      <Modal.Body>
        <div
          className={clsx(styles.container, {
            [styles.containerMobile]: isMobile,
          })}
        >
          <Stack space="xxl" align="center" fullHeight={isMobile}>
            <Stack align="center" space="md">
              <TreatsLogo />
              <Title>{labels.title}</Title>
            </Stack>
            <div className={clsx({[styles.steps]: !isMobile})}>
              <InstructionSteps
                book={labels.instructionSteps.book}
                earn={labels.instructionSteps.earn}
                redeem={labels.instructionSteps.redeem}
              />
            </div>
            <div
              className={clsx({
                [styles.button]: !isMobile,
                [styles.buttonMobile]: isMobile,
              })}
            >
              <Button fullWidth buttonStyle="secondary" onClick={onCTAClick}>
                {labels.cta.label}
              </Button>
            </div>
          </Stack>
        </div>
      </Modal.Body>
    </Modal>
  );
};
