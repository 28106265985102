import React from 'react';

import { Radio } from 'js/components/form/Radio/Radio';
import { PriceView } from 'js/components/PriceDisplay/PriceView';
import { formatPrice } from 'js/helpers/price/priceUtilities';
import { formatDuration, getDurationCms } from 'js/helpers/formatters';

import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { EmployeeLevelItem } from 'js/model/rainbow/venue/EmployeeOutput';
import { PriceRange } from 'js/helpers/price/PriceRange';
import styles from './EmployeeLevelListItem.module.css';

interface Props {
  data: EmployeeLevelItem;
  isSelected?: boolean;
  showDuration: boolean;
  onChange: (value: string) => void;
  channel: ChannelOutput;
  i18n: (key: string, count?: number | string) => string;
}

export function EmployeeLevelListItem({
  isSelected,
  onChange,
  showDuration,
  data,
  i18n,
  channel,
}: Props): React.ReactElement {
  function renderPrice(): React.ReactElement {
    const priceRange = new PriceRange(data.priceRange);
    const price = formatPrice(priceRange.getSalePrice(), channel);
    const strikeThroughPrice = priceRange.getStrikeThroughPrice(channel);
    return (
      <div style={{ marginTop: '1px' }}>
        <PriceView price={price} strikeThroughPrice={strikeThroughPrice} />
      </div>
    );
  }

  return (
    <div className={styles.item} onClick={() => onChange(data.id)}>
      <div className={styles.radio}>
        <Radio
          id={data.id}
          name="popover"
          noWrap
          checked={!!isSelected}
          onChange={() => onChange(data.id)}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.name}>{data.name}</div>
        <div className={styles.employees}>{data.employees.join(', ')}</div>
        {showDuration ? (
          <div className={styles.duration}>
            {formatDuration(data.durationMinutes, getDurationCms(i18n))}
          </div>
        ) : null}
      </div>
      {renderPrice()}
    </div>
  );
}
