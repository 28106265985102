import React from 'react';
import { Inline, Text, useModal } from '@treatwell/ui';
import styles from './PatchTestWarning.module.css';
import { PatchTestWarningPopup } from './PatchTestWarningPopup';
import {
  trackPatchTestUnderstood,
  trackPatchTestInfo,
} from './patchTestTracking';

interface Cms {
  contentCms: string;
  headerCms: string;
  buttonCms: string;
}
interface Props {
  label: string;
  popupLabel: string;
  cms: Cms;
}

export function PatchTestWarning(props: Props): React.ReactElement {
  const { openModal, modalProps, closeModal } = useModal({
    width: 300,
    preventDismiss: true,
  });

  const onOpen = (): void => {
    openModal();
    trackPatchTestInfo();
  };

  const onClose = (): void => {
    closeModal();
    trackPatchTestUnderstood();
  };

  return (
    <div className={styles.patchTestWarning}>
      <Inline space="xs" className={styles.requirementContainer}>
        <div className={styles.icon} />
        <Text as="div" type="captionHeavy" className={styles.requirement}>
          {props.label}
        </Text>
      </Inline>
      <Text type="caption" className={styles.warningPopup} onClick={onOpen}>
        {props.popupLabel}
      </Text>
      <PatchTestWarningPopup
        cms={props.cms}
        {...modalProps}
        onClose={onClose}
      />
    </div>
  );
}
