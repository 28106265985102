import React from 'react';
import { Text } from '@treatwell/ui';
import styles from './VenueDescription.module.css';

interface Props {
  htmlDescription: string;
}

export function VenueDescription(props: Props): React.ReactElement {
  const { htmlDescription } = props;

  return (
    <Text as="div" className={styles.description}>
      <div dangerouslySetInnerHTML={{ __html: htmlDescription }} />
    </Text>
  );
}
