import { VenueMenuItemDetailsOutput } from 'js/model/rainbow/venue/VenueMenuItemDetailsOutput';
import {
  CmsVenuePage,
  getServiceDetailsLabels,
} from 'js/model/cms/cms-venue-page';

export interface MenuItemDetailsLabels {
  headers: {
    rating: string;
    about: string;
    info: string;
    restrictions: string;
    reviews: string;
    serviceImageHeader: string;
    footer: {
      selectSingular: string;
      select: string;
      serviceSingular: string;
      selected: string;
    };
  };
  emptyMessage: string;
  showReviewsLabel: string;
  treatmentGuideLinkLabel: string;
}

interface MenuItemDetailsPopupTitles {
  desktop: string;
  mobile: string;
}

export function getMenuItemDetailLabels(
  cms: CmsVenuePage,
  serviceDetails: VenueMenuItemDetailsOutput
): MenuItemDetailsLabels {
  const content = getServiceDetailsLabels(cms);
  const reviewsCount = serviceDetails.reviewAggregates.count;
  const treatmentCategory = serviceDetails.primaryTreatmentCategory || {};
  const { name = '' } = treatmentCategory || {};
  return {
    headers: {
      rating: content.rating.header,
      about: content.about.header,
      info: content['good-to-know'].header,
      restrictions: content.restrictions.header,
      reviews: content['service-reviews'].header,
      serviceImageHeader: content['service-images-header'],
      footer: {
        selectSingular: content.footer['select-singular'],
        select: content.footer.select,
        serviceSingular: content.footer['service-singular'],
        selected: content.footer.selected,
      },
    },
    emptyMessage: content['no-details-message'],
    showReviewsLabel: content['service-reviews']['show-reviews-label'].replace(
      '{0}',
      reviewsCount.toString()
    ),
    treatmentGuideLinkLabel: content.about['treatment-guide'].replace(
      '{0}',
      name
    ),
  };
}

export function getMenuItemDetailPopupTitles(
  cms: CmsVenuePage
): MenuItemDetailsPopupTitles {
  const content = getServiceDetailsLabels(cms);
  return {
    desktop: content.title,
    mobile: content['mobile-title'],
  };
}
