import React from 'react';
import clsx from 'clsx';
import { Stack, Viewport } from '@treatwell/ui';

import { scrollElementTo } from 'js/helpers/animations';
import { Header } from './Header';
import styles from './PopupContent.module.css';

export enum FooterBoundary {
  Line = 'line',
  Shadow = 'shadow',
}

interface Props {
  header?: {
    // If true, the header will only be rendered at mobile widths
    mobileOnly: boolean;

    closeText: string;
    title?: string;
    onClose?: () => void;
    icon?: { size: 16 | 24 };
    isTransparent?: boolean;
    noPaddingRightOnDesktop?: boolean;
  };
  children: React.ReactNode;
  footer?: {
    content: React.ReactNode;

    // What type of delimiter to show at the top of the footer.
    boundary?: FooterBoundary;
  };
}

/*
  <PopupContent> is a component intended for use as a child of
  a <Popup> component.

  It provides for
    - an optional standardised header, fixed to the top
    - an optional custom footer, fixed to the bottom
    - children
        - rendered between header and footer
        - scrollable if too big to fit between header and footer
 */
export class PopupContent extends React.PureComponent<Props> {
  private contentRef: React.RefObject<HTMLDivElement> = React.createRef<
    HTMLDivElement
  >();

  private renderHeader(): React.ReactNode {
    if (!this.props.header) {
      return null;
    }

    const {
      mobileOnly,
      closeText,
      title,
      onClose,
      isTransparent,
      noPaddingRightOnDesktop,
      icon,
    } = this.props.header;
    const headerClassNames = clsx({
      [styles.header]: isTransparent,
      [styles.noPaddingRightOnDesktop]: noPaddingRightOnDesktop,
    });
    const header = (
      <Header
        title={title}
        closeText={closeText}
        onCloseClick={onClose}
        icon={icon}
        positioningClassNames={headerClassNames}
      />
    );

    if (mobileOnly) {
      return (
        <Viewport serverRender device="mobile">
          {header}
        </Viewport>
      );
    }

    return header;
  }

  private renderFooter(): React.ReactNode {
    if (!this.props.footer) {
      return null;
    }

    const { content, boundary } = this.props.footer;

    const classname = clsx({
      [styles.footerLine]: boundary === FooterBoundary.Line,
    });

    const shadow = boundary === FooterBoundary.Shadow && (
      <div className={styles.footerShadow} />
    );

    return (
      <div className={styles.footer}>
        {shadow}
        <div className={classname}>{content}</div>
      </div>
    );
  }

  private renderContent(): React.ReactNode {
    const contentClassNames = clsx(styles.content, {
      [styles.transparentHeader]: this.props.header?.isTransparent,
    });
    return (
      <div ref={this.contentRef} className={contentClassNames}>
        {this.props.children}
      </div>
    );
  }

  // Animated scroll to a y offset.
  public scrollTo(y: number): void {
    if (this.contentRef.current) {
      scrollElementTo(this.contentRef.current, 0, y);
    }
  }

  public render(): React.ReactNode {
    return (
      <Stack className={styles.popupContent}>
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderFooter()}
      </Stack>
    );
  }
}
