import React from 'react';
import { ServiceSection } from '../ServiceSection';

interface Props {
  header: string;
  serviceDescription?: string;
}

export function ServiceAbout({
  header,
  serviceDescription,
}: Props): React.ReactElement | null {
  if (!serviceDescription) {
    return null;
  }

  return (
    <ServiceSection header={header}>
      <div
        dangerouslySetInnerHTML={{
          __html: serviceDescription,
        }}
      />
    </ServiceSection>
  );
}
