/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M8 1.333a6.667 6.667 0 110 13.334A6.667 6.667 0 018 1.333zm2.529 4.196L7 9.057 5.471 7.53l-.942.942L7 10.943l4.471-4.472-.942-.942z',
  ],

  24: [
    'M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm3.793 6.293L10.5 13.586l-2.293-2.293-1.414 1.414 3.707 3.707 6.707-6.707-1.414-1.414z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconCheckFilled = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconCheckFilled.displayName = 'IconCheckFilled';
