import React from 'react';
import { Button, ButtonColour, ButtonSize } from 'js/components/Button';
import { splitToParagraphs } from 'js/helpers/text';
import { Modal, ModalProps, Text } from '@treatwell/ui';
import styles from './PatchTestWarningPopup.module.css';

type ModalPropsWithoutChildren = Omit<ModalProps, 'children'>;

interface Cms {
  contentCms: string;
  headerCms: string;
  buttonCms: string;
}

interface Props extends ModalPropsWithoutChildren {
  onClose: () => void;
  cms: Cms;
}

export function PatchTestWarningPopup({
  onClose,
  cms,
  ...modalProps
}: Props): React.ReactElement {
  const content = (): React.ReactNode => {
    const contents = splitToParagraphs(cms.contentCms);
    return (
      <div className={styles.content}>
        <div>
          {contents.map(item => (
            <Text as="p" type="caption" key={item}>
              {item}
            </Text>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Modal {...modalProps} onClose={onClose}>
      <Modal.Body>
        <div className={styles.header}>
          <Text type="mdHeader">{cms.headerCms}</Text>
        </div>
        {content()}
      </Modal.Body>
      <Modal.Footer>
        <Button
          colour={ButtonColour.Blue}
          size={ButtonSize.Medium}
          label={cms.buttonCms}
          fullWidth
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
}
