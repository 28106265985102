import { PropsType } from 'js/types/react';
import { VenueReview } from 'js/pages/VenuePage/VenueReviewsSection/VenueReviews/VenueReview';
import { ReviewOutput } from 'js/model/ReviewOutput';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { longAgo } from 'js/helpers/datetime-format';

export function createVenueReviewViewModel(
  review: ReviewOutput,
  channel: Channel,
  i18n: (key: string) => string,
  showTreatmentNames?: boolean
): PropsType<typeof VenueReview> {
  return {
    review: {
      rating: review.rating,
      content: !review.content.generated ? review.content.content : '',
      employeeDescription: review.employeeDescription || '',
      ...(review.treatmentNames && { treatmentNames: review.treatmentNames }),
      author: review.reviewer.name || i18n('venue.reviews.anonymous'),
      isVerified: review.verified,
      created: longAgo(review.createdAt, channel.languageCode),
    },
    response: {
      content: review.venueResponse ? review.venueResponse.content : '',
      visibilityToggleLabels: {
        isVisible: i18n('venue.reviews.show-response-opened'),
        isHidden: i18n('venue.reviews.show-response-closed'),
      },
    },
    reportLink: {
      label: i18n('venue.reviews.report'),
      uri: i18n('venue.reviews.report-url'),
      isVisible: channel.code === 'WHN_GB',
    },
    showAllTreatmentsLabel: i18n('venue.reviews.show-all-treatments'),
    showTreatmentNames,
  };
}
