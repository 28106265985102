import React from 'react';
import clsx from 'clsx';
import styles from './HorizontalRule.module.css';

interface Props {
  colorScheme?: 'blue-pink-yellow' | 'orange-pink-blue';
}

export function HorizontalRule(props: Props): React.ReactElement {
  const { colorScheme = 'blue-pink-yellow' } = props;
  return (
    <div
      className={clsx(styles.horizontalRule, {
        [styles.bluePinkYellow]: colorScheme === 'blue-pink-yellow',
        [styles.orangePinkBlue]: colorScheme === 'orange-pink-blue',
      })}
    />
  );
}
