import React from 'react';
import clsx from 'clsx';

import { CollapseText } from 'js/components/generic/CollapseText';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { MenuGroup as MenuGroupType } from 'js/model/rainbow/venue/VenueOutput';
import styles from './MenuGroup.module.css';
import { MenuGroupHeader } from './MenuGroupHeader';
import { MenuGroupItems } from './MenuGroupItems';

interface Props {
  group: MenuGroupType;
  channel: ChannelOutput;
  isSuggestedGroup: boolean;
  isActive: boolean;
  isSelected: boolean;
  onClick: () => void;
  isMobile: boolean;
  expandLabel: string;
  collapseLabel: string;
}

export function MenuGroup({
  group,
  channel,
  isSuggestedGroup,
  isActive,
  isSelected,
  onClick,
  isMobile,
  expandLabel,
  collapseLabel,
}: Props): React.ReactElement {
  return (
    <section className={styles.group} data-menu-group-id={group.id}>
      <MenuGroupHeader
        channel={channel}
        group={group}
        isSuggested={isSuggestedGroup}
        isActive={isActive}
        isSelected={isSelected}
        onClick={onClick}
      />
      {!!isActive && (
        <div
          className={clsx(
            { [styles.services]: !!isMobile },
            { [styles.selected]: !!isActive }
          )}
        >
          {isMobile && (
            <>
              <CollapseText
                collapseLabel={collapseLabel}
                expandLabel={expandLabel}
              >
                {group.description!}
              </CollapseText>

              <MenuGroupItems
                group={group}
                isActive
                isSuggestedGroup={isSuggestedGroup}
                channel={channel}
                trackingGroupType="venue_menu"
              />
            </>
          )}
        </div>
      )}
    </section>
  );
}
