import React from 'react';
import { useModal, LazyImage } from '@treatwell/ui';
import { TeamListItemContentSection } from 'js/pages/VenuePage/VenueTeamSection/TeamListItemContentSection';
import { trackStylistPortfolioClicked } from 'js/pages/VenuePage/VenueTeamSection/tracking';
import { TeamListItemPortfolioPopup } from './TeamListItemPortfolioPopup/TeamListItemPortfolioPopup';
import styles from './TeamListItemPortfolioSection.module.css';

export interface TeamListItemPortfolioImage {
  '128x170': string;
  '800x1066': string;
}

interface Props {
  closePopupLabel: string;
  heading: string;
  id: number;
  images: TeamListItemPortfolioImage[];
  imageCountLabel: string;
}

export function TeamListItemPortfolioSection(props: Props): React.ReactElement {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const { openModal, modalProps } = useModal({
    title: props.imageCountLabel,
    width: 500,
    onToggle: isOpen => {
      setIsModalOpen(isOpen);
    },
  });
  const [focusImageIndex, setFocusImageIndex] = React.useState<number>(0);

  function onImageClick(focusImageIndex: number): void {
    openModal();
    setFocusImageIndex(focusImageIndex);

    trackStylistPortfolioClicked(focusImageIndex + 1, props.id);
  }

  return (
    <TeamListItemContentSection heading={props.heading}>
      <div className={styles.container} data-cy="team-member-portfolio-images">
        {props.images.map((image, imageIndex) => (
          <LazyImage
            key={imageIndex}
            data-cy="team-member-portfolio-image"
            src={image['128x170']}
            height={170}
            width={128}
            onClick={() => onImageClick(imageIndex)}
          />
        ))}
      </div>
      {isModalOpen && (
        <TeamListItemPortfolioPopup
          closeLabel={props.closePopupLabel}
          images={props.images}
          initiallySelectedImage={props.images[focusImageIndex]}
          imageCountLabel={props.imageCountLabel}
          {...modalProps}
        />
      )}
    </TeamListItemContentSection>
  );
}
