import React from 'react';
import clsx from 'clsx';
import styles from './Divider.module.css';

interface Props {
  footnote?: boolean;
  inContent?: boolean;
}

export function Divider(props: Props): React.ReactElement {
  const { inContent, footnote } = props;
  const classes = clsx(styles.divider, {
    [styles.content]: inContent,
    [styles.footnote]: footnote,
  });

  return <hr className={classes} />;
}
