import React from 'react';
import clsx from 'clsx';
import { generateDuration } from 'js/helpers/venue-menu';
import { getDurationCms } from 'js/helpers/formatters';
import { PriceDisplay } from 'js/components/PriceDisplay/PriceDisplay';
import { Chevron, Direction } from 'js/components/Icons/Chevron/Chevron';
import { getServicePriceRangeCms } from 'js/helpers/price/priceUtilities';
import { Button, Text, Stack, Inline, IconChecked } from '@treatwell/ui';
import { DurationRange } from 'js/model/rainbow/venue/TreatmentVenueMenuItemOutput';
import { PriceRangeOutput } from 'js/model/rainbow/PriceRangeOutput';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { EmployeeLevelItem } from 'js/model/rainbow/venue/EmployeeOutput';
import { Context, GeneralContext } from 'js/components/LocaleWrapper';
import { ColorBloomBaseCoral600 } from '@treatwell/design-tokens';
import styles from './MenuItem.module.css';
import { EmployeeLevelPopover } from './EmployeeLevelPopover/EmployeeLevelPopover';

interface Props {
  name?: string;
  details?: boolean;
  isSelected?: boolean;
  isExpanded?: boolean;
  isItemGroup?: boolean;
  popoverShown: boolean;
  duration?: DurationRange;
  priceRangeOutput?: PriceRangeOutput;
  options2D: EmployeeLevelItem[];
  channel: ChannelOutput;
  onShowDetails: (event: React.SyntheticEvent) => void;
  onClick: () => void;
  onPopoverSubmit: (optionId: string) => void;
  initiallyExpanded?: boolean;
}

export const isChurnedVenue = (context: GeneralContext) => {
  return context.pageData.venue.venue.status === 'churned';
};

export function LocalMenuItem(props: Props): React.ReactElement {
  const addBtnEl = React.useRef<HTMLDivElement>(null);

  function getServicePriceRange(): React.ReactNode {
    const { priceRangeOutput, isExpanded, channel, options2D = [] } = props;
    return !isExpanded || options2D.length > 0 ? (
      <div style={{ marginTop: isExpanded ? '-3px' : '-1px' }}>
        <Context.Consumer>
          {value => {
            return (
              !isChurnedVenue(value) && (
                <PriceDisplay
                  priceRangeOutput={priceRangeOutput!}
                  channelOutput={channel}
                  servicePriceRangeCms={getServicePriceRangeCms(value.i18n)}
                />
              )
            );
          }}
        </Context.Consumer>
      </div>
    ) : null;
  }

  function getEmployeeLevelPopover(): React.ReactNode {
    const {
      popoverShown,
      onPopoverSubmit,
      onClick,
      channel,
      options2D = [],
    } = props;

    return options2D.length && popoverShown && addBtnEl.current ? (
      <Context.Consumer>
        {value => (
          <EmployeeLevelPopover
            addBtnEl={addBtnEl.current}
            options={options2D}
            showDuration
            onClose={onClick}
            onSubmit={onPopoverSubmit}
            channel={channel}
            i18n={value.i18n}
          />
        )}
      </Context.Consumer>
    ) : null;
  }

  function renderChevron(): React.ReactNode {
    const { isExpanded } = props;

    return (
      <div
        className={clsx({
          [styles['menu-item-icon']]: true,
        })}
        ref={addBtnEl}
      >
        <Chevron
          colour="bloomGreyNavy500"
          direction={isExpanded ? Direction.Up : Direction.Down}
          animated
        />
      </div>
    );
  }

  const serviceIsSelected = props.isSelected;

  function getButtonLabel(context: GeneralContext): string {
    const selectLabel = context.i18n('venue.menu.labels.select') || '';
    const selectedLabel = context.i18n('venue.menu.labels.selected') || '';

    return serviceIsSelected ? selectedLabel : selectLabel;
  }

  function renderServiceButtons(): React.ReactNode {
    const positioningClasses = clsx({
      [styles.selectService]: !props.isSelected,
      [styles.unselectService]: props.isSelected,
    });

    return (
      <Context.Consumer>
        {value => {
          return (
            !isChurnedVenue(value) && (
              <div className={positioningClasses} ref={addBtnEl}>
                <div
                  className={clsx(styles.button, {
                    [styles.selected]: serviceIsSelected,
                  })}
                >
                  <Button buttonStyle="primary" size="sm">
                    {getButtonLabel(value)}
                  </Button>
                </div>
              </div>
            )
          );
        }}
      </Context.Consumer>
    );
  }

  const {
    name,
    duration,
    isExpanded,
    details = true,
    onShowDetails,
    onClick,
    isItemGroup,
    isSelected,
    initiallyExpanded,
  } = props;

  const detailsNode = details ? (
    <Context.Consumer>
      {value => {
        return (
          !isChurnedVenue(value) && (
            <Text className={styles.details} onClick={onShowDetails}>
              {value.i18n('venue.menu.details-and-reviews')}
            </Text>
          )
        );
      }}
    </Context.Consumer>
  ) : null;

  const durationNode =
    !isExpanded && Boolean(name) ? (
      <Context.Consumer>
        {value => (
          <Text className={styles.durationRange}>
            {generateDuration(duration!, getDurationCms(value.i18n))}
          </Text>
        )}
      </Context.Consumer>
    ) : null;

  const showInfo = detailsNode || durationNode;
  const classnames = clsx({
    [styles.stack]: !isItemGroup,
  });

  return (
    <Inline
      align="start"
      space="md"
      splitAfter={0}
      className={classnames}
      onClick={onClick}
    >
      <Stack space="xs">
        <Context.Consumer>
          {value => (
            <Text className={styles.title}>
              {name || generateDuration(duration!, getDurationCms(value.i18n))}
            </Text>
          )}
        </Context.Consumer>
        {!showInfo ? null : (
          <Inline wrap space="sm">
            {durationNode}
            {detailsNode}
          </Inline>
        )}
      </Stack>

      <Context.Consumer>
        {value =>
          !isChurnedVenue(value) && (
            <>
              {isSelected && isItemGroup && (
                <div className={styles['group-added']}>
                  <IconChecked size={16} fill={ColorBloomBaseCoral600} />
                </div>
              )}
            </>
          )
        }
      </Context.Consumer>
      {getServicePriceRange()}
      {isItemGroup
        ? initiallyExpanded || renderChevron()
        : renderServiceButtons()}
      {getEmployeeLevelPopover()}
    </Inline>
  );
}
