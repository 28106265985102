import React from 'react';
import { Text, Inline, Stack, Viewport } from '@treatwell/ui';
import { formatPrice } from 'js/helpers/price/priceUtilities';
import {
  ChannelLoyalty,
  ChannelOutput,
} from 'js/model/rainbow/content/ChannelOutput';
import { CmsPageVenue } from 'js/model/cms/cms-venue-page';
import styles from './Summary.module.css';
import { LoyaltyTotalContainer } from './LoyaltyTotal';

export interface Price {
  amount: string;
  range: boolean;
}
interface Props {
  serviceCount: number;
  price: Price;
  loyaltyPoints: number;
  channelData: ChannelOutput;
  cms: CmsPageVenue;
}

export function Summary(props: Props): React.ReactElement {
  const { serviceCount, price, loyaltyPoints, channelData, cms } = props;

  const servicesTemplate =
    serviceCount > 1
      ? cms['vc-bar']['services-plural']
      : cms['vc-bar']['services-singular'];

  const servicesText = servicesTemplate.replace('{0}', serviceCount.toString());
  const summarySuggestion =
    serviceCount === 1 ? cms['vc-bar']['service-summary-suggestion'] : '';
  const pricePrefix = price.range ? cms['vc-bar'].from : '';
  const formattedPrice = formatPrice(price.amount, channelData);

  return (
    <div className={styles.container} data-cy="summary">
      <Stack className={styles.summary}>
        <Viewport device="mobile">
          <Stack className={styles.servicesAndPrice}>
            <div className={styles.services}>{servicesText}&nbsp;</div>
            <div className={styles.price}>
              {pricePrefix} {formattedPrice}
            </div>
          </Stack>
        </Viewport>
        <Viewport device={['tablet', 'desktop']}>
          <Inline className={styles.servicesAndPrice}>
            <div className={styles.services}>{servicesText}&nbsp;</div>
            <div className={styles.price}>
              {pricePrefix} {formattedPrice}
            </div>
          </Inline>
        </Viewport>
        <Text as="div" type="caption" className={styles.summarySuggestion}>
          {summarySuggestion}
        </Text>
      </Stack>
      {loyaltyPoints > 0 && channelData.loyalty && (
        <Viewport device="desktop">
          <LoyaltyTotalContainer
            cms={cms}
            channel={channelData as ChannelLoyalty}
            total={loyaltyPoints}
          />
        </Viewport>
      )}
    </div>
  );
}
