import { trackStructuredEvent } from 'js/helpers/google-tag-manager';
import { getWindowDeviceType } from 'js/helpers/dom';
import { assertUnreachable } from 'js/types/assert-unreachable';
import { ClickArea } from 'js/pages/VenuePage/VenueTeamSection/TeamListItemHeading/TeamListItemHeading';
import { TeamMember } from 'js/pages/VenuePage/VenueTeamSection/VenueTeamSection';

enum Category {
  Venue = 'venue',
  MeetTheTeam = 'meet_the_team',
}

enum Property {
  MeetTheTeam = 'meet_the_team',
  StylistReviews = 'stylist_reviews',
  StylistServices = 'stylist_services',
  StylistDetails = 'stylist_details',
  StylistProfileDropdown = 'stylist_dropdown_menu',
  StylistPortfolioPhotos = 'stylist_portfolio_photos',
  StylistSentiments = 'stylist_sentiments',
}

enum Action {
  View = 'view',
  Click = 'click',
  Expand = 'expand',
  Collapse = 'collapse',
}

function trackEvent(
  category: Category,
  property: Property,
  action: Action | string,
  label?: string,
  value?: number
): void {
  trackStructuredEvent({
    category,
    property,
    action,
    label,
    value,
  });
}

export function trackSectionViewed(employeeCount: number): void {
  trackEvent(
    Category.Venue,
    Property.MeetTheTeam,
    Action.View,
    '',
    employeeCount
  );
}

export function trackFirstVisibleEmployeeViewed(
  teamMember: TeamMember,
  stylistId: number
): void {
  const sections = Object.keys(
    teamMember.sections
  ) as (keyof TeamMember['sections'])[];

  const eventLabel: string = sections
    .filter((section): boolean => teamMember.sections[section] !== undefined)
    .join('-');

  trackEvent(
    Category.MeetTheTeam,
    Property.StylistDetails,
    Action.View,
    eventLabel,
    stylistId
  );
}

export function trackStylistReviewsDesktop(
  stylistIndex: number,
  stylistId: number,
  label: string
): void {
  trackEvent(
    Category.MeetTheTeam,
    Property.StylistReviews,
    `${Action.Click}_${stylistIndex}`,
    label,
    stylistId
  );
}

export function trackStylistDetailsDesktop(
  stylistIndex: number,
  stylistId: number,
  label: string
): void {
  trackEvent(
    Category.MeetTheTeam,
    Property.StylistDetails,
    `${Action.Click}_${stylistIndex}`,
    label,
    stylistId
  );
}

export function trackStylistDetailsMobile(
  hasBeenExpanded: boolean,
  stylistIndex: number,
  stylistId: number,
  label: string
): void {
  trackEvent(
    Category.MeetTheTeam,
    Property.StylistDetails,
    hasBeenExpanded
      ? `${Action.Collapse}_${stylistIndex}`
      : `${Action.Expand}_${stylistIndex}`,
    label,
    stylistId
  );
}

export function trackStylistReviewsMobile(
  hasBeenExpanded: boolean,
  stylistIndex: number,
  stylistId: number,
  label: string
): void {
  trackEvent(
    Category.MeetTheTeam,
    Property.StylistReviews,
    hasBeenExpanded
      ? `${Action.Collapse}_${stylistIndex}`
      : `${Action.Expand}_${stylistIndex}`,
    label,
    stylistId
  );
}

export function trackStylistChevronMobile(
  hasBeenExpanded: boolean,
  stylistIndex: number,
  stylistId: number,
  label: string
): void {
  trackEvent(
    Category.MeetTheTeam,
    Property.StylistProfileDropdown,
    hasBeenExpanded
      ? `${Action.Collapse}_${stylistIndex}`
      : `${Action.Expand}_${stylistIndex}`,
    label,
    stylistId
  );
}

export function trackStylistPortfolioClicked(
  imageIndex: number,
  stylistId: number
): void {
  trackEvent(
    Category.MeetTheTeam,
    Property.StylistPortfolioPhotos,
    `${Action.Click}_${imageIndex}`,
    '',
    stylistId
  );
}

export function trackHeadingClick(
  teamMemberIndex: number,
  teamMember: TeamMember,
  clickArea: ClickArea,
  isExpanded: boolean
): void {
  const sections = Object.keys(
    teamMember.sections
  ) as (keyof TeamMember['sections'])[];

  const eventLabel: string = sections
    .filter((section): boolean => teamMember.sections[section] !== undefined)
    .join('-');

  switch (clickArea) {
    case ClickArea.Reviews:
      if (getWindowDeviceType() === 'mobile') {
        trackStylistReviewsMobile(
          isExpanded,
          teamMemberIndex + 1,
          teamMember.id,
          eventLabel
        );
      } else {
        trackStylistReviewsDesktop(
          teamMemberIndex + 1,
          teamMember.id,
          eventLabel
        );
      }
      break;
    case ClickArea.Details:
      if (getWindowDeviceType() === 'mobile') {
        trackStylistDetailsMobile(
          isExpanded,
          teamMemberIndex + 1,
          teamMember.id,
          eventLabel
        );
      } else {
        trackStylistDetailsDesktop(
          teamMemberIndex + 1,
          teamMember.id,
          eventLabel
        );
      }
      break;
    case ClickArea.Chevron:
      trackStylistChevronMobile(
        isExpanded,
        teamMemberIndex + 1,
        teamMember.id,
        eventLabel
      );
      break;
    default:
      assertUnreachable(clickArea);
      break;
  }
}

export function trackServicesClick(
  serviceIndex: number,
  treatmentTypeId: number,
  stylistId: number
): void {
  trackEvent(
    Category.MeetTheTeam,
    Property.StylistServices,
    `${Action.Click}_${serviceIndex}`,
    treatmentTypeId.toString(),
    stylistId
  );
}

export function trackSentimentClick(
  sentimentPosition: number,
  id: number,
  sentimentAmount: number,
  stylistId: number
): void {
  trackEvent(
    Category.MeetTheTeam,
    Property.StylistSentiments,
    `click_${sentimentPosition}`,
    `${id}_${sentimentAmount}`,
    stylistId
  );
}
