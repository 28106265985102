export interface CmsServiceDetails {
  about: {
    'treatment-guide': string;
    header: string;
  };
  'good-to-know': {
    header: string;
  };
  rating: {
    header: string;
  };
  restrictions: {
    header: string;
  };
  'service-reviews': {
    header: string;
    'show-reviews-label': string;
  };
  title: string;
  'mobile-title': string;
  'spa-day-title': string;
  'spa-day-mobile-title': string;
  'spa-break-title': string;
  'spa-break-mobile-title': string;
  'no-details-message': string;
  'service-images-header': string;
  footer: {
    'select-singular': string;
    'service-singular': string;
    select: string;
    selected: string;
  };
}

export interface CmsVenueTeamSection {
  heading: string;
  'review-count': string;
  'section-headings': {
    about: string;
    portfolio: string;
    sentiments: string;
    services: string;
  };
  'portfolio-count': {
    singular: string;
    plural: string;
  };
}

export interface CmsVenuePage {
  venue: {
    menu: {
      'button-buy': string;
      'button-book': string;
      labels: {
        from: string;
        save: string;
        'per-person': string;
      };
    };
  };
  page: {
    venue: CmsPageVenue;
  };
}

export interface CmsPageVenue {
  'service-details': CmsServiceDetails;
  'venue-team-section': CmsVenueTeamSection;
  amenities: {
    amenities: string;
  };
  'header-navigation': {
    about: string;
    reviews: string;
    'book-now': string;
  };
  details: {
    chain: string;
  };
  'our-work': {
    heading: string;
    'employee-title': string;
    reviews: string;
    'service-title': string;
    'more-from': string;
    'book-button-title': string;
    'book-with': string;
  };
  'vc-bar': {
    'services-singular': string;
    'services-plural': string;
    'service-summary-suggestion': string;
    'appointment-btn': string;
    'loyalty-total': string;
    from: string;
    patchtestwarning: string;
    patchtestpopup: string;
    patchtestwarningpopup: string;
    patchtestwarningpopupheader: string;
    patchtestwarningpopupbutton: string;
    'loyalty-modal': {
      title: string;
      cta: {
        label: string;
      };
      closeButton: { label: string };
      instructionSteps: {
        book: { label: string; description: string };
        earn: { label: string; description: string };
        redeem: { label: string; description: string };
      };
    };
  };
  'handover-modal': {
    heading: string;
    message: string;
  };
}

export interface CmsVenueAmenities {
  amenities: string;
}

export interface CmsOurWork {
  heading: string;
  'service-title': string;
  'employee-title': string;
  plural: string;
  singular: string;
  'book-button-title': string;
  'book-with': string;
  'view-button': string;
  'mobile-view-tap-image': string;
  'more-from': string;
  patchtestwarning: string;
  patchtestpopup: string;
  patchtestwarningpopup: string;
  'save-up-to': string;
  save: string;
  from: string;
}

export function getServiceDetailsLabels(cms: CmsVenuePage): CmsServiceDetails {
  return cms.page.venue['service-details'];
}
