import React, { useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Text, useDevice, useViewport } from '@treatwell/ui';
import { ProductOutput } from 'js/model/rainbow/venue/ProductOutput';
import { Context } from 'js/components/LocaleWrapper';
import { t } from 'i18next';
import styles from './ProductsUsed.module.css';

const INITIAL_VISIBLE_PRODUCTS = 6;

export const ProductsUsed = ({
  products,
  fullWidth,
}: {
  products: ProductOutput[];
  fullWidth: boolean;
}) => {
  const { i18n } = useContext(Context);
  const { isMobile: isMobileDevice } = useDevice();
  const isMobile = useViewport({
    device: 'mobile',
    serverRender: isMobileDevice,
  });
  const getVisibleProductsCount = useCallback(
    () => (isMobile ? INITIAL_VISIBLE_PRODUCTS : products.length),
    [isMobile, products.length]
  );
  const [visibleProducts, setVisibleProducts] = useState(
    getVisibleProductsCount()
  );

  useEffect(() => {
    setVisibleProducts(getVisibleProductsCount());
  }, [getVisibleProductsCount]);

  return (
    <>
      <Text type="mdHeader" as="h2">
        {i18n('page.venue.section-titles.products-used')}
      </Text>
      <ul
        className={clsx(styles.products, {
          [styles.fullWidth]: fullWidth,
        })}
      >
        {products.map(({ name }, index) => (
          <li
            key={name}
            style={{
              display: index < visibleProducts ? 'list-item' : 'none',
            }}
          >
            <Text type="body">{name}</Text>
          </li>
        ))}
      </ul>
      {visibleProducts < products.length && (
        <Button
          buttonStyle="secondary"
          buttonVariant="outline"
          onClick={() => {
            setVisibleProducts(products.length);
          }}
          data-cy="see-all-brands"
          fullWidth
        >
          {t<string>('browsePage.productsSection.seeAllBrandsButton')}
        </Button>
      )}
    </>
  );
};
