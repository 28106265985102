import { generateRuid } from 'js/helpers/ruid';
import { fetchCached, RequestData } from 'js/helpers/service';
import { VenueMenuItemDetailsOutput } from 'js/model/rainbow/venue/VenueMenuItemDetailsOutput';
import { VenueMenuItemReviewsOutput } from 'js/model/rainbow/venue/VenueMenuItemReviewsOutput';

export enum MenuItemType {
  Stay = 'stay',
  DayPass = 'day_pass',
}

export interface MenuItemData {
  options: {
    fulfilmentTypes: unknown[];
  }[];
}

export interface MenuItemVenue {
  id: number;
  name: string;
  normalisedName: string;
}

export interface MenuItem {
  item: {
    data: MenuItemData;
  };
}

export const fetchVenueMenuItemReviews = (
  state: RequestData,
  venueId: number,
  menuItemId: string,
  page: number,
  size: number,
  ruid = generateRuid()
) => {
  const params = [
    `page=${page}`,
    `size=${size}`,
    'includeResponses=true',
    'excludeUnverifiedReviews=true',
  ].join('&');
  const menuItem = menuItemId ? `menuItemId=${menuItemId}&` : '';
  const path = `/api/v1/venue/${venueId}/review?${menuItem}${params}`;
  return fetchCached<VenueMenuItemReviewsOutput>(state, path, ruid);
};

export const fetchVenueMenuItemDetails = (
  state: RequestData,
  venueId: number,
  menuItemId: string,
  ruid = generateRuid()
) =>
  fetchCached<VenueMenuItemDetailsOutput>(
    state,
    `/api/v1/venue/${venueId}/menu/${menuItemId}/display-summary`,
    ruid
  );
