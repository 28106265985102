import React from 'react';
import { Inline, Text } from '@treatwell/ui';
import { Icon } from 'js/components/TreatmentTypeIcon/TreatmentTypeIcon';
import { TreatmentTypeIcon } from 'js/components/TreatmentTypeIcon';
import { TeamListItemContentSection } from '../TeamListItemContentSection';
import { trackServicesClick } from '../tracking';
import styles from './TeamListItemServicesSection.module.css';

interface Props {
  id: number;
  heading: string;
  services: {
    id: number;
    icon: Icon;
    name: string;
  }[];
}

export function TeamListItemServicesSection(props: Props): React.ReactElement {
  const onServiceClick = (serviceId: number, index: number): void => {
    trackServicesClick(index + 1, serviceId, props.id);
  };

  const services = props.services.sort((a, b) => a.name.length - b.name.length);

  return (
    <TeamListItemContentSection heading={props.heading}>
      <div className={styles.container}>
        <Inline space="xxs" wrap>
          {services.map((service, index) => (
            <div
              className={styles.service}
              key={service.name}
              onClick={() => onServiceClick(service.id, index)}
            >
              <TreatmentTypeIcon icon={service.icon} />
              <Text as="div" type="caption" className={styles.ellipsis}>
                {service.name}
              </Text>
            </div>
          ))}
        </Inline>
      </div>
    </TeamListItemContentSection>
  );
}
