import React from 'react';
import { PageSection } from 'js/components/PageSection';

import { Context } from 'js/components/LocaleWrapper';
import { VenueReviews } from './VenueReviews';

const sectionId = 'reviews';

interface Props {
  [key: string]: unknown;
}

export function VenueReviewsSection(props: Props): React.ReactElement {
  const { i18n, pageData } = React.useContext(Context);
  const channelData = pageData.channel;
  const { venue, ...requestData } = pageData;
  const { hasReviews } = venue;

  if (!hasReviews) {
    return <div />;
  }

  return (
    <PageSection
      id={sectionId}
      title={i18n('page.venue.section-titles.reviews')}
    >
      <VenueReviews
        requestData={requestData}
        venue={venue}
        channelData={channelData}
        i18n={i18n}
        {...props}
      />
    </PageSection>
  );
}
