import React from 'react';
import { Inline } from '@treatwell/ui';
import { PaginationOutput } from 'js/model/rainbow/PaginationOutput';
import {
  previousPaginationPage,
  nextPaginationPage,
} from 'js/helpers/uri-util';
import { calculatePagination } from './calculate-pagination';

import styles from './Pagination.module.css';
import { PageLink } from './PageLink';

interface Props {
  pagination: PaginationOutput;
  createPageUri: (page: number) => string;
  onPageChange?: (page: number) => void;
}

const MAX_PAGE_COUNT = 5;

export class Pagination extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { pagination, createPageUri, onPageChange } = this.props;
    const currentPage = pagination.page;
    const totalPages = pagination.totalPages;

    if (totalPages === 1) {
      return null;
    }

    const {
      pages,
      ellipsisStart,
      ellipsisEnd,
      previousNavigation,
      nextNavigation,
    } = calculatePagination(currentPage, totalPages, MAX_PAGE_COUNT);

    const displayedPagination = pages.map(page => (
      <PageLink
        key={page}
        page={page}
        href={createPageUri(page + 1)}
        active={currentPage === page}
        changePage={onPageChange}
      />
    ));

    if (ellipsisStart) {
      displayedPagination.splice(
        1,
        0,
        <div key="ellipsis-start" className={styles.item}>
          &hellip;
        </div>
      );
    }

    if (ellipsisEnd) {
      displayedPagination.splice(
        -1,
        0,
        <div key="ellipsis-end" className={styles.item}>
          &hellip;
        </div>
      );
    }

    if (previousNavigation) {
      displayedPagination.unshift(
        <PageLink
          key="prev"
          direction="prev"
          page={currentPage - 1}
          href={createPageUri(previousPaginationPage(currentPage))}
          changePage={onPageChange}
        />
      );
    }

    if (nextNavigation) {
      displayedPagination.push(
        <PageLink
          key="next"
          direction="next"
          page={currentPage + 1}
          href={createPageUri(nextPaginationPage(currentPage))}
          changePage={onPageChange}
        />
      );
    }

    return (
      <Inline justify="center" className={styles.container}>
        {displayedPagination}
      </Inline>
    );
  }
}
