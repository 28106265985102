import React from 'react';
import { MarketplaceVenuePage } from 'js/pages/VenuePage/MarketplaceVenuePage';
import { WidgetVenuePage } from 'js/pages/VenuePage/Widget/VenuePage';
import {
  embedWidgetInIframe,
  WidgetInIframe,
} from 'js/pages/VenuePage/Widget/EmbedInIFrame';
import { Context } from 'js/components/LocaleWrapper';

export default function VenuePage(): React.ReactElement {
  if (embedWidgetInIframe()) {
    return <WidgetInIframe />;
  }

  return (
    <Context.Consumer>
      {value =>
        value.isWidget ? (
          <WidgetVenuePage
            pageData={value.pageData}
            generateUri={value.generateUri}
            i18n={value.i18n}
            isWidget={value.isWidget}
          />
        ) : (
          <MarketplaceVenuePage />
        )
      }
    </Context.Consumer>
  );
}
