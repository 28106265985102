import React from 'react';
import clsx from 'clsx';
import { Rating, Viewport } from '@treatwell/ui';
import { formatDistance, FormatterChannelData } from 'js/helpers/formatters';
import gridStyles from 'assets/style/grid.module.css';
import { Context } from 'js/components/LocaleWrapper';
import { useIsMounted } from 'js/hooks/useIsMounted';
import { trackVenueResultData } from 'js/pages/BrowsePage/BrowseResult/tracking';
import styles from './NearbyVenue.module.css';

interface Props {
  name: string;
  uri: string;
  rating: number;
  reviews: number;
  distance?: number;
  image: string;
  isProListing?: boolean;
  channelData: FormatterChannelData;
  venueRank: number;
  venueId: number;
}

export function NearbyVenue(props: Props): React.ReactElement {
  const {
    name,
    uri,
    rating,
    reviews,
    distance,
    image,
    isProListing = false,
    channelData,
    venueRank,
    venueId,
  } = props;

  const isBrowser = useIsMounted();

  function renderImage(): React.ReactNode | null {
    if (isProListing) {
      return null;
    }
    const img = <img src={image} alt={name} title={name} />;
    if (!isBrowser) {
      return img;
    }
    return <Viewport device={['tablet', 'desktop']}>{img}</Viewport>;
  }

  const { i18n } = React.useContext(Context);

  return (
    <div
      className={clsx(
        styles.wrapper,
        gridStyles['col-xs-12'],
        gridStyles['col-sm-4'],
        gridStyles['col-md-3'],
        { [styles.isBasicListing]: !isProListing }
      )}
      onClick={async () => {
        await trackVenueResultData({
          eventName: 'search_venue_clicked',
          venueId,
          rating: {
            count: reviews,
            average: rating,
            displayAverage: rating.toString(),
          },
          countryCode: channelData.country?.countryCode || '',
          pageName: 'venue_page',
          venueRank,
        });
      }}
    >
      <a href={uri} className={styles.venue}>
        {renderImage()}
        <div className={styles.details}>
          <span className={styles.name}>{name}</span>
          {!distance ? null : (
            <span className={styles.distance}>
              {i18n(
                'page.venue.venues-nearby.distance',
                formatDistance(distance, channelData)
              )}
            </span>
          )}
        </div>
        <div className={styles.rating}>
          {!rating ? null : (
            <span className={styles.rate}>
              <Rating rating={rating} size={16} showRatingValue={false} />
            </span>
          )}
          <span className={styles.reviews}>
            {!reviews ? i18n('venue.reviews.no-reviews') : `(${reviews})`}
          </span>
        </div>
      </a>
    </div>
  );
}
