import React from 'react';
import clsx from 'clsx';
import { Inline, Stack, Text, Rating, useViewport } from '@treatwell/ui';
import gridStyles from 'src/assets/style/grid.module.css';
import styles from './VenueCriteria.module.css';

interface Criteria {
  name: string;
  average: string | number;
}

interface Props {
  dimensions: Criteria[];
}

export function VenueCriteria(props: Props): React.ReactElement | null {
  const { dimensions } = props;
  const isMobile = useViewport({ device: 'mobile' });
  const isTablet = useViewport({ device: 'tablet' });
  const isDesktop = useViewport({ device: 'desktop' });
  const classnames = clsx(gridStyles['col-sm-4'], styles.criteriaCol);

  if (!dimensions.length) {
    return null;
  }

  function renderCriteriaContent(criteria: Criteria): React.ReactNode {
    return (
      <>
        <div className={styles.name}>
          <Text>{criteria.name}</Text>
        </div>
        <Rating rating={criteria.average} size={24} showRatingValue={false} />
      </>
    );
  }

  return (
    <div className={classnames}>
      {dimensions.map((criteria, index) => (
        <div key={index} className={styles.criteria}>
          {(isMobile || isDesktop) && (
            <Inline align="center">{renderCriteriaContent(criteria)}</Inline>
          )}
          {isTablet && (
            <Stack space="xs">{renderCriteriaContent(criteria)}</Stack>
          )}
        </div>
      ))}
    </div>
  );
}
