import React from 'react';
import { FormatterChannelData } from 'js/helpers/formatters';
import { VenueScore } from 'js/components/VenueScore';
import { ReviewAggregates } from 'js/model/rainbow/venue/VenueMenuItemDetailsOutput';
import styles from './ServiceVenueScore.module.css';
import { ServiceSection } from '../ServiceSection';

interface Props {
  header: string;
  channelData: FormatterChannelData;
  reviewAggregates: ReviewAggregates;
}

export function ServiceVenueScore({
  header,
  channelData,
  reviewAggregates,
}: Props): React.ReactElement | null {
  const { average, count } = reviewAggregates;
  if (!count) {
    return null;
  }
  return (
    <ServiceSection header={header}>
      <VenueScore
        average={average}
        count={count}
        channelData={channelData}
        extraStyles={styles}
        inServicePopup
      />
    </ServiceSection>
  );
}
