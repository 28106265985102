import React from 'react';
import Search from 'js/components/Search';
import { Divider } from 'js/components/generic/Divider';
import styles from './VenueSearch.module.css';

interface Props {
  children?: React.ReactNode;
  title?: string;
  typeAhead?: {
    venueTypeIds?: number;
    treatmentIds?: number;
  };
  browseUriValues: {
    offerResultType?: string;
    treatments?: string;
    treatmentType?: string;
    venueType?: string;
  };
  popularLocations?: [];
  onSelectedLocationChange?: (location: string, index: number) => void;
  onSearchButtonClick?: (status: string) => void;
}

export function VenueSearch(props: Props): React.ReactElement {
  const {
    title,
    typeAhead,
    children,
    browseUriValues,
    popularLocations,
    onSelectedLocationChange = () => {},
    onSearchButtonClick = () => {},
  } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <Search
        {...typeAhead}
        browseUriValues={browseUriValues}
        popularLocations={popularLocations}
        onSelectedLocationChange={onSelectedLocationChange}
        onSearchButtonClick={onSearchButtonClick}
      />
      {children ? (
        <div>
          <Divider />
          {children}
        </div>
      ) : null}
    </div>
  );
}
