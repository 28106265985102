import { isBrowser } from 'js/helpers/environment';

export interface ImageUris {
  [size: string]: string;
}

interface WidthToUri {
  width: number;
  uri: string;
}

const DIMENSIONS_REGEX = /^(-?\d+)x(-?\d+)$/;

/*
 * This class's purpose is, given an object representing an image with multiple
 * different resolution urls (see ImageUris interface above), choose the most appropriately
 * sized url for a given container's size. The intention is to reduce unnecessarily large
 * image downloads on smaller viewports.
 *
 * This implementation uses internal state is optimise selection to ensure we do not load
 * smaller images if the user has previously downloaded a higher quality one
 */
export class ImageSizer {
  private widthsToUris: WidthToUri[];

  constructor(imageUris: ImageUris) {
    this.widthsToUris = Object.keys(imageUris).reduce(
      (acc: WidthToUri[], dimensions: string) => {
        acc.push({
          width: ImageSizer.parseDimensions(dimensions).width,
          uri: imageUris[dimensions],
        });
        return acc;
      },
      []
    );
  }

  private static withinBounds(
    upscaleAllowance: number,
    targetWidth: number,
    proposedWidth: number
  ): boolean {
    return proposedWidth >= targetWidth - targetWidth * upscaleAllowance;
  }

  private static parseDimensions(
    dimensions: string
  ): { width: number; height: number } {
    const result = dimensions.match(DIMENSIONS_REGEX);

    if (!result) {
      throw new Error(`${dimensions} is not a recognised dimension format`);
    }

    return {
      width: parseInt(result[1], 10),
      height: parseInt(result[2], 10),
    };
  }

  // This implementation selects the resolution which has the smallest absolute difference
  // to the container size given
  public getBestImage(
    containerWidth: number,
    calculatePhysicalWidth = true,
    upscaleAllowance = Infinity
  ): string {
    const physicalWidth =
      containerWidth *
      (isBrowser && calculatePhysicalWidth ? window.devicePixelRatio : 1);

    const best = this.widthsToUris.reduce(
      (prev: WidthToUri, next: WidthToUri) => {
        const { width: prevWidth } = prev;
        const { width: nextWidth } = next;

        const nextWithinBounds = ImageSizer.withinBounds(
          upscaleAllowance,
          physicalWidth,
          nextWidth
        );
        const prevWithinBounds = ImageSizer.withinBounds(
          upscaleAllowance,
          physicalWidth,
          prevWidth
        );

        if (!(nextWithinBounds && prevWithinBounds)) {
          if (nextWithinBounds) {
            return next;
          }
          if (prevWithinBounds) {
            return prev;
          }
        }

        const prevDifference = Math.abs(prevWidth - physicalWidth);
        const nextDifference = Math.abs(nextWidth - physicalWidth);

        if (prevDifference === nextDifference) {
          return nextWidth > prevWidth ? next : prev;
        }
        if (nextDifference < prevDifference) {
          return next;
        }
        return prev;
      }
    );

    // Remove resolutions smaller than the 'best' one so they can never be selected
    this.widthsToUris = this.widthsToUris.filter(
      ({ width }: WidthToUri) => width >= best.width
    );

    return best.uri;
  }
}
