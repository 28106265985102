export function isElementInView(
  element: Element,
  innerContainer: HTMLElement
): boolean {
  const view = element.getBoundingClientRect();
  const containerView = innerContainer.getBoundingClientRect();

  return (
    view.top >= 0 &&
    view.left >= 0 &&
    view.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    view.left >= containerView.left &&
    view.right <= containerView.right
  );
}
