import React from 'react';
import { Text } from '@treatwell/ui';
import { TeamListItemContentSection } from 'js/pages/VenuePage/VenueTeamSection/TeamListItemContentSection';
import styles from './TeamListItemAboutSection.module.css';

interface Props {
  content: string;
  heading: string;
}

export function TeamListItemAboutSection(props: Props): React.ReactElement {
  const { content, heading } = props;

  return (
    <TeamListItemContentSection heading={heading}>
      <Text as="p" className={styles.container}>
        {content}
      </Text>
    </TeamListItemContentSection>
  );
}
