import React from 'react';

import { StateData } from 'js/model/rainbow/StateData';
import { VenueMenuSection } from '../VenueMenuSection/VenueMenuSection';
import { VisitConfiguratorBar } from '../VisitConfiguratorBar/VisitConfiguratorBar';
import { PopupsSection } from '../PopupsSection/PopupsSection';
import { VenueHeader } from './VenueHeader';
import { VenueFooter } from './VenueFooter';
import {
  setFBEPixelIdCookie,
  setUtmCampaignCookie,
} from './gtm-trigger-cookie';
import styles from '../styles.module.css';

interface Props {
  pageData: StateData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateUri: (pageType: string, ...args: unknown[]) => any;
}

export function WidgetActiveVenue({
  pageData,
  generateUri,
}: Props): React.ReactElement {
  const channel = pageData.channel;
  const venuePageData = pageData.venue;
  const venue = venuePageData.venue;
  const { footer } = pageData.cms;

  const alternateLocaleUris =
    (venuePageData && venuePageData.alternateLocaleUris) || [];

  setUtmCampaignCookie();
  setFBEPixelIdCookie(venue);

  return (
    <div data-venue-id={venue.id}>
      <div className={styles.wrapper}>
        <VenueHeader
          venue={venue}
          channelData={channel}
          alternateLocaleUris={alternateLocaleUris}
        />
        <VenueMenuSection />
        <PopupsSection pageData={pageData} isWidget />
        <VenueFooter cms={footer} />
      </div>
      <VisitConfiguratorBar
        channel={channel}
        cms={pageData.cms.page.venue}
        pageData={pageData}
        generateUri={generateUri}
      />
    </div>
  );
}
