import React, { useContext } from 'react';
import clsx from 'clsx';
import {
  Button,
  IconExpand,
  LazyImage,
  mParticleEventType,
  mParticleLogEvent,
} from '@treatwell/ui';
import { ImageSizer } from 'js/helpers/ImageSizer';
import { CmsOurWork } from 'js/model/cms/cms-venue-page';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import { useIsMounted } from 'js/hooks/useIsMounted';
import { Context } from 'js/components/LocaleWrapper';
import styles from './VenuePortfolioCarouselGallery.module.css';

interface Props {
  image: ImageOutput;
  cms: CmsOurWork;
  containerWidth: number;
  imageSize: 'big' | 'small';
  onClick: (image: ImageOutput) => void;
}

const MOBILE_BREAKPOINT = parseInt(styles['tablet-layout-width'], 10);
const MOBILE_BIG_IMAGE_WIDTH = parseInt(styles.mobileBigImageWidth, 10);
const MOBILE_SMALL_IMAGE_WIDTH = parseInt(styles.mobileImageWidth, 10);
const DESKTOP_BIG_IMAGE_WIDTH = parseInt(styles.desktopBigImageWidth, 10);
const DESKTOP_SMALL_IMAGE_WIDTH = parseInt(styles.desktopImageWidth, 10);

const MOBILE_BIG_IMAGE_HEIGHT = parseInt(styles.mobileBigImageHeight, 10);
const MOBILE_SMALL_IMAGE_HEIGHT = parseInt(styles.mobileImageHeight, 10);
const DESKTOP_BIG_IMAGE_HEIGHT = parseInt(styles.desktopBigImageHeight, 10);
const DESKTOP_SMALL_IMAGE_HEIGHT = parseInt(styles.desktopImageHeight, 10);

let width = 0;
let height = 0;
let url = '';

export function VenuePortfolioCarouselImage({
  imageSize,
  image,
  cms,
  containerWidth,
  onClick,
}: Props): React.ReactElement {
  const { channel } = useContext(Context);
  const [showButton, setShowButton] = React.useState<boolean>(false);

  const imageSizer = new ImageSizer(image.uris);

  const isBrowser = useIsMounted();

  if (isBrowser) {
    url = imageSizer.getBestImage(1.5 * containerWidth, false);
  }

  const cssProperties = {
    '--Button--secondary-backgroundColor': 'var(--color-white)',
    '--Button--secondary-color': 'var(--color-base-navy)',
  } as React.CSSProperties;

  const onMouseEnter = (): void => {
    setShowButton(true);
  };

  const onMouseLeave = (): void => {
    setShowButton(false);
  };

  const onClickHandler = (): void => {
    mParticleLogEvent('portfolio_image_clicked', mParticleEventType.Other, {
      country: channel.country.countryCode,
      platform: 'web',
    });
    onClick(image);
    onMouseLeave();
  };

  const getClassnames = (
    bigImageClassname: string,
    smallImageClassname: string
  ): string =>
    clsx({
      [bigImageClassname]: imageSize === 'big',
      [smallImageClassname]: imageSize === 'small',
    });

  if (containerWidth <= MOBILE_BREAKPOINT) {
    if (imageSize === 'big') {
      width = MOBILE_BIG_IMAGE_WIDTH;
      height = MOBILE_BIG_IMAGE_HEIGHT;
    }
    if (imageSize === 'small') {
      width = MOBILE_SMALL_IMAGE_WIDTH;
      height = MOBILE_SMALL_IMAGE_HEIGHT;
    }
  } else if (containerWidth > MOBILE_BREAKPOINT) {
    if (imageSize === 'big') {
      width = DESKTOP_BIG_IMAGE_WIDTH;
      height = DESKTOP_BIG_IMAGE_HEIGHT;
    }
    if (imageSize === 'small') {
      width = DESKTOP_SMALL_IMAGE_WIDTH;
      height = DESKTOP_SMALL_IMAGE_HEIGHT;
    }
  }

  return (
    <div
      className={getClassnames(
        styles.bigImageContainer,
        styles.smallImgsContainer
      )}
    >
      <LazyImage
        src={url}
        width={width}
        height={height}
        className={getClassnames(styles.bigImage, styles.smallImage)}
        onClick={onClickHandler}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />

      {showButton && containerWidth > MOBILE_BREAKPOINT && (
        <div
          className={styles.button}
          style={cssProperties}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Button
            buttonStyle="secondary"
            icon={<IconExpand size={24} />}
            iconPosition="right"
            onClick={onClickHandler}
          >
            {cms['view-button']}
          </Button>
        </div>
      )}
    </div>
  );
}
