import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { VenueTag, VenueTagType } from 'js/components/VenueTag';
import { getItemByServiceId, getTreatmentTypeId } from 'js/helpers/venue-menu';

import {
  trackServiceSelect,
  trackServiceDetailsEvent,
  trackCorrectBasketEvent,
  Action,
} from 'js/pages/VenuePage/tracking';
import { getVenueMenu } from 'js/redux-modules/venue-page';

import { openServicePopup } from 'js/redux-modules/venue-page/popup';

import {
  selectMenuOption,
  unselectMenuOption,
  toggleVenueMenuService,
  toggleVenueMenuHighlightedService,
} from 'js/redux-modules/venue-page/venue-menu';
import {
  MenuItem,
  SERVICE_POPUP_TYPE,
} from 'js/pages/VenuePage/VenueMenuSection/VenueMenu/MenuItem';
import { MenuGroup } from 'js/model/rainbow/venue/VenueOutput';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { SalesPriceDiscountType } from 'js/model/rainbow/SalesPriceDiscountType';
import {
  DurationRange,
  TreatmentVenueMenuItemOutput,
} from 'js/model/rainbow/venue/TreatmentVenueMenuItemOutput';
import { EmployeeLevelItem } from 'js/model/rainbow/venue/EmployeeOutput';
import { Context, GeneralContext } from 'js/components/LocaleWrapper';
import { useIsMounted } from 'js/hooks/useIsMounted';
import { mParticleEventType, mParticleLogEvent } from '@treatwell/ui';
import styles from './MenuGroupItems.module.css';

type VenueState = {
  selected: {};
};

type ItemOptions = {
  item: TreatmentVenueMenuItemOutput;
  options2D: EmployeeLevelItem[];
  itemGroup?: never;
  itemOptionGroups?: never;
};

type MenuGroupItem = {
  itemGroup: TreatmentVenueMenuItemOutput;
  itemOptionGroups: ItemOptions[];
  item?: never;
  options2D?: never;
};

type ItemsOutput = ItemOptions | MenuGroupItem;

type Props = PropsFromRedux & {
  group: MenuGroup;
  isSuggestedGroup: boolean;
  isActive: boolean;
  venueMenu: VenueState;
  channel: ChannelOutput;
  selectMenuOption: {};
  unselectMenuOption: {};
  toggleVenueMenuHighlightedService: {};
  toggleVenueMenuService: {};
  openServicePopup: {};
  showDiscountRow?: boolean;
  trackingGroupType: string;
};

export function prepareItemData(
  menuItem: TreatmentVenueMenuItemOutput
): ItemsOutput {
  if (!menuItem.optionGroups) {
    return {
      item: menuItem,
      options2D: [],
    };
  }

  const singleGroupSingleOption =
    menuItem.optionGroups.length === 1 &&
    menuItem.optionGroups[0].options.length === 1;

  // Single Group with Single Option
  // We fold the option right up to the service
  // Service name stays the same, but item.id becomes option.id
  if (singleGroupSingleOption) {
    const optionGroup = menuItem.optionGroups[0];

    return {
      item: {
        ...menuItem,
        id: optionGroup.options[0].id,
      },
      options2D: [],
    };
  }

  // Single Group with options2D
  // We fold the options2D up to the OptionGroup level
  // OptionGroup takes option.id and option.name
  if (menuItem.optionGroups.length === 1) {
    const optionGroup = menuItem.optionGroups[0];
    const itemOptionGroups: ItemOptions[] = optionGroup.options.map(
      option =>
        ({
          item: ({
            ...optionGroup,
            id: option.id,
            name: option.name,
            priceRange: option.priceRange,
          } as unknown) as TreatmentVenueMenuItemOutput,
          options2D: [],
        } as ItemOptions)
    );

    return {
      itemGroup: menuItem,
      itemOptionGroups,
    };
  }

  const itemOptionGroups = menuItem.optionGroups.map(optionGroup => {
    let options2D = optionGroup.options || [];
    let id = menuItem.id;

    // If there is only a single option, override the option group
    if (options2D.length === 1) {
      id = options2D[0].id;
      options2D = [];
    }

    return ({
      item: {
        ...optionGroup,
        id,
      },
      options2D,
    } as unknown) as ItemOptions;
  });

  return {
    itemGroup: menuItem,
    itemOptionGroups,
  };
}

function MenuGroupItemsComponent(props: Props): JSX.Element | null {
  const {
    group,
    isSuggestedGroup,
    venueMenu,
    channel,
    showDiscountRow = true,
  } = props;
  const isMounted = useIsMounted();

  function someItemsHaveDiscount(): boolean {
    const items = group.menuItems;
    const itemsWithDiscount = items.reduce(
      (sum, item) =>
        item.data.priceRange.yieldDiscountTypes.length ? sum + 1 : sum,
      0
    );

    return itemsWithDiscount > 0 && itemsWithDiscount < items.length;
  }

  function getDiscounts(context: GeneralContext): string | null {
    if (!props.group) {
      return null;
    }
    const discountTypes = group.priceRange.yieldDiscountTypes;

    if (
      discountTypes.length === 2 &&
      discountTypes.indexOf(SalesPriceDiscountType.Offpeak) > -1 &&
      discountTypes.indexOf(SalesPriceDiscountType.Jit) > -1
    ) {
      return context.i18n('venue.menu.jit-and-offpeak-discount-active');
    }
    if (discountTypes.length === 1) {
      if (discountTypes[0] === SalesPriceDiscountType.Offpeak) {
        return context.i18n('venue.menu.offpeak-discount-active');
      }
      if (discountTypes[0] === SalesPriceDiscountType.Jit) {
        return context.i18n('venue.menu.jit-discount-active');
      }
    }

    return null;
  }

  function getGroupItemDuration(
    item?: TreatmentVenueMenuItemOutput
  ): DurationRange | undefined {
    if (!item) {
      return;
    }
    const durationRange = { ...item.durationRange } || {};

    if (item.durationMinutes) {
      durationRange.minDurationMinutes = item.durationMinutes;
      durationRange.maxDurationMinutes = item.durationMinutes;
      durationRange.range = false;
    }

    return durationRange;
  }

  function handleSelectItem(
    groupId: number | undefined,
    serviceId: string | undefined,
    optionId: string | number | undefined,
    selected: boolean,
    isMulti: boolean,
    treatmentTypeId: number | undefined
  ): void {
    if (!serviceId || !groupId) {
      return;
    }
    const numericServiceId = parseInt(serviceId.substring(2), 10);
    if (selected) {
      const selectedGroup = props.venueMenu.selected[groupId];

      if (!isMulti && selectedGroup && selectedGroup[serviceId]) {
        const selectedService = selectedGroup[serviceId] || {};
        const removeIds = Object.keys(selectedService).filter(
          id => selectedService[id]
        );

        removeIds.forEach(id => {
          props.unselectMenuOption(groupId, serviceId, id, treatmentTypeId);
        });
      }

      props.selectMenuOption(groupId, serviceId, optionId, treatmentTypeId);
      mParticleLogEvent('treatment_selected', mParticleEventType.Other, {
        country: props.channel.country.countryCode,
        platform: 'web',
      });
      trackServiceSelect([numericServiceId], [], [props.group]);
      trackCorrectBasketEvent(
        Action.AddToBasket,
        props.trackingGroupType,
        numericServiceId
      );
    } else {
      props.unselectMenuOption(groupId, serviceId, optionId, treatmentTypeId);
      trackServiceSelect([], [numericServiceId], [props.group]);
      trackCorrectBasketEvent(
        Action.RemoveFromBasket,
        props.trackingGroupType,
        numericServiceId
      );
    }
  }

  function handleOpenPopup(popupType: string, serviceId: string): void {
    if (popupType === SERVICE_POPUP_TYPE) {
      props.openServicePopup(serviceId);
      const numericServiceId = serviceId.substring(2);

      trackServiceDetailsEvent(
        getItemByServiceId(numericServiceId, [props.group]).item!
      );
    }
  }

  function handleToggleItem(id: string, expanded: boolean): void {
    if (props.isSuggestedGroup) {
      props.toggleVenueMenuHighlightedService(id, expanded);
    } else {
      props.toggleVenueMenuService(id, expanded);
    }
  }

  const someItemsWithDiscount = someItemsHaveDiscount();
  const maybeDiscountRow = (context: GeneralContext): React.ReactNode => {
    const discounts = getDiscounts(context);

    const message = someItemsWithDiscount
      ? context.i18n('venue.menu.some-discount-types', discounts || '')
      : context.i18n('venue.menu.active-discount-types', discounts || '');

    if (discounts && showDiscountRow) {
      return (
        <div
          key={`menu-group-discount-row-${group.id}`}
          className={styles['discount-summary']}
        >
          <VenueTag
            type={VenueTagType.YieldDiscount}
            size="large"
            label={message}
          />
        </div>
      );
    }

    return null;
  };

  const groupId = group.id;
  const isSingleItem = group.menuItems.length === 1;
  const menuItems = (): React.ReactNode =>
    group.menuItems.map(rawItem => {
      const itemGroupId = isSuggestedGroup ? rawItem.originalGroupId : groupId;
      const { item, options2D, itemGroup, itemOptionGroups } = prepareItemData(
        rawItem.data
      );

      if (item) {
        // single item
        const serviceId = rawItem.data.id;

        return (
          <MenuItem
            key={`menu-item-${item.id}-${serviceId}`}
            id={item.id}
            name={item.name}
            details
            duration={item.durationRange}
            price={item.priceRange}
            options2D={options2D}
            groupId={itemGroupId}
            serviceId={serviceId}
            venueMenu={venueMenu}
            treatmentTypeId={getTreatmentTypeId(rawItem)}
            channel={channel}
            isGroupSelected={props.isActive}
            onSelectItem={handleSelectItem}
            onOpenPopup={handleOpenPopup}
            isSuggestedGroup={false}
          />
        );
      }
      // grouped items
      const serviceId = itemGroup?.id;
      let isItemSelectedInGroup = false;

      if (
        venueMenu.selected[itemGroupId!] !== undefined &&
        venueMenu.selected[itemGroupId!][serviceId!] !== undefined
      ) {
        isItemSelectedInGroup = true;
      } else {
        isItemSelectedInGroup = false;
      }

      return (
        <MenuItem
          key={itemGroup?.id}
          isItemGroup
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          id={itemGroup?.id!}
          name={itemGroup?.name}
          details
          duration={itemGroup?.durationRange}
          price={itemGroup?.priceRange}
          initiallyExpanded={isSingleItem}
          groupId={itemGroupId}
          serviceId={serviceId}
          venueMenu={venueMenu}
          channel={channel}
          isGroupSelected={props.isActive}
          onToggleItem={handleToggleItem}
          onOpenPopup={handleOpenPopup}
          isSuggestedGroup={props.isSuggestedGroup}
          isItemSelectedInGroup={isMounted && isItemSelectedInGroup}
        >
          {itemOptionGroups?.map(
            ({ item: groupItem, options2D: groupItemOptions }) => (
              <MenuItem
                key={`${itemGroup?.id}-${groupItem?.id}`}
                id={groupItem?.id}
                name={groupItem?.name}
                details={false}
                duration={getGroupItemDuration(groupItem)}
                price={groupItem?.priceRange}
                options2D={groupItemOptions}
                multi={itemGroup?.multiOptionSelection}
                groupId={itemGroupId}
                serviceId={serviceId}
                venueMenu={venueMenu}
                treatmentTypeId={getTreatmentTypeId(rawItem)}
                channel={channel}
                isGroupSelected={props.isActive}
                onSelectItem={handleSelectItem}
                onOpenPopup={handleOpenPopup}
                isSuggestedGroup={false}
              />
            )
          )}
        </MenuItem>
      );
    });

  return (
    <>
      <Context.Consumer>{maybeDiscountRow}</Context.Consumer>
      {menuItems()}
    </>
  );
}

const mapStateToProps = (state: VenueState) => ({
  venueMenu: getVenueMenu(state),
});

const actions = {
  selectMenuOption,
  unselectMenuOption,
  toggleVenueMenuService,
  toggleVenueMenuHighlightedService,
  openServicePopup,
};

const connector = connect(mapStateToProps, actions);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const MenuGroupItems = connector(MenuGroupItemsComponent);
