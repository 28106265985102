import React from 'react';

interface Props {
  color: string;
}

export const IconFace: React.FunctionComponent<Props> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.5C5.30654 2.5 7.64234 3.9904 8.8285 6.87442L8.93633 7.14888L9.05212 7.45764L5.86263 18.6208C5.71091 19.1519 6.0184 19.7054 6.54944 19.8571C6.66433 19.8899 6.78385 19.9018 6.90234 19.8925L7.02027 19.8761L9.09538 19.4611L10.3143 19.9487C10.7115 20.1076 11.1642 19.9355 11.3556 19.5528C11.5076 19.2487 11.4094 18.885 11.139 18.6957L11.0528 18.6444L10.5528 18.3944L11.4472 16.6056L11.9472 16.8556C13.2696 17.5168 13.8056 19.1248 13.1444 20.4472C12.5153 21.7055 11.0624 22.3004 9.7428 21.8679L9.57151 21.8056L8.904 21.538L7.41251 21.8373C7.02195 21.9154 6.62055 21.9148 6.23165 21.8365L6 21.7801C4.46379 21.3412 3.55116 19.7816 3.89606 18.2423L3.93959 18.0714L6.944 7.555L6.87652 7.39849C6.01728 5.51023 4.52707 4.57086 2.2645 4.50386L2 4.5V2.5ZM18.9116 7.95501L20.5884 9.04499C19.5472 10.6469 18.1609 11.5 16.5 11.5C14.9182 11.5 13.5854 10.7262 12.5626 9.26872L12.4116 9.04499L14.0884 7.95501C14.7806 9.0198 15.5609 9.5 16.5 9.5C17.3804 9.5 18.1213 9.07795 18.7807 8.14783L18.9116 7.95501ZM16.5 2.5C18.9331 2.5 20.9934 3.57273 22.614 5.65404L22.8064 5.90864L21.1936 7.09136C19.9091 5.33974 18.3695 4.5 16.5 4.5C14.7154 4.5 13.2316 5.26514 11.9832 6.85815L11.8064 7.09136L10.1936 5.90864C11.8424 3.66026 13.9695 2.5 16.5 2.5Z"
      fill={props.color}
    />
  </svg>
);
