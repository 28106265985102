import React from 'react';
import { assertUnreachable } from 'js/types/assert-unreachable';
import { ColorBloomGreyNavy500 } from '@treatwell/design-tokens';
import { IconAll } from './IconAll';
import { IconBody } from './IconBody';
import { IconFace } from './IconFace';
import { IconHair } from './IconHair';
import { IconHairRemoval } from './IconHairRemoval';
import { IconMassage } from './IconMassage';
import { IconNails } from './IconNails';
import { IconYogaPilates } from './IconYogaPilates';
import { IconDance } from './IconDance';
import { IconFitness } from './IconFitness';
import { IconPhysicalTherapy } from './IconPhysicalTherapy';
import { IconCounsellingHolistic } from './IconCounsellingHolistic';
import { IconMedicalDental } from './IconMedicalDental';

export enum Icon {
  All,
  Body,
  Face,
  Hair,
  HairRemoval,
  Massage,
  Nails,
  YogaPilates,
  Dance,
  Fitness,
  PhysicalTherapy,
  CounsellingHolistic,
  MedicalDental,
}

interface Props {
  icon: Icon;
  color: string;
}

const defaultProps: Partial<Props> = {
  // eslint-disable-next-line react/default-props-match-prop-types
  color: ColorBloomGreyNavy500,
};

export function TreatmentTypeIcon(props: Props): React.ReactElement {
  switch (props.icon) {
    case Icon.All:
      return <IconAll {...props} />;
    case Icon.Body:
      return <IconBody {...props} />;
    case Icon.Face:
      return <IconFace {...props} />;
    case Icon.Hair:
      return <IconHair {...props} />;
    case Icon.HairRemoval:
      return <IconHairRemoval {...props} />;
    case Icon.Massage:
      return <IconMassage {...props} />;
    case Icon.Nails:
      return <IconNails {...props} />;
    case Icon.YogaPilates:
      return <IconYogaPilates {...props} />;
    case Icon.Dance:
      return <IconDance {...props} />;
    case Icon.Fitness:
      return <IconFitness {...props} />;
    case Icon.PhysicalTherapy:
      return <IconPhysicalTherapy {...props} />;
    case Icon.CounsellingHolistic:
      return <IconCounsellingHolistic {...props} />;
    case Icon.MedicalDental:
      return <IconMedicalDental {...props} />;
    default:
      return assertUnreachable(props.icon);
  }
}

TreatmentTypeIcon.defaultProps = defaultProps;
