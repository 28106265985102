import React from 'react';
import clsx from 'clsx';
import styles from './Radio.module.css';

interface Props {
  id: string;
  name: string;
  label?: string;
  value?: string;
  checked?: boolean;
  inlineLabel?: boolean;
  nrOptions?: number;
  noWrap?: boolean;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
}

export function Radio(props: Props): React.ReactElement {
  const {
    id,
    name,
    label,
    value,
    checked,
    inlineLabel,
    nrOptions,
    onChange,
    noWrap,
  } = props;

  return (
    <div
      key={value}
      className={clsx(styles.radio, {
        [styles['no-wrap']]: noWrap,
        [styles['input-group']]: true,
        [styles['inline-label']]: inlineLabel,
      })}
      style={{
        width: `calc(100% / ${nrOptions})`,
      }}
    >
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className={styles['input-label']}>
        <div className={styles['image-container']}>
          <span className={styles.image} />
        </div>
        <div>{label}</div>
      </label>
    </div>
  );
}
