import React from 'react';
import { Text } from '@treatwell/ui';

import { PageSection, SectionType } from 'js/components/PageSection';
import { StateData } from 'js/model/rainbow/StateData';
import { VenueFooter } from './VenueFooter';
import { VenueHeader } from './VenueHeader';
import styles from '../styles.module.css';

interface Props {
  pageData: StateData;
  i18n: (key: string, count?: number | string) => string;
  isWidget: boolean;
}

export function WidgetClosedVenue(props: Props): React.ReactElement {
  const pageData = props.pageData;
  const channel = pageData.channel;
  const { i18n } = props;
  const venue = pageData.venue.venue;
  const alternateLocaleUris =
    (pageData.venue && pageData.venue.alternateLocaleUris) || [];

  return (
    <div className={styles.wrapper} data-venue-id={venue.id}>
      <VenueHeader
        venue={venue}
        channelData={channel}
        isActiveVenue={false}
        alternateLocaleUris={alternateLocaleUris}
      />
      <PageSection id="venueClosed" type={SectionType.VeryShort}>
        <Text as="p" type="lgHeader" className={styles.venueClosed}>
          {i18n('venue.widget-venue-unavailable')}
        </Text>
      </PageSection>
      <VenueFooter cms={pageData.cms.footer} />
    </div>
  );
}
