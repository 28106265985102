import React from 'react';
import { PolicyItem } from 'js/model/rainbow/venue/VenueMenuItemDetailsOutput';
import { ServiceSection } from '../ServiceSection/ServiceSection';

interface Props {
  header: string;
  policyItems?: PolicyItem[];
}

export function ServicePolicy({
  header,
  policyItems,
}: Props): React.ReactElement | null {
  const allPolicyItems =
    policyItems &&
    policyItems.length &&
    policyItems.reduce((acc, item) => item.text, '');
  if (!allPolicyItems) {
    return null;
  }
  return (
    <ServiceSection header={header}>
      <div dangerouslySetInnerHTML={{ __html: allPolicyItems }} />
    </ServiceSection>
  );
}
