import React from 'react';
import { Inline, Text } from '@treatwell/ui';
import { TeamListItemContentSection } from '../TeamListItemContentSection/TeamListItemContentSection';
import styles from './TeamListItemSentimentSection.module.css';
import { trackSentimentClick } from '../tracking';

interface Props {
  heading: string;
  sentiments: {
    label: string;
    count: number;
    id: number;
  }[];
  stylistId: number;
}

export function TeamListItemSentimentSection({
  heading,
  sentiments,
  stylistId,
}: Props): React.ReactElement {
  return (
    <TeamListItemContentSection heading={heading}>
      <div className={styles.sentiments}>
        <Inline space="xxs" wrap>
          {sentiments.map((sentiment, index) => (
            <div
              className={styles.sentiment}
              key={sentiment.label}
              onClick={() =>
                trackSentimentClick(
                  index + 1,
                  sentiment.id,
                  sentiment.count,
                  stylistId
                )
              }
            >
              <Text type="caption">{sentiment.label}</Text>
              <Text type="captionHeavy" className={styles.count}>
                {sentiment.count}
              </Text>
            </div>
          ))}
        </Inline>
      </div>
    </TeamListItemContentSection>
  );
}
