import React from 'react';

import {IconBadge} from '../IconBadge/IconBadge';
import {IconTeam, IconUser} from '../Icon';
import {Badge, BadgeSize, ColorPair, ColorPairTeal} from '../../Internal/Badge';
import styles from './EmployeeBadge.module.css';
import {Text} from '../Text';

export type Props = {
  size: BadgeSize;

  /**
   * Named pairs of background and foreground colours.
   * For example `ColorPairTeal` (the default) will use `ColorTeal100` and `ColorTeal10`.
   */
  colorPair: ColorPair;

  /**
   * The employee `thumbnailUrl` value should be a URL of a
   * suitable size. It does not matter whether it's relative
   * or absolute, as long as it works when used in the context
   * of the rendered badge.
   *
   * If there is no thumbnail image for an employee, then the
   * first letter of the name (in uppercase) will be rendered.
   *
   * For any employee, or if the employee is not known, then
   * `unknown` will render a suitable icon.
   *
   * For more than a single employee `multiple`, will render a
   * suitable icon.
   */
  employee:
    | {
        name: string;
        thumbnailUrl?: string;
      }
    | 'unknown'
    | 'multiple';
};

/**
 * EmployeeBadge renders a badge for an employee.
 */
export const EmployeeBadge = ({
  colorPair = ColorPairTeal,
  size = 'lg',
  employee,
}: Props) => {
  if (typeof employee === 'object') {
    if (employee.thumbnailUrl !== undefined) {
      // Render employee image.
      return (
        <Badge colorPair={colorPair} size={size}>
          <img className={styles.thumbnail} src={employee.thumbnailUrl} />
        </Badge>
      );
    } else {
      // Render employee's first initial.
      const employeeInitial = employee.name.charAt(0).toUpperCase();
      const textType = size === 'sm' ? 'bodyHeavy' : 'mdHeader';

      return (
        <Badge colorPair={colorPair} size={size}>
          <Text as="div" type={textType}>
            {employeeInitial}
          </Text>
        </Badge>
      );
    }
  }

  const iconSize = size === 'sm' ? 16 : 24;
  const Icon = employee === 'multiple' ? IconTeam : IconUser;
  return (
    <IconBadge
      colorPair={colorPair}
      size={size}
      icon={<Icon size={iconSize} />}
    />
  );
};

EmployeeBadge.displayName = 'EmployeeBadge';
