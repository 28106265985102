import React from 'react';
import { ProductOutput } from 'js/model/rainbow/venue/ProductOutput';
import { AccoladeOutput } from 'js/model/rainbow/browse-page/AccoladeOutput';
import { Stack, useDevice, useViewport } from '@treatwell/ui';
import clsx from 'clsx';
import { VenueAccolades } from './VenueAccolades/VenueAccolades';
import { ProductsUsed } from './ProductsUsed/ProductsUsed';
import styles from './ProductsAccoladesSection.module.css';

const SectionContainer = ({
  className,
  children,
}: {
  className: string;
  children: React.ReactNode;
}) => {
  const { isMobile: isMobileDevice } = useDevice();
  const isMobile = useViewport({
    device: ['mobile', 'tablet'],
    serverRender: isMobileDevice,
  });

  return (
    <Stack className={className} space={isMobile ? 'lg' : 'md'}>
      {children}
    </Stack>
  );
};

export const ProductsAccoladesSection = ({
  products,
  accolades,
}: {
  products: ProductOutput[];
  accolades: AccoladeOutput[];
}) => {
  const fullWidth = products.length === 0 || accolades.length === 0;

  return (
    <div className={styles.root}>
      {products.length > 0 && (
        <SectionContainer
          className={clsx(styles.products, {
            [styles.fullWidth]: fullWidth,
          })}
        >
          <ProductsUsed products={products} fullWidth={fullWidth} />
        </SectionContainer>
      )}

      {accolades.length > 0 && (
        <SectionContainer
          className={clsx(styles.accolades, {
            [styles.fullWidth]: fullWidth,
          })}
        >
          <VenueAccolades accolades={accolades} />
        </SectionContainer>
      )}
    </div>
  );
};
