import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Inline, Stack, Text } from '@treatwell/ui';

import {
  TreatmentVenueMenuItemOutput,
  VenueMenuOptionOutput,
} from 'js/model/rainbow/venue/TreatmentVenueMenuItemOutput';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { ServicePriceRangeCms } from 'js/helpers/price/ServicePriceRangeCms';
import {
  selectMenuOption,
  unselectMenuOption,
} from 'js/redux-modules/venue-page/venue-menu';
import { getVenueMenu } from 'js/redux-modules/venue-page';

import clsx from 'clsx';
import styles from './Footer.module.css';
import { PriceDisplay } from '../PriceDisplay/PriceDisplay';

export interface ServicesFooterCms {
  serviceSingular: string;
  servicesPlural: string;
  selectSingular: string;
  select: string;
  selected: string;
}

interface VenueState {
  selected: {};
}

type Props = PropsFromRedux & {
  channel: ChannelOutput;
  cms: ServicePriceRangeCms & ServicesFooterCms;
  groupId: string;
  service: TreatmentVenueMenuItemOutput;
  close: () => void;
  expanded: boolean;
  onExpand: () => void;
  popupHeight: number;
  venueMenu: VenueState;
};

function FooterComponent({
  channel,
  cms,
  groupId,
  service,
  selectMenuOption,
  unselectMenuOption,
  close,
  expanded,
  onExpand,
  popupHeight,
  venueMenu,
}: Props): JSX.Element | null {
  const [maxHeight, setMaxHeight] = React.useState<number>(0);
  const selectedId = getSelectedOption();
  const options: VenueMenuOptionOutput[] = [];
  for (const group of service.optionGroups) {
    options.push(group.options[0]);
  }
  const hasOneOption = options.length <= 1;

  React.useEffect(() => {
    setMaxHeight(expanded || hasOneOption ? popupHeight / 2 : 0);
  }, [popupHeight, expanded]);

  function onClick(optionId: string): void {
    if (
      selectedId === optionId ||
      (venueMenu.selected[groupId] !== undefined &&
        venueMenu.selected[groupId][service.id] !== undefined)
    ) {
      unselectMenuOption(
        groupId,
        service.id,
        selectedId,
        service.primaryTreatmentCategoryGroupId
      );
    }
    if (selectedId !== optionId) {
      selectMenuOption(
        groupId,
        service.id,
        optionId,
        service.primaryTreatmentCategoryGroupId
      );
    }
    close();
  }

  function getSelectedOption(): string | undefined {
    if (venueMenu.selected === undefined) {
      return;
    }
    const selectedGroup = venueMenu.selected[groupId] || {};
    const selectedService = selectedGroup[service.id] || {};
    return Object.keys(selectedService).find(id => selectedService[id]);
  }

  const is2D = service.optionGroups.some(group => group.options.length > 1);
  if (service.multiOptionSelection || is2D) {
    return null;
  }

  const getButtonLabel = (isSelected: boolean): string => {
    if (isSelected) {
      return cms.selected;
    }
    if (hasOneOption) {
      return cms.selectSingular;
    }
    return cms.select;
  };

  const renderExpandedOptions = (): React.ReactNode => (
    <div
      className={clsx(styles.expandedContainer, {
        [styles.visible]: hasOneOption || expanded,
      })}
      style={{ maxHeight }}
    >
      {options.map(option => {
        const isSelected = selectedId === option.id;
        return (
          <div key={option.id} className={styles.option}>
            <Inline space="sm" splitAfter={0}>
              <Stack>
                {hasOneOption ? (
                  <Text>{cms.serviceSingular}</Text>
                ) : (
                  <Text>{option.name}</Text>
                )}

                <PriceDisplay
                  priceRangeOutput={option.priceRange}
                  channelOutput={channel}
                  servicePriceRangeCms={cms}
                  alignStart
                  inline
                />
              </Stack>
              <div
                className={clsx(styles.button, {
                  [styles.selected]: isSelected,
                })}
              >
                <Button size="lg" onClick={() => onClick(option.id)}>
                  {getButtonLabel(isSelected)}
                </Button>
              </div>
            </Inline>
          </div>
        );
      })}
    </div>
  );

  const renderCollapsedOptions = (): React.ReactNode => {
    const servicesTemplate = cms.servicesPlural;
    const servicesText = servicesTemplate.replace(
      '{0}',
      options.length.toString()
    );

    return (
      <div
        className={clsx(styles.collapsedContainer, {
          [styles.visible]: !expanded,
        })}
      >
        <Inline splitAfter={0} className={styles.collapsedContainerInner}>
          <Stack>
            <Text>{servicesText}</Text>
            <PriceDisplay
              priceRangeOutput={service.priceRange}
              channelOutput={channel}
              servicePriceRangeCms={cms}
              alignStart
              inline
            />
          </Stack>
          <div
            className={clsx(styles.button, {
              [styles.selected]: selectedId === service.id,
            })}
          >
            <Button size="lg" onClick={onExpand}>
              {cms.selectSingular}
            </Button>
          </div>
        </Inline>
      </div>
    );
  };

  return (
    <>
      {renderExpandedOptions()}
      {!hasOneOption && renderCollapsedOptions()}
    </>
  );
}

const mapStateToProps = (state: VenueState) => ({
  venueMenu: getVenueMenu(state),
});

const actions = {
  selectMenuOption,
  unselectMenuOption,
};

const connector = connect(mapStateToProps, actions);
type PropsFromRedux = ConnectedProps<typeof connector>;
export const Footer = connector(FooterComponent);
