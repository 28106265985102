import React from 'react';

export function PointyEnd(): React.ReactElement {
  return (
    <svg
      width="16px"
      height="32px"
      viewBox="0 0 16 32"
      preserveAspectRatio="none"
    >
      <path d="M0,0 L16,16 L0,32 Z" />
    </svg>
  );
}
