import React from 'react';
import { trackStructuredEvent } from 'js/helpers/google-tag-manager';
import { HorizontalOverflowHint } from 'js/components/HorizontalOverflowHint';
import { useIsMounted } from 'js/hooks/useIsMounted';
import styles from './TreatmentTypeBar.module.css';
import { TreatmentTypeButton } from './TreatmentTypeButton';

interface Props {
  treatmentTypes: { id: number; name: string }[];
  selectedTreatmentTypes: number[];
  activeTreatmentTypeId: number;
  treatmentTypeSelected: (treatmentTypeId: number) => void;
  isChurned?: boolean;
}

export function TreatmentTypeBar(props: Props): React.ReactElement {
  const isMounted = useIsMounted();

  function trackTreatmentTypeSelectedEvent(treatmentTypeId: number): void {
    return trackStructuredEvent({
      category: 'venue_treatmenttype_filter',
      action: 'select_treatmenttype',
      value: treatmentTypeId,
      property: undefined,
      label: undefined,
    });
  }

  function treatmentTypeSelected(treatmentTypeId: number | undefined): void {
    const { activeTreatmentTypeId, treatmentTypeSelected = () => {} } = props;
    if (treatmentTypeId !== activeTreatmentTypeId) {
      trackTreatmentTypeSelectedEvent(treatmentTypeId!);
      treatmentTypeSelected(treatmentTypeId!);
    }
  }

  const isSelected = (treatmentType: { id: number }): boolean => {
    if (!props.selectedTreatmentTypes) {
      return false;
    }

    return props.selectedTreatmentTypes.includes(treatmentType.id);
  };

  const { treatmentTypes, activeTreatmentTypeId, isChurned = false } = props;
  const targetChildIndex = !activeTreatmentTypeId
    ? 0
    : treatmentTypes.findIndex(type => type.id === activeTreatmentTypeId);

  return (
    <HorizontalOverflowHint
      containerClassName={styles.barContainer}
      targetChildIndex={targetChildIndex}
      initialSpacing={14}
    >
      {treatmentTypes.map(type => (
        <TreatmentTypeButton
          key={type.id}
          id={type.id}
          name={type.name}
          selected={!isChurned && isMounted && isSelected(type)}
          active={activeTreatmentTypeId === type.id}
          onClick={() => treatmentTypeSelected(type.id)}
        />
      ))}
    </HorizontalOverflowHint>
  );
}
