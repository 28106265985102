import { IconChevronLeft, VisuallyHidden } from '@treatwell/ui';
import React from 'react';
import styles from './NavigateBackLinkAsButton.module.css';

export const NavigateBackLinkAsButton = ({
  navigateLink,
  navigateText,
  onNavigateLinkClick,
}: {
  navigateLink: string;
  navigateText: string;
  onNavigateLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  return (
    <a
      href={navigateLink}
      className={styles.navigateLink}
      data-cy="NavigateBackLinkAsButton"
      onClick={onNavigateLinkClick}
    >
      <IconChevronLeft size={24} />
      <VisuallyHidden>{navigateText}</VisuallyHidden>
    </a>
  );
};
