import React from 'react';

interface Props {
  color: string;
}

export const IconYogaPilates: React.FunctionComponent<Props> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5714 15.0037L15.4286 16.8487C15.4286 16.8487 19.2857 17.6678 19.2857 19.6163C19.2857 21.0001 17.5714 21.0001 17.5714 21.0001H13L10.75 19.7501"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.42858 15.0035L8.57144 16.8486C8.57144 16.8486 4.71429 17.6677 4.71429 19.6162C4.71429 20.9999 6.42858 20.9999 6.42858 20.9999H8.50001L10.75 19.7499L13.5 17.9999"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 15.9261C3 15.9261 5.14286 15.4649 6.42857 15.0036C7.71429 8.54595 11.5714 9.00721 12 9.00721C12.4286 9.00721 16.2857 8.54595 17.5714 15.0036C18.8571 15.4649 21 15.9261 21 15.9261"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
