import { StateData } from 'js/model/rainbow/StateData';
import React from 'react';
import { useTrackPageAndSessionView } from 'js/hooks/useTrackPageAndSessionView';
import { WidgetActiveVenue } from './ActiveVenue';
import { WidgetClosedVenue } from './ClosedVenue';

const CLOSED_STATUSES = [
  'archived',
  'pending',
  'closed',
  'disabled',
  'temporarily_disabled',
];

interface Props {
  pageData: StateData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateUri: (pageType: string, ...args: unknown[]) => any;
  i18n: (
    key: string,
    count?: string | number | undefined,
    ...args: string[]
  ) => string;
  isWidget: boolean;
}

export function WidgetVenuePage({
  i18n,
  isWidget,
  pageData,
  generateUri,
}: Props): React.ReactElement {
  const { status, menu, widgetSettings } = pageData.venue.venue;
  useTrackPageAndSessionView();

  if (
    CLOSED_STATUSES.includes(status) ||
    menu.menuGroups.length === 0 ||
    (widgetSettings && !widgetSettings.widgetEnabled)
  ) {
    return (
      <WidgetClosedVenue pageData={pageData} i18n={i18n} isWidget={isWidget} />
    );
  }
  return <WidgetActiveVenue generateUri={generateUri} pageData={pageData} />;
}
