/* eslint-disable react/no-unescaped-entities */
/*
  This module provides a (somewhat hacky) means of testing a venue widget
  when it's hosted in an iframe.

  Venues often embed the widget in a frame, and this brings some challenges.
  So it's good to be able to test this scenario.
  In particular it's important to be able to test the point in the flow
  when we move from the iframe to a full page.

  To load a venue's widget in an iframe, open a modified venue page URL.
    * The URL should be for a _marketplace_ venue page. For example
        https://www.treatwell.co.uk.localhost.twbox.io/place/beauty-ace/
    * Add a query parameter of "?iframe", giving a URL like this.
        https://www.treatwell.co.uk.localhost.twbox.io/place/beauty-ace/?iframe

        The query parameter will result in the client side rendering abandoning
  the regular venue content, and instead render an iframe, with the venue
  page's _widget_ URL as the source. For example
    <iframe src="https://widget.treatwell.co.uk.localhost.twbox.io/place/beauty-ace/"/>
*/

import React from 'react';
import { isBrowser, isProductionEnvironment } from 'js/helpers/environment';

export function embedWidgetInIframe(): boolean {
  return (
    isBrowser &&
    window.location.search === '?iframe' &&
    !isProductionEnvironment
  );
}

export function WidgetInIframe(): React.ReactElement {
  let hostNamePrefix = 'widget';
  if (window.location.host.startsWith('www.treatwell.lt')) {
    hostNamePrefix = 'book';
  } else if (
    window.location.host.startsWith('www.treatwell.at') ||
    window.location.host.startsWith('www.treatwell.ch') ||
    window.location.host.startsWith('www.treatwell.de')
  ) {
    hostNamePrefix = 'buchung';
  }

  return (
    <div>
      <div>
        The iframe below hosts the venue page in an iframe. The iframe's src is
        the venue's widget url.
      </div>

      <iframe
        width="1000"
        height="600"
        src={window.location.href
          .replace('?iframe', '')
          .replace('www', hostNamePrefix)}
      />
    </div>
  );
}
