import { isBrowser, isProductionEnvironment } from './environment';

export interface VenueLocation {
  address: {
    addressLines: string[];
    postalCode: string;
  };
  point: {
    lat: number;
    lon: number;
  };
  map: {
    zoom: number;
    lat: number;
    lon: number;
  };
}

export function getStaticMapUrl(venueLocation: VenueLocation): string {
  const apiKey = isProductionEnvironment ? 'treatwell' : 'demo';
  const zoom = venueLocation.map.zoom;

  const baseUrl = `https://maps.a-cdn.net/static/${apiKey}/`;
  const centerCoordinates = `${venueLocation.map.lat},${venueLocation.map.lon}`;
  const markerCoordinates = `${venueLocation.point.lat},${venueLocation.point.lon}`;
  const size = '650x288';
  const markerUrl =
    'https://cdn1.treatwell.net/images/view/v2.i1105929.w28.h24.xCCE9E5B8.png';
  let staticMapUrl = `${baseUrl}?center=${centerCoordinates}&zoom=${zoom}&size=${size}&markers=icon:${markerUrl}|${markerCoordinates}`;
  if (isBrowser && window.devicePixelRatio > 1) {
    staticMapUrl += `&scale=2`;
  }
  return staticMapUrl;
}
