import React from 'react';
import {
  Inline,
  MediaObject,
  Stack,
  useViewport,
  IconPin,
  IconTime,
  Viewport,
  useDevice,
} from '@treatwell/ui';

import { Address } from 'js/model/rainbow/Address';
import { CmsCommon } from 'js/model/cms/cms-common';
import { CmsVenue } from 'js/model/cms/cms-venue';
import { createVenueTags } from 'js/model/view/venue-tags';
import { CmsVenuePage } from 'js/model/cms/cms-venue-page';
import { SalesPriceDiscountType } from 'js/model/rainbow/SalesPriceDiscountType';
import { VenueTypeOutput } from 'js/model/rainbow/venue/VenueTypeOutput';

import { VenueOpeningHoursOutput } from 'js/model/rainbow/venue/VenueOpeningHoursOutput';
import { VenueTag, VenueTagType } from 'js/components/VenueTag';
import { IconWithContent } from 'js/components/IconWithContent';
import { getVenueRelativeOpeningHours } from 'js/helpers/venue-relative-opening-time';

import { VenueChain } from 'js/model/rainbow/venue/VenueOutput';
import { FormattedAddress } from './Address';
import styles from './style.module.css';

interface Props {
  address: Address;
  chain?: VenueChain;
  discountTypes: SalesPriceDiscountType[];
  venueType?: VenueTypeOutput;
  venueSubtypes?: VenueTypeOutput[];
  cmsCommon: CmsCommon;
  cmsVenue: CmsVenue;
  cmsChainTemplate: CmsVenuePage['page']['venue']['details']['chain'];
  openingHours: VenueOpeningHoursOutput[];
  countryCode: string;
  isActiveVenue?: boolean;
}

export function Details2(props: Props): React.ReactElement {
  const { isMobile: isMobileDevice } = useDevice();
  const isMobile = useViewport({
    device: 'mobile',
    serverRender: isMobileDevice,
  });

  const venueTagsData = createVenueTags(
    {
      jit: props.discountTypes.includes(SalesPriceDiscountType.Jit),
      offpeak: props.discountTypes.includes(SalesPriceDiscountType.Offpeak),
    },
    props.venueType,
    props.venueSubtypes,
    props.cmsVenue
  );

  const venueTypesTagData = venueTagsData.filter(
    tag =>
      tag.type === VenueTagType.HomeBased ||
      tag.type === VenueTagType.MobileVenue
  );

  const discountsTagData = venueTagsData.find(
    tag => tag.type === VenueTagType.YieldDiscount
  );

  function venueTypes(): React.ReactElement | null {
    if (!props.isActiveVenue) {
      return null;
    }

    if (venueTypesTagData.length === 0) {
      return null;
    }

    const venueTags = venueTypesTagData.map(tagData => (
      <VenueTag key={tagData.label} size="small" {...tagData} />
    ));

    return (
      <>
        <Viewport serverRender={isMobileDevice} device="mobile">
          <Stack space="xxs">{venueTags}</Stack>
        </Viewport>

        <Viewport serverRender={!isMobileDevice} device={['tablet', 'desktop']}>
          <Inline space="lg">{venueTags}</Inline>
        </Viewport>
      </>
    );
  }

  function discounts(): React.ReactElement | null {
    if (!props.isActiveVenue) {
      return null;
    }

    if (!discountsTagData) {
      return null;
    }

    return <VenueTag {...discountsTagData} />;
  }

  const openingHours = getVenueRelativeOpeningHours(
    props.openingHours,
    props.countryCode,
    props.cmsCommon
  );

  return (
    <Stack space={isMobile ? 'xs' : 'md'}>
      <MediaObject align="center" space={isMobile ? 'xs' : 'md'}>
        <IconPin size={isMobile ? 16 : 24} />
        <Stack space="xxs" className={styles.location}>
          <FormattedAddress
            address={props.address}
            chain={props.chain}
            cmsChainTemplate={props.cmsChainTemplate}
          />
          {venueTypes()}
        </Stack>
      </MediaObject>

      {props.isActiveVenue && (
        <IconWithContent icon={IconTime} text={openingHours} />
      )}

      {discounts()}
    </Stack>
  );
}
