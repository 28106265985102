import React from 'react';
import { IconCheckFilled, Stack, Text } from '@treatwell/ui';
import clsx from 'clsx';
import {
  TreatmentTypeIcon,
  mapTreatmentTypeIdToIcon,
} from 'js/components/TreatmentTypeIcon';
import {
  ColorBloomBaseCoral600,
  ColorBloomBaseNavy800,
} from '@treatwell/design-tokens';
import styles from './TreatmentTypeButton.module.css';

const ACTIVE_FILL = ColorBloomBaseNavy800;

interface Props {
  id: number;
  name: string;
  active: boolean;
  selected: boolean;
  onClick: () => void;
}

export function TreatmentTypeButton(props: Props): React.ReactElement {
  const { id, name, active, selected, onClick = () => {} } = props;
  const treatmentTypeIcon = mapTreatmentTypeIdToIcon(id);
  const selectedIcon = selected ? (
    <div className={styles.selectedIcon} data-cy="selectedIcon">
      <IconCheckFilled size={24} fill={ColorBloomBaseCoral600} />
    </div>
  ) : null;

  const classes = clsx({
    [styles.panel]: true,
    [styles.active]: active,
    [styles.inactive]: !active,
    [styles.selected]: selected,
  });

  return (
    <Stack
      align="center"
      onClick={onClick}
      className={classes}
      data-treatment-type-id={id}
    >
      {selectedIcon}
      <TreatmentTypeIcon
        icon={treatmentTypeIcon}
        color={active ? ACTIVE_FILL : undefined}
      />
      <Text type="footnote" className={styles.name}>
        {name}
      </Text>
    </Stack>
  );
}
