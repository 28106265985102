/*
 * The number of info block columns that will fit in the window's current width;
 */
const getNumberOfColumns = () => {
  const width = window.innerWidth;

  if (width >= 992) {
    return 3;
  }
  if (width >= 520) {
    return 2;
  }

  return 1;
};

export default getNumberOfColumns;
