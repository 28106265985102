import { VenueTypeOutput } from 'js/model/rainbow/venue/VenueTypeOutput';
import React from 'react';
import styles from './BrowseMoreVenues.module.css';
import { Context } from './LocaleWrapper';

interface Props {
  nearbyVenueSubTypes: VenueTypeOutput[];
  onLinkClick: (venueSubTypeId: number) => void;
}
export function BrowseMoreVenues(props: Props): React.ReactElement {
  const { nearbyVenueSubTypes, onLinkClick } = props;
  const { i18n } = React.useContext(Context);

  return (
    <>
      <div className={styles.title}>
        {i18n('venue.venue-closed-search.browse-venues')}
      </div>
      <div className={styles.nearbyLinks}>
        {nearbyVenueSubTypes.map((nearby, index) => {
          if (!nearby.infoUri) {
            return null;
          }

          return (
            <a
              key={index}
              href={nearby.infoUri.desktopUri}
              className={styles.nearbyLink}
              onClick={() => {
                onLinkClick(nearby.id);
              }}
            >
              {i18n('venue.venue-closed-search.nearby', nearby.pluralName)}
            </a>
          );
        })}
      </div>
    </>
  );
}
