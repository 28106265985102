import React from 'react';
import { Text } from '@treatwell/ui';
import styles from './ServiceSection.module.css';

interface Props {
  children: React.ReactNode;
  header: string;
}

export function ServiceSection({
  children,
  header,
}: Props): React.ReactElement {
  return (
    <div className={styles.section}>
      <Text as="div" type="mdHeader">
        {header}
      </Text>
      <Text as="div" type="body" className={styles.content}>
        {children}
      </Text>
    </div>
  );
}
