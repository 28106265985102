import React from 'react';
import { Text } from '@treatwell/ui';
import styles from './VenueReviewResponse.module.css';

interface Props {
  content: string;
  visibilityToggleLabels: {
    isVisible: string;
    isHidden: string;
  };
}

export function VenueReviewResponse(props: Props): React.ReactElement {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const toggleVisibility = (): void => {
    setIsVisible(isVisible => !isVisible);
  };

  const { content, visibilityToggleLabels } = props;

  return (
    <div className={styles.response} onClick={toggleVisibility}>
      <Text as="div" type="body" className={styles.title}>
        {isVisible
          ? visibilityToggleLabels.isVisible
          : visibilityToggleLabels.isHidden}
      </Text>

      {isVisible && (
        <Text as="div" type="body" className={styles.reply}>
          {content}
        </Text>
      )}
    </div>
  );
}
