/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M8.5 0C12.09 0 15 2.865 15 6.4S10.125 16 8.5 16 2 9.935 2 6.4C2 2.865 4.91 0 8.5 0zm0 4.8c-.897 0-1.625.716-1.625 1.6 0 .884.728 1.6 1.625 1.6s1.625-.716 1.625-1.6c0-.884-.728-1.6-1.625-1.6z',
  ],

  24: [
    'M12 2c4.418 0 8 3.582 8 8s-6 12-8 12-8-7.582-8-12 3.582-8 8-8zm0 6a2 2 0 10-.001 3.999A2 2 0 0012 8z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconPin = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconPin.displayName = 'IconPin';
