import React from 'react';
import { Inline } from '@treatwell/ui';
import clsx from 'clsx';
import styles from './Button.module.css';

interface Props {
  label: string;
  primary?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function Button(props: Props): React.ReactElement {
  const { label, primary = false, onClick } = props;

  const classes = clsx(styles.button, {
    [styles.primaryButton]: primary,
    [styles.secondaryButton]: !primary,
  });

  return (
    <Inline className={classes} justify="center" onClick={onClick}>
      <Inline>{label}</Inline>
    </Inline>
  );
}
