import React from 'react';
import clsx from 'clsx';
import { VisuallyHidden } from '@treatwell/ui';
import { isModifiedClick } from 'js/helpers/modified-click';
import styles from './Pagination.module.css';

interface Props {
  page: number; // 0-based
  href: string;
  changePage?: (page: number) => void;
  active?: boolean;
  direction?: 'prev' | 'next';
}

export class PageLink extends React.Component<Props> {
  private onClick = (event: React.MouseEvent) => {
    if (!this.props.changePage || isModifiedClick(event)) {
      return;
    }

    event.preventDefault();
    this.props.changePage(this.props.page);
  };

  public render(): React.ReactNode {
    const { page, active, href, direction } = this.props;
    const pageDisplay = page + 1;

    if (direction) {
      return (
        <a
          className={clsx(styles.item, styles[direction])}
          href={href}
          onClick={this.onClick}
        >
          <VisuallyHidden>{pageDisplay}</VisuallyHidden>
        </a>
      );
    }

    if (active) {
      return (
        <div className={clsx(styles.item, styles.active)}>{pageDisplay}</div>
      );
    }

    return (
      <a className={styles.item} href={href} onClick={this.onClick}>
        {pageDisplay}
      </a>
    );
  }
}
