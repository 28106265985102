import React from 'react';
import clsx from 'clsx';
import {
  Tooltip,
  TooltipPosition,
  TooltipPointerPosition,
} from 'js/components/generic/Tooltip';
import styles from './BestTreatmentsBadge.module.css';

export enum BestTreatmentsBadgeStyle {
  Outline = 'outline',
  Solid = 'solid',
}

interface Props {
  label: string;
  style: BestTreatmentsBadgeStyle;
  tooltip?: string;
}

interface State {
  isTooltipVisible: boolean;
}

export class BestTreatmentsBadge extends React.PureComponent<Props, State> {
  public state = { isTooltipVisible: false };

  private toggleTooltip = (isVisible: boolean): void =>
    this.setState({ isTooltipVisible: isVisible });

  public render(): React.ReactNode {
    const [labelFirstWord] = this.props.label.split(' ', 1);
    const labelRest = this.props.label.substr(labelFirstWord.length);

    return (
      <div
        className={clsx(styles.container, styles[this.props.style])}
        onClick={() => this.toggleTooltip(false)}
        onMouseEnter={() => this.toggleTooltip(true)}
        onMouseLeave={() => this.toggleTooltip(false)}
      >
        <div className={styles.rosetteIcon} />
        <div className={styles.label}>
          <em>{labelFirstWord}</em>
          {labelRest}
        </div>

        {this.props.tooltip && this.state.isTooltipVisible && (
          <Tooltip
            extraStyles={[styles.tooltip]}
            position={TooltipPosition.Top}
            pointer={TooltipPointerPosition.Middle}
          >
            {this.props.tooltip}
          </Tooltip>
        )}
      </div>
    );
  }
}
