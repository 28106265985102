import React from 'react';

interface Props {
  color: string;
}

export const IconPhysicalTherapy: React.FunctionComponent<Props> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 17.5591L14.8728 17.5L18.8728 11"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 17C7 15.8954 6.10457 15 5 15C3.89543 15 3 15.8954 3 17C3 18.1046 3.89543 19 5 19C6.10457 19 7 18.1046 7 17Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.873 17.5591H21.873"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M9.81738 15.7619L11.226 10.1272"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 14.3532C7 10.4089 9.81743 10.1272 11.2261 10.1272L12.6346 10.1272C12.8694 11.3011 13.9024 13.7897 16.1562 14.3532"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7852 8C12.8897 8 13.7852 7.10457 13.7852 6C13.7852 4.89543 12.8897 4 11.7852 4C10.6806 4 9.78516 4.89543 9.78516 6C9.78516 7.10457 10.6806 8 11.7852 8Z"
      stroke={props.color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
