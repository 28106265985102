import React from 'react';

import { CmsVenuePage } from 'js/model/cms/cms-venue-page';
import { Button } from 'js/components/Button/Button';
import { scrollWindowTo } from 'js/helpers/animations';
import { ButtonColour, ButtonSize } from 'js/components/Button';
import styles from './Actions.module.css';
import { trackVenueAction } from './tracking';

interface Props {
  cms: CmsVenuePage['page']['venue']['header-navigation'];
  hasMenu: boolean;
  hasReviews: boolean;
}

export function Actions(props: Props): React.ReactElement {
  const { cms, hasMenu, hasReviews } = props;

  function handleNavigation(sections: string[], actionName?: string): void {
    const sectionNode = sections
      .map(section => document.getElementById(section))
      .find(node => node !== null);

    if (!sectionNode) {
      return;
    }

    scrollWindowTo(sectionNode.getBoundingClientRect().top);

    const action = actionName || sectionNode.id;
    trackVenueAction(action);
  }

  const aboutButton = (
    <Button
      size={ButtonSize.Medium}
      colour={ButtonColour.NoOutlineBlue}
      label={cms.about}
      onClick={() => handleNavigation(['amenities', 'about'])}
    />
  );
  const reviewsButton = !hasReviews ? null : (
    <Button
      size={ButtonSize.Medium}
      colour={ButtonColour.NoOutlineBlue}
      label={cms.reviews}
      onClick={() => handleNavigation(['reviews'])}
    />
  );
  const servicesButton = !hasMenu ? null : (
    <Button
      colour={ButtonColour.Blue}
      size={ButtonSize.Medium}
      label={cms['book-now']}
      onClick={() => handleNavigation(['menu'], 'services')}
    />
  );

  return (
    <div className={styles.actions}>
      <div className={styles.navigate}>
        {servicesButton}
        {reviewsButton}
        {aboutButton}
      </div>
    </div>
  );
}
