import React from 'react';
import { PageSection } from 'js/components/PageSection';
import gridStyles from 'assets/style/grid.module.css';
import { ProximityVenue } from 'js/model/rainbow/venue/ProximityVenue';
import { VenueTypeOutput } from 'js/model/rainbow/venue/VenueTypeOutput';
import { Context } from 'js/components/LocaleWrapper';
import { ProximityVenues } from './ProximityVenues/ProximityVenues';

export function ProximityVenuesSection(props: {}): React.ReactElement | null {
  const { i18n, pageData } = React.useContext(Context);

  const venuePageData = pageData.venue;
  const venue = venuePageData.venue;
  const channel = pageData.channel;

  const showVenuesNearby =
    venuePageData.hasProximityVenues &&
    Object.keys(venuePageData.proximityVenue!).length;

  if (!showVenuesNearby) {
    return null;
  }

  const proximityVenue = venuePageData.proximityVenue as ProximityVenue & {
    venueType: VenueTypeOutput;
    venueSubTypes: VenueTypeOutput[];
  };

  return (
    <PageSection
      id="proximityVenues"
      title={i18n(
        'page.venue.section-titles.venues-nearby',
        venue.type?.pluralName
      )}
    >
      <div className={gridStyles.row}>
        <ProximityVenues
          data-testid="proximity-venues"
          location={venue.location.tree.name}
          nearbyVenues={proximityVenue.venues}
          nearbyVenueTypes={proximityVenue.venueType}
          nearbyVenueSubTypes={proximityVenue.venueSubTypes}
          channelData={channel}
          {...props}
        />
      </div>
    </PageSection>
  );
}
