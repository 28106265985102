import React from 'react';
import clsx from 'clsx';
import throttle from 'lodash.throttle';
import { IntersectionObserver } from 'js/components/IntersectionObserver';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import { Inline, Stack, Viewport, Text } from '@treatwell/ui';
import { RequestData } from 'js/helpers/service';
import { CmsOurWork } from 'js/model/cms/cms-venue-page';
import { Chevron, Direction, Size } from 'js/components/Icons/Chevron/Chevron';
import {
  getFragmentValue,
  objToQuery,
  queryToObj,
  updateUriWithOpenPopup,
} from 'js/helpers/uri-util';
import {
  getDesktopGalleryPagesWidth,
  getMobilePageNumber,
  getMobilePageCount,
  MOBILE_IMAGE_WIDTH,
} from './venue-portfolio-gallery-helpers';
import { VenuePortfolioCarouselImage } from './VenuePortfolioCarouselImage';
import { Dots } from '../page-navigation-dots/Dots';
import { PortfolioImagePopup } from '../PortfolioImagePopup/PortfolioImagePopup';
import styles from './VenuePortfolioCarouselGallery.module.css';

const MOBILE_BREAKPOINT = parseInt(styles['tablet-layout-width'], 10);
const MAX_NAV_DOTS = 5;

interface Props {
  images: ImageOutput[];
  pageData: RequestData;
  cms: CmsOurWork;
}

export function VenuePortfolioCarouselGallery({
  images,
  pageData,
  cms,
}: Props): React.ReactElement {
  const [mounted, setMounted] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState<number>(0);
  const [translateDesktopPx, setTranslateDesktopPx] = React.useState<number>(0);
  const [marginLeftContainer, setMarginLeftContainer] = React.useState<number>(
    0
  );
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [selectedImage, setSelectedImage] = React.useState<ImageOutput>();

  const [
    desktopGalleryPagesWidth,
    setDesktopGalleryPagesWidth,
  ] = React.useState<number[]>([]);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const imagesContainerRef = React.useRef<HTMLDivElement>(null);
  const imagesPaddingRef = React.useRef<HTMLDivElement>(null);

  function onResize(): void {
    setWindowWidth(window.innerWidth);
    setTranslateDesktopPx(0);
    setCurrentPage(0);

    // Scroll mobile view to page 0.
    if (imagesContainerRef.current) {
      imagesContainerRef.current.scrollLeft = 0;
    }

    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setMobileImagesContainerPadding();
      updateMobileNavigationDots();
    }
  }

  React.useEffect(() => {
    const serviceDetails = getFragmentValue('serviceDetails');
    if (!serviceDetails) {
      openPopupFromUri();
    }
    setMounted(true);
    const onResizeThrottled = throttle(onResize, 100);

    window.addEventListener('resize', onResizeThrottled);
    onResize();

    return () => {
      window.removeEventListener('resize', onResizeThrottled);
    };
  }, []);

  React.useEffect(() => {
    const updateDots = throttle(updateMobileNavigationDots, 50);

    imagesContainerRef.current?.addEventListener('scroll', updateDots);
    updateDots();
    window.addEventListener('popstate', onPopState);

    return () => {
      imagesContainerRef.current?.removeEventListener('scroll', updateDots);
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  React.useEffect(() => {
    const leftCurrent = (containerRef.current
      ?.previousSibling as HTMLDivElement).offsetLeft;

    setMarginLeftContainer(leftCurrent);
    setDesktopGalleryPagesWidth(
      getDesktopGalleryPagesWidth(images, windowWidth, leftCurrent)
    );
  }, [windowWidth]);

  const openPopupFromUri = () => {
    const imageId = getFragmentValue('portfolioImage');
    const image = images.find(image => image.id.toString() === imageId);

    if (!imageId || !image) {
      return;
    }

    setSelectedImage(image);
  };

  function onPopState(): void {
    if (
      getFragmentValue('portfolioImage') !== undefined &&
      selectedImage === undefined &&
      getFragmentValue('imageIds') === undefined
    ) {
      openPopupFromUri();
    } else if (getFragmentValue('portfolioImage') === undefined) {
      setSelectedImage(undefined);
    }
  }

  /*
    The last cell of the imagesContainer grid is a dummy cell.
    It is there so that it can provide empty space at the end of
    the grid, to allow the last column with images to be scrolled
    far enough left so that it's at the left edge of the container.

    The width of this last cell (assigned to the paddingElement
    constant) is set to a value that facilitates this.
   */
  function setMobileImagesContainerPadding(): void {
    const paddingElement = imagesPaddingRef.current;
    const imagesContainerElement = imagesContainerRef.current;

    if (!paddingElement || !imagesContainerElement) {
      return;
    }

    // A single image is a special case. No padding is required,
    // as no scrolling is desired.
    if (images.length === 1) {
      paddingElement.style.width = '0';
      return;
    }

    /*
      In layouts below
        "L" represents the large first image
        "s" represents a small image
        "." represents the absence of a small image (in the second row of the grid)

      If number of images is even, the last column will contain one image.
        L s     OR     L s s s
          .              s s .

      If number of images is odd, the last column will contain two images.
        L s     OR     L s s s
          s              s s s
     */

    const isLastColumnFull = images.length % 2 === 1;
    const padding = isLastColumnFull
      ? imagesContainerElement.clientWidth - MOBILE_IMAGE_WIDTH
      : imagesContainerElement.clientWidth;

    paddingElement.style.width = `${padding}px`;
  }

  function updateMobileNavigationDots(): void {
    if (windowWidth > MOBILE_BREAKPOINT) {
      return;
    }

    const containerElement = imagesContainerRef.current;
    if (!containerElement) {
      return;
    }

    const { scrollLeft } = containerElement;
    const pageNumber = getMobilePageNumber(scrollLeft);

    setCurrentPage(pageNumber);
  }

  function clickRight(): void {
    if (currentPage === desktopGalleryPagesWidth.length) {
      return;
    }

    const currentTranslatePx = desktopGalleryPagesWidth[currentPage];
    setCurrentPage(currentPage + 1);
    setTranslateDesktopPx(translateDesktopPx - currentTranslatePx);
  }

  function clickLeft(): void {
    if (currentPage === 0) {
      return;
    }

    const currentTranslatePx = desktopGalleryPagesWidth[currentPage - 1];
    setCurrentPage(currentPage - 1);
    setTranslateDesktopPx(translateDesktopPx + currentTranslatePx);
  }

  function renderChevron(direction: Direction): React.ReactNode {
    const isFirstPage = currentPage === 0;
    const isLastPage = currentPage === desktopGalleryPagesWidth.length;
    const disabled = direction === Direction.Left ? isFirstPage : isLastPage;
    const chevronClassnames = clsx([styles.chevron], {
      [styles.disabled]: disabled,
    });
    const colour = disabled ? 'bloomGreyNavy700' : 'bloomBaseNavy800';

    return (
      <div
        onClick={direction === Direction.Left ? clickLeft : clickRight}
        className={chevronClassnames}
      >
        <Chevron
          direction={direction}
          size={Size.Medium}
          disabled={disabled}
          colour={colour}
        />
      </div>
    );
  }

  function renderNavigation(): React.ReactNode {
    if (
      windowWidth > MOBILE_BREAKPOINT &&
      desktopGalleryPagesWidth.length < 1
    ) {
      return;
    }
    if (windowWidth < MOBILE_BREAKPOINT && images.length === 1) {
      return;
    }

    return (
      <>
        <Viewport serverRender device="mobile">
          <Inline justify="center">
            <Dots
              maxDotCount={MAX_NAV_DOTS}
              pageCount={getMobilePageCount(images.length)}
              page={currentPage}
            />
          </Inline>
        </Viewport>

        <Viewport device={['desktop', 'tablet']}>
          <Inline justify="between" className={styles.chevronsContainer}>
            {renderChevron(Direction.Left)}
            <Dots
              maxDotCount={MAX_NAV_DOTS}
              pageCount={desktopGalleryPagesWidth.length + 1}
              page={currentPage}
            />
            {renderChevron(Direction.Right)}
          </Inline>
        </Viewport>
      </>
    );
  }

  function track(): void {}

  function onPopupOpen(image: ImageOutput): void {
    updateUriWithOpenPopup({
      portfolioImage: image.id,
    });
    setSelectedImage(image);
  }

  function onPopupClose(): void {
    const { pathname, hash, search } = window.location;
    const newParams = { ...queryToObj(hash) };
    delete newParams.portfolioImage;

    const url =
      Object.keys(newParams).length > 0
        ? pathname + search + objToQuery(newParams, '#')
        : pathname + search;
    window.history.pushState(newParams, '', url);

    setSelectedImage(undefined);
  }

  // Only make visible once mounted.
  // This avoids a flicker on desktop when the gallery is re-rendered
  // once it's known that the window is of desktop width.
  const containerStyle: React.CSSProperties = {
    visibility: mounted ? 'visible' : 'hidden',
  };

  const style = { marginLeft: marginLeftContainer };

  return (
    <IntersectionObserver rootMargin="0px" onChange={track}>
      <div
        ref={containerRef}
        className={styles.container}
        style={containerStyle}
      >
        <Stack space="lg">
          <Stack space="xxs" style={style}>
            <Text type="mdHeader">{cms.heading}</Text>
            <Viewport serverRender device="mobile">
              <Text type="caption">{cms['mobile-view-tap-image']}</Text>
            </Viewport>
          </Stack>

          <div className={styles.wrapper} style={style}>
            <div
              className={styles.imagesContainer}
              ref={imagesContainerRef}
              style={{
                transform: `translateX(${translateDesktopPx}px)`,
              }}
            >
              {images.map((image, index) => {
                // Every 7th image on desktop should be big and only the first one on mobile
                if (
                  (index % 7 === 0 && windowWidth > MOBILE_BREAKPOINT) ||
                  index === 0
                ) {
                  return (
                    <VenuePortfolioCarouselImage
                      key={image.id}
                      image={image}
                      imageSize="big"
                      cms={cms}
                      containerWidth={windowWidth}
                      onClick={onPopupOpen}
                    />
                  );
                }
                return (
                  <VenuePortfolioCarouselImage
                    key={image.id}
                    image={image}
                    imageSize="small"
                    cms={cms}
                    containerWidth={windowWidth}
                    onClick={onPopupOpen}
                  />
                );
              })}

              {/*
                Take space, to allow last mobile 'page' to be scrolled
                to left edge.
               */
              windowWidth > MOBILE_BREAKPOINT ? null : (
                <div ref={imagesPaddingRef} />
              )}
              {selectedImage && (
                <PortfolioImagePopup
                  pageData={pageData}
                  images={images}
                  initiallySelectedImage={selectedImage}
                  onClose={onPopupClose}
                  cms={cms}
                />
              )}
            </div>
          </div>
          {renderNavigation()}
        </Stack>
      </div>
    </IntersectionObserver>
  );
}
