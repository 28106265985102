import React from 'react';
import { Text } from '@treatwell/ui';
import styles from './TeamListItemContentSection.module.css';

interface Props {
  heading: string;
  children: React.ReactNode;
}

export function TeamListItemContentSection(props: Props): React.ReactElement {
  const { heading, children } = props;

  return (
    <div className={styles.container}>
      <Text as="h4" type="bodyHeavy" className={styles.heading}>
        {heading}
      </Text>
      {children}
    </div>
  );
}
