import React from 'react';

interface Props {
  color: string;
}

export const IconHairRemoval: React.FunctionComponent<Props> = props => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5 2.5C22.8807 3.88071 22.8807 6.11928 21.5 7.5L7.5 21.5C6.11928 22.8807 3.88071 22.8807 2.5 21.5C1.11928 20.1193 1.11928 17.8807 2.5 16.5L16.5 2.5C17.8807 1.11928 20.1193 1.11928 21.5 2.5ZM19 11.3431L21.7486 15.4836C22.7756 17.0305 22.5699 19.0869 21.257 20.3999C20.0105 21.6464 17.9895 21.6464 16.743 20.3999C15.4301 19.0869 15.2244 17.0305 16.2513 15.4836L19 11.3431ZM12.9995 8.82746L3.91421 17.9142C3.31455 18.5139 3.31455 19.4861 3.91421 20.0858C4.48056 20.6521 5.37924 20.6836 5.98252 20.1802L6.08578 20.0858L12.9995 13.1715V8.82746ZM19 14.9593L17.9176 16.5898C17.4171 17.3436 17.5174 18.3458 18.1572 18.9856C18.6227 19.4511 19.3773 19.4511 19.8428 18.9856C20.445 18.3834 20.5692 17.4603 20.1645 16.7252L20.0824 16.5898L19 14.9593ZM18.0175 3.81982L17.9142 3.91421L14.9995 6.82746V11.1715L20.0858 6.08578C20.6854 5.48612 20.6854 4.51387 20.0858 3.91421C19.5194 3.34786 18.6208 3.3164 18.0175 3.81982Z"
      fill={props.color}
    />
  </svg>
);
