import flattenDeep from 'lodash.flattendeep';

export type ChannelCode = 'WHN_ES' | 'WHN_FR' | 'WHN_IT';
const UALA_ID = 100_000_000;

interface GenerateUalaWidgetUrlOptions {
  isProductionEnvironment: boolean;
  channelCode: ChannelCode;
  venueId: number;
  treatmentIds: number[];
}

function channelCodeToTLD(channelCode: ChannelCode): string {
  switch (channelCode) {
    case 'WHN_ES':
      return '.es';
    case 'WHN_FR':
      return '.fr';
    case 'WHN_IT':
      return '.it';
    default:
      return '.com';
  }
}

function saasIdToTreatmentIds(saasId: string): number[] {
  return saasId.split('-').map(Number);
}

function getUalaVenueId(venueId: number): number {
  return venueId - UALA_ID;
}

export function saasIdsToTreatmentIds(saasIds: string[]): number[] {
  return flattenDeep(saasIds?.map(saasIdToTreatmentIds));
}

export function generateUalaWidgetUrl(
  options: GenerateUalaWidgetUrlOptions
): string {
  const {
    isProductionEnvironment,
    channelCode,
    venueId,
    treatmentIds,
  } = options;
  const id = getUalaVenueId(venueId);
  const environmentSubdomain = !isProductionEnvironment
    ? 'envtw-www-tw'
    : 'www-tw';
  const tld = channelCodeToTLD(channelCode);
  const url = new URL(`https://${environmentSubdomain}.uala${tld}`);
  url.pathname = `${id}/widget`;
  url.hash = `treatment=${treatmentIds.join(',')}`;
  return url.toString();
}
