import flattenDeep from 'lodash.flattendeep';
import { VenueMenuItemTypeOutput } from 'js/model/rainbow/venue/VenueMenuItemTypeOutput';
import { hasSearchCriteria, SearchCriteria } from './searchCriteria';

const MAX_SEARCH_SERVICES = 10;
const MAX_SUGGESTED_SERVICES = 5;

let maxSearchServices = MAX_SEARCH_SERVICES;
let maxSuggestedServices = MAX_SUGGESTED_SERVICES;

const addServices = (
  services: VenueMenuItemTypeOutput[],
  getMoreServices: () => VenueMenuItemTypeOutput[]
): VenueMenuItemTypeOutput[] => {
  if (services.length < maxSearchServices) {
    const moreServices = getMoreServices()
      // Remove any service that is already in 'services'.
      .filter(
        newService =>
          !services.some(service => service.data.id === newService.data.id)
      );

    return services.concat(moreServices).slice(0, maxSearchServices);
  }
  return services;
};

const servicesFromServiceIds = (
  serviceIds: string[],
  allServices: VenueMenuItemTypeOutput[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any =>
  serviceIds
    .map(serviceId =>
      allServices.find(service => service.data.id === serviceId)
    )
    .filter(service => !!service);

const servicesFromTreatmentIds = (
  treatmentIds: number[],
  allServices: VenueMenuItemTypeOutput[]
): VenueMenuItemTypeOutput[] => {
  const servicesForTreatmentIds = treatmentIds.map(treatmentId =>
    allServices.filter(
      service => service.data.primaryTreatmentCategoryId === treatmentId
    )
  );

  // flatten array of arrays
  return flattenDeep(servicesForTreatmentIds);
};

const servicesFromTreatmentTypeId = (
  treatmentTypeId: number,
  allServices: VenueMenuItemTypeOutput[]
): VenueMenuItemTypeOutput[] =>
  allServices.filter(
    service => service.treatmentCategoryGroupId === treatmentTypeId
  );

const servicesUsingSearchCriteria = (
  allServices: VenueMenuItemTypeOutput[],
  searchCriteria?: SearchCriteria
): VenueMenuItemTypeOutput[] => {
  let services: VenueMenuItemTypeOutput[] = [];
  if (searchCriteria) {
    services = addServices(services, () =>
      servicesFromServiceIds(searchCriteria.serviceIds, allServices)
    );
    if (searchCriteria.treatmentIds) {
      services = addServices(services, () =>
        servicesFromTreatmentIds(searchCriteria.treatmentIds, allServices)
      );
    }
    if (searchCriteria.treatmentTypeId) {
      services = addServices(services, () =>
        servicesFromTreatmentTypeId(
          searchCriteria.treatmentTypeId!,
          allServices
        )
      );
    }
  }

  return services;
};

const servicesUsingSuggestedServices = (
  suggestedServices: VenueMenuItemTypeOutput[]
): VenueMenuItemTypeOutput[] =>
  suggestedServices.slice(0, maxSuggestedServices);

/**
 * Create a list of services suitable for rendering as highlighted services.
 *
 * @param {Array} allServices a list that can be used to populate the returned list.
 * @param {Array} suggestedServices a list of suggested services.
 * @param {Object} searchCriteria browse page search criteria, that can be used to find
 *                  services to populate the returned list.
 *
 * @returns {Array} a list of services.
 */
export const createList = (
  allServices: VenueMenuItemTypeOutput[],
  suggestedServices: VenueMenuItemTypeOutput[],
  searchCriteria?: SearchCriteria,
  isChurned?: boolean
): VenueMenuItemTypeOutput[] => {
  if (hasSearchCriteria({ searchCriteria, isChurned })) {
    return servicesUsingSearchCriteria(allServices, searchCriteria);
  }
  return servicesUsingSuggestedServices(suggestedServices);
};

export const setMaxSearchServices = (max?: number) => {
  maxSearchServices = max || MAX_SEARCH_SERVICES;
};

export const setMaxSuggestedServices = (max?: number) => {
  maxSuggestedServices = max || MAX_SUGGESTED_SERVICES;
};
