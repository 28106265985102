import * as React from 'react';
import styles from './Checkbox.module.css';

interface Props {
  name: string;
  onChange: (isChecked: boolean) => void;
  label: React.ReactNode | string;
  isChecked: boolean;
}

export function Checkbox({
  name,
  onChange,
  label,
  isChecked = false,
}: Props): React.ReactElement {
  function handleOnKeyPress(
    event: React.KeyboardEvent<HTMLLabelElement>
  ): void {
    if (event.key !== 'Enter') {
      return;
    }
    onChange(!isChecked);
  }

  function handleOnChange(): void {
    onChange(!isChecked);
  }

  return (
    <div className={styles.container}>
      <input
        type="checkbox"
        name={name}
        id={name}
        onChange={handleOnChange}
        checked={isChecked}
      />
      <label
        htmlFor={name}
        className={styles.label}
        tabIndex={0}
        onKeyPress={handleOnKeyPress}
      >
        {label}
      </label>
    </div>
  );
}
