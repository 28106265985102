import React from 'react';
import clsx from 'clsx';
import { IconPin, MediaObject, Text } from '@treatwell/ui';
import gridStyles from 'src/assets/style/grid.module.css';
import styles from './VenueContacts.module.css';

interface Props {
  name: string;
  addressLines: string[];
}

export function VenueContacts(props: Props): React.ReactElement {
  const classes = clsx(
    gridStyles['col-xs-12'],
    gridStyles['col-sm-6'],
    gridStyles['col-md-5'],
    styles.venueContacts
  );

  return (
    <div className={classes}>
      <div className={styles.location}>
        <MediaObject space="md">
          <IconPin size={24} />
          <Text>
            <Text as="div" type="bodyHeavy">
              {props.name}
            </Text>
            {props.addressLines.map((line, index) => (
              <Text key={index} as="div">
                {line}
              </Text>
            ))}
          </Text>
        </MediaObject>
      </div>
    </div>
  );
}
