import React from 'react';
import { Message, Text, Stack } from '@treatwell/ui';
import { splitToParagraphs } from 'js/helpers/text';
import styles from './PatchTestWarning.module.css';

export interface Cms {
  patchtestwarningpopup: string;
  patchtestwarningpopupheader: string;
  patchtestwarning: string;
  patchtestpopup: string;
}

interface Props {
  cms: Cms;
}

export function PatchTestWarning(props: Props): React.ReactElement {
  const { cms } = props;

  return (
    <Message className={styles.container}>
      <Stack space="xxs">
        <Text type="captionHeavy">{cms.patchtestwarningpopupheader}</Text>
        <Text type="caption">
          {splitToParagraphs(cms.patchtestwarningpopup).map((para, index) => (
            <p key={index}>{para}</p>
          ))}
        </Text>
      </Stack>
    </Message>
  );
}
