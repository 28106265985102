import React from 'react';
import { Inline, Stack, Text } from '@treatwell/ui';
import { VerifiedReviewIcon } from './VerifiedReviewIcon';
import styles from './VerifiedReviewsBlock.module.css';

interface Props {
  heading: string;
  body: string;
}

export function VerifiedReviewsBlock(props: Props): React.ReactElement {
  return (
    <div className={styles.container}>
      <Inline space="sm" align="center">
        <VerifiedReviewIcon size={24} />
        <Stack space="xxs" className={styles.textContainer}>
          <Text as="h2" type="captionHeavy">
            {props.heading}
          </Text>
          <Text type="caption">{props.body}</Text>
        </Stack>
      </Inline>
    </div>
  );
}
