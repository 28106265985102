/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M12 4a2 2 0 01-1.45 1.923c.273.597.431 1.257.449 1.951A4.002 4.002 0 0010 0a4.002 4.002 0 00-3.874 3.002 4.977 4.977 0 011.95.448A2 2 0 0112 4zM14 12a3.986 3.986 0 00-1.094-2.75A6.024 6.024 0 0014.472 8 5.978 5.978 0 0116 12h-2z',
    'M10 8a4 4 0 01-4 4 4 4 0 00-4 4H0c0-2.26 1.25-4.227 3.094-5.25A4 4 0 1110 8zm-4 2a2 2 0 100-4 2 2 0 000 4z',
    'M8.906 13.25A3.986 3.986 0 0110 16h2a5.978 5.978 0 00-1.528-4c-.447.5-.976.923-1.566 1.25z',
  ],

  24: [
    'M9 5a5 5 0 013.487 8.581c3 1.14 4.428 3.844 4.51 7.12L17 21h-2c0-3.425-1.515-5.893-5.722-5.997L9 15l-.217-.005A4.999 4.999 0 019 5zm-5.185 9.507l.1.128a6.6 6.6 0 001.385 1.25c-1.576.982-2.244 2.71-2.297 4.836L3 21H1l.004-.31c.064-2.47.893-4.614 2.586-6.006l.225-.177zM15 3a5 5 0 013.488 8.582c3 1.14 4.427 3.844 4.508 7.119L23 19h-2c0-3.425-1.515-5.893-5.722-5.997l-.164-.002.122-.238c.283-.575.546-1.254.61-1.884a3 3 0 10-2.09-5.609c-.473-.6-1.143-1.021-1.718-1.299A4.985 4.985 0 0115 3zM9 7a3 3 0 100 6 3 3 0 000-6z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconTeam = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconTeam.displayName = 'IconTeam';
