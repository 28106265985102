import React from 'react';
import { Dropdown, DropdownItem, Text, Star } from '@treatwell/ui';
import clsx from 'clsx';
import styles from './RatingSelect.module.css';

const EM_DASH = '—'; // U+2014

interface Option {
  value: string;
  label: string;
  rating?: number;
}

interface Props {
  options: Option[];
  onChange: (value: number | string) => void;
  value: string | number;
}

export function RatingSelect(props: Props): React.ReactElement {
  const { options, onChange, value } = props;

  function onSelected(stringValue: string): void {
    const numValue = parseInt(stringValue, 10);
    if (isNaN(numValue)) {
      if (stringValue === value) {
        return;
      }
      onChange(stringValue);
    } else {
      if (numValue === value) {
        return;
      }
      onChange(numValue);
    }
  }

  return (
    <div className={styles.select}>
      <Dropdown
        labelId="treatmentFilter"
        initialSelected={value.toString()}
        menuWidthAuto
        caretSize={16}
        enableScroll
        onSelected={onSelected}
      >
        {options.map(option => (
          <DropdownItem key={option.value} value={option.value.toString()}>
            <TreatmentItem option={option} />
          </DropdownItem>
        ))}
      </Dropdown>
    </div>
  );
}

function TreatmentItem({
  option,
  isDropdownOption,
}: {
  option: Option;
  isDropdownOption?: boolean;
}): React.ReactElement {
  const ratingIcon = option.rating ? (
    <div className={styles.optionRating}>
      <span>{option.rating}</span>
      <Star size={16} fill="full" data-testid="star-small" />
    </div>
  ) : (
    <div className={styles.optionRating}>
      <span className={styles.noRating}>{EM_DASH}</span>
    </div>
  );

  const classes = clsx({
    [styles.option]: true,
    [styles.buttonText]: !isDropdownOption,
  });

  return (
    <div className={classes}>
      <Text type="body">{option.label}</Text>
      {isDropdownOption ? ratingIcon : null}
    </div>
  );
}
