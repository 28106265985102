import ReactDOM from 'react-dom';
import { useContainer } from '../hooks/useContainer';

interface Props {
  id: string;
  children: React.ReactNode;
}

export function Portal({ id, children }: Props) {
  const container = useContainer(id);
  return ReactDOM.createPortal(children, container);
}
