import React from 'react';
import { Stack } from '@treatwell/ui';
import { FormatterChannelData } from 'js/helpers/formatters';
import { VenueMenuItemDetailsOutput } from 'js/model/rainbow/venue/VenueMenuItemDetailsOutput';
import { TreatmentVenueMenuItemOutput } from 'js/model/rainbow/venue/TreatmentVenueMenuItemOutput';
import { MenuItemDetailsLabels } from 'js/model/view/venue-menu-item-details';
import { MenuItemVenue } from 'js/service/venueMenuItemService';
import { VenueMenuItemReview } from 'js/model/rainbow/venue/VenueMenuItemReviewsOutput';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import { RequestData } from 'js/helpers/service';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import { HorizontalRule } from 'js/components/HorizontalRule/HorizontalRule';
import { CmsOurWork } from 'js/model/cms/cms-venue-page';
import { ServicePriceRangeCms } from 'js/helpers/price/ServicePriceRangeCms';
import { ServiceImages } from './ServiceImages/ServiceImages';
import { ServiceReviews } from './ServiceReviews/ServiceReviews';
import styles from './ServiceDetails.module.css';
import { TreatmentGuide } from './TreatmentGuide/TreatmentGuide';
import {
  Cms as PatchTestWarningCms,
  PatchTestWarning,
} from './PatchTestWarning/PatchTestWarning';
import { ServicePolicy } from './ServicePolicy/ServicePolicy';
import { ServiceAbout } from './ServiceAbout/ServiceAbout';
import { ServiceVenueScore } from './ServiceVenueScore';
import { ServiceLoading } from './ServiceLoading';

interface WrapperProps {
  name: string;
  children: React.ReactNode;
  onClickWrapper?: () => void;
}

function ServiceDetailsWrapper({
  children,
  name,
  onClickWrapper,
}: WrapperProps): JSX.Element {
  return (
    <div className={styles.container} onClick={onClickWrapper}>
      <div className={styles.name}>{name}</div>
      {children}
    </div>
  );
}

interface Props {
  cms: {
    patchtestwarning: PatchTestWarningCms;
    ourwork: CmsOurWork;
    servicePriceRange: ServicePriceRangeCms;
  };
  labels: MenuItemDetailsLabels;
  serviceId: string;
  venue: MenuItemVenue;
  serviceDetails: VenueMenuItemDetailsOutput | null;
  menuServiceDetails: TreatmentVenueMenuItemOutput;
  reviews: VenueMenuItemReview[] | null;
  channelData: FormatterChannelData;
  onLoadMoreReviews: () => void;
  showReviews: boolean;
  showRating?: boolean;
  showTreatmentGuideLink?: boolean;
  scrollTo: (y: number) => void;
  serviceImages?: ImageOutput[];
  pageData: RequestData & { venue?: { venue: VenueOutput } };
  onClick: () => void;
}

export function ServiceDetails({
  cms,
  venue,
  channelData,
  serviceId,
  serviceDetails,
  menuServiceDetails,
  labels,
  onLoadMoreReviews,
  reviews,
  showTreatmentGuideLink,
  showReviews = true,
  showRating = true,
  scrollTo,
  serviceImages,
  pageData,
  onClick,
}: Props): JSX.Element {
  const [reviewsEl, setReviewsEl] = React.useState<HTMLDivElement | null>();

  function scrollToReview(): void {
    const reviewsTop = reviewsEl!.offsetTop;
    const offset = 80;
    scrollTo(reviewsTop - offset);
  }

  if (serviceDetails === null) {
    return <ServiceLoading />;
  }

  const reviewsCount = serviceDetails.reviewAggregates.count;
  if (showReviews && reviewsCount && (!reviews || !reviews.length)) {
    return <ServiceLoading />;
  }

  const treatmentCategory = serviceDetails.primaryTreatmentCategory || {};
  const {
    uri: { desktopUri: treatmentGuideUrl = '' } = {},
  } = treatmentCategory || { uri: {} };
  const treatmentGuideDescription = !serviceDetails.description
    ? treatmentCategory.description
    : '';

  const showVenueScore =
    (showRating || showReviews) && !!serviceDetails.reviewAggregates.count;
  const showAbout = serviceDetails.description || treatmentGuideUrl;
  const showGoodToKnow = Boolean(
    serviceDetails.policies.info && serviceDetails.policies.info.length
  );
  const showRestrictions = Boolean(
    serviceDetails.policies.restrictions &&
      serviceDetails.policies.restrictions.length &&
      serviceDetails.policies.restrictions[0].text
  );
  const hasServiceContent = Boolean(
    showAbout || showGoodToKnow || showRestrictions
  );
  const showReviewsAnchor = Boolean(
    showReviews && reviewsCount && hasServiceContent
  );

  if (!showVenueScore && !hasServiceContent) {
    return (
      <ServiceDetailsWrapper
        name={serviceDetails.name}
        onClickWrapper={onClick}
      >
        <div className={styles.noDetails}>{labels.emptyMessage}</div>
      </ServiceDetailsWrapper>
    );
  }

  const numberServiceId = serviceId.includes('TP')
    ? serviceId.split('TP')[1]
    : serviceId.split('TR')[1]; // Services from the venue are in the form of TR1111 or TP1111

  return (
    <div className={styles.wrapper} onClick={onClick}>
      <ServiceDetailsWrapper name={serviceDetails.name}>
        {showVenueScore && (
          <ServiceVenueScore
            header={labels.headers.rating}
            channelData={channelData}
            reviewAggregates={serviceDetails.reviewAggregates}
          />
        )}
        {showReviewsAnchor && (
          <a className={styles.showReviewsLink} onClick={scrollToReview}>
            {labels.showReviewsLabel}
          </a>
        )}
        {showAbout && (
          <ServiceAbout
            header={labels.headers.about}
            serviceDescription={serviceDetails.description}
          />
        )}
        {serviceImages && (
          <ServiceImages
            images={serviceImages}
            serviceId={numberServiceId}
            pageData={pageData}
            venueName={venue.name}
            header={labels.headers.serviceImageHeader}
            cms={{ ...cms.ourwork, ...cms.patchtestwarning }}
          />
        )}
        {showGoodToKnow && (
          <ServicePolicy
            header={labels.headers.info}
            policyItems={serviceDetails.policies.info}
          />
        )}
        {showRestrictions && (
          <ServicePolicy
            header={labels.headers.restrictions}
            policyItems={serviceDetails.policies.restrictions}
          />
        )}
        <Stack space="xl" className={styles.additionalInfo}>
          {menuServiceDetails.requiresPatchTest && (
            <PatchTestWarning cms={cms.patchtestwarning} />
          )}
          <TreatmentGuide
            treatmentGuideDescription={treatmentGuideDescription}
            treatmentGuideUrl={treatmentGuideUrl}
            treatmentGuideLabel={labels.treatmentGuideLinkLabel}
            showTreatmentGuideLink={showTreatmentGuideLink}
          />
        </Stack>
        <div className={styles.horizontalRuleContainer}>
          <HorizontalRule colorScheme="orange-pink-blue" />
        </div>
        {showReviews && reviews && (
          <ServiceReviews
            header={labels.headers.reviews}
            channelData={channelData}
            reviews={reviews}
            reviewsCount={reviewsCount}
            venue={venue}
            setElementRef={setReviewsEl}
            onLoadMoreReviews={onLoadMoreReviews}
          />
        )}
      </ServiceDetailsWrapper>
    </div>
  );
}
