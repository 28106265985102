import React from 'react';
import { PropsType } from 'js/types/react';
import { Icon } from 'js/components/TreatmentTypeIcon/TreatmentTypeIcon';
import { TeamListItemSentimentSection } from 'js/pages/VenuePage/VenueTeamSection/TeamListItemSentimentSection';
import { TeamListItemServicesSection } from 'js/pages/VenuePage/VenueTeamSection/TeamListItemServicesSection';
import { TeamListItemAboutSection } from 'js/pages/VenuePage/VenueTeamSection/TeamListItemAboutSection';
import { TeamListItemPortfolioSection } from 'js/pages/VenuePage/VenueTeamSection/TeamListItemPortfolioSection';
import styles from './TeamListItemContent.module.css';

export type Sections = {
  about?: { content: string; heading: string };
  services?: {
    id: number;
    heading: string;
    services: {
      id: number;
      icon: Icon;
      name: string;
    }[];
  };
  portfolioImages?: Pick<
    PropsType<typeof TeamListItemPortfolioSection>,
    'closePopupLabel' | 'heading' | 'images' | 'imageCountLabel'
  >;
  sentiments?: {
    heading: string;
    sentiments: {
      label: string;
      count: number;
      id: number;
    }[];
    stylistId: number;
  };
};

interface Props {
  sections: Sections;
  id: number;
}

export function TeamListItemContent(props: Props): React.ReactElement {
  const { sections, id } = props;
  return (
    <div
      className={styles.container}
      key={id}
      role="tabpanel"
      aria-labelledby={`team-member-${id}-tab`}
    >
      {sections.about && (
        <TeamListItemAboutSection
          heading={sections.about.heading}
          content={sections.about.content}
        />
      )}
      {sections.services && (
        <TeamListItemServicesSection
          id={id}
          heading={sections.services.heading}
          services={sections.services.services}
        />
      )}
      {sections.portfolioImages && (
        <TeamListItemPortfolioSection {...sections.portfolioImages} id={id} />
      )}
      {sections.sentiments && (
        <TeamListItemSentimentSection
          heading={sections.sentiments.heading}
          sentiments={sections.sentiments.sentiments}
          stylistId={id}
        />
      )}
    </div>
  );
}
