import React from 'react';
import { Stack, Text } from '@treatwell/ui';
import { Button, ButtonColour } from 'js/components/Button';
import styles from './VenueReviewsEmpty.module.css';

interface Props {
  i18n: (key: string, count?: number | string) => string;
  resetFilters: () => void;
}

export function VenueReviewsEmpty(props: Props): React.ReactElement {
  const { i18n, resetFilters } = props;
  return (
    <Stack align="center" className={styles.reviewsEmpty}>
      <div className={styles.icon} />
      <Text as="div" type="mdHeader" className={styles.primaryText}>
        {i18n('page.venue.review-filter.no-reviews-primary-text')}
      </Text>
      <Text as="div" className={styles.secondaryText}>
        {i18n('page.venue.review-filter.no-reviews-secondary-text')}
      </Text>
      <Button
        label={i18n('page.venue.review-filter.reset-button-label')}
        colour={ButtonColour.Blue}
        onClick={resetFilters}
      />
    </Stack>
  );
}
