import React from 'react';
import clsx from 'clsx';
import styles from './ProgressBar.module.css';

interface Props {
  val: number;
  max: number;
  colour?: string;
  showMin?: boolean;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export function ProgressBar(props: Props): React.ReactElement {
  const { val, max, colour, className, showMin, ...rest } = props;
  const width = max === 0 ? 0 : Math.floor((val / max) * 100);
  const innerClasses = {
    [styles.inner]: true,
    [styles.min]: showMin,
  };

  return (
    <div {...rest} className={clsx(styles.outer, className)}>
      <div
        className={clsx(innerClasses)}
        style={{
          backgroundColor: colour,
          width: `${width}%`,
        }}
      />
    </div>
  );
}
