import React from 'react';

import { Popup } from 'js/components/Popup/Popup';
import { PopupContent } from 'js/components/Popup/PopupContent';

import { VenueMenuItemReview } from 'js/model/rainbow/venue/VenueMenuItemReviewsOutput';
import { TreatmentVenueMenuItemOutput } from 'js/model/rainbow/venue/TreatmentVenueMenuItemOutput';
import { VenueMenuItemDetailsOutput } from 'js/model/rainbow/venue/VenueMenuItemDetailsOutput';
import {
  getMenuItemDetailLabels,
  getMenuItemDetailPopupTitles,
} from 'js/model/view/venue-menu-item-details';
import { REVIEWS_PER_PAGE } from 'js/pages/VenuePage/VenueReviewsSection/VenueReviews/constants';
import {
  MenuItemVenue,
  fetchVenueMenuItemDetails,
  fetchVenueMenuItemReviews,
} from 'js/service/venueMenuItemService';
import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { ImageOutput } from 'js/model/rainbow/ImageOutput';
import { queryToObj, objToQuery } from 'js/helpers/uri-util';
import { ServiceDetails } from './ServiceDetails';
import { Footer } from './Footer';
import { Context } from '../LocaleWrapper';

interface Props {
  channel: ChannelOutput;
  groupId?: string;
  serviceId: string;
  menuServiceDetails?: TreatmentVenueMenuItemOutput;
  venue: MenuItemVenue;
  showReviews: boolean;
  showRating: boolean;
  showTreatmentGuideLink: boolean;
  onClose: () => void;
  serviceImages?: ImageOutput[];
}

export function ServicePopup(props: Props): React.ReactElement | null {
  const popupContentRef = React.useRef<PopupContent | null>(null);
  const { pageData, i18n } = React.useContext(Context);

  const [
    serviceDetails,
    setServiceDetails,
  ] = React.useState<VenueMenuItemDetailsOutput | null>(null);
  const [reviews, setReviews] = React.useState<VenueMenuItemReview[] | null>(
    []
  );
  const [page, setPage] = React.useState<number>(0);
  const [expandedFooter, setExpandedFooter] = React.useState<boolean>(false);
  const [popupHeight, setPopupHeight] = React.useState<number>(0);

  React.useEffect(() => {
    async function mount(): Promise<{}> {
      const { venue, serviceId, showReviews } = props;
      const serviceDetails = await fetchVenueMenuItemDetails(
        pageData,
        venue.id,
        serviceId
      );
      return new Promise(async () => {
        if (!showReviews) {
          setServiceDetails(serviceDetails);
          setPage(currentPage => currentPage + 1);
          return;
        }
        const reviews = await fetchReviews();
        setServiceDetails(serviceDetails);
        setPage(currentPage => currentPage + 1);
        setReviews((reviews && reviews.reviews) || []);
      });
    }

    mount();
  }, []);

  const fetchReviews = () => {
    const { venue, serviceId } = props;
    return fetchVenueMenuItemReviews(
      pageData,
      venue.id,
      serviceId,
      page,
      REVIEWS_PER_PAGE
    );
  };

  const onLoadMoreReviews = async () => {
    const moreReviews = await fetchReviews();
    setReviews([
      ...(reviews || []),
      ...((moreReviews && moreReviews.reviews) || []),
    ]);
    setPage(page + 1);
  };

  const onPopupClose = () => {
    const { pathname, hash, search } = window.location;
    const newParams = { ...queryToObj(hash) };
    if (newParams.portfolioImage !== undefined) {
      return;
    }
    props.onClose();
    delete newParams.serviceDetails;

    const url =
      Object.keys(newParams).length > 0
        ? pathname + search + objToQuery(newParams, '#')
        : pathname + search;
    window.history.pushState(newParams, '', url);
  };

  const scrollTo = (y: number): void => {
    popupContentRef.current?.scrollTo(y);
  };

  const {
    groupId,
    serviceId,
    venue,
    channel,
    showReviews,
    showRating,
    showTreatmentGuideLink,
    menuServiceDetails,
    serviceImages,
  } = props;
  const { cms } = pageData;
  if (!serviceId || !serviceDetails) {
    return null;
  }
  const titles = getMenuItemDetailPopupTitles(pageData.cms);
  const labels = getMenuItemDetailLabels(cms, serviceDetails);

  const vcBarCms = cms.page.venue['vc-bar'];
  const menuLabelCms = cms.page.venue.menu.labels;
  const serviceDetailsCms = {
    patchtestwarning: {
      patchtestwarningpopup: vcBarCms.patchtestwarningpopup,
      patchtestwarningpopupheader: vcBarCms.patchtestwarningpopupheader,
      patchtestwarning: vcBarCms.patchtestwarning,
      patchtestpopup: vcBarCms.patchtestpopup,
    },
    ourwork: {
      ...cms.page.venue['our-work'],
      ...cms.page.venue.head['review-text'].short,
    },
    servicePriceRange: {
      perPerson: '',
      saveUpTo: menuLabelCms['save-up-to'],
      save: menuLabelCms.save,
      from: menuLabelCms.from,
    },
    footer: {
      serviceSingular: labels.headers.footer.serviceSingular,
      servicesPlural: vcBarCms['services-plural'],
      selectSingular: labels.headers.footer.selectSingular,
      select: labels.headers.footer.select,
      selected: labels.headers.footer.selected,
    },
  };

  const footerContent = (
    <Footer
      groupId={groupId!}
      service={menuServiceDetails!}
      cms={{
        ...serviceDetailsCms.footer,
        ...serviceDetailsCms.servicePriceRange,
      }}
      channel={channel}
      close={onPopupClose}
      expanded={expandedFooter}
      onExpand={() => setExpandedFooter(true)}
      popupHeight={popupHeight}
    />
  );
  const footer = footerContent
    ? {
        content: footerContent,
      }
    : undefined;

  return (
    <Popup
      width={928}
      height={754}
      onCloseRequest={onPopupClose}
      isFullscreenWhenOnMobileWidth
      hasMarginWhenNotOnMobileWidth
      hasRoundedCornersWhenNotOnMobileWidth
      getHeight={(height: number) => setPopupHeight(height)}
    >
      <PopupContent
        ref={popupContentRef}
        header={{
          mobileOnly: false,
          closeText: i18n('common.popup.close-button'),
          title: titles.desktop,
          onClose: onPopupClose,
          isTransparent: true,
          noPaddingRightOnDesktop: true,
        }}
        footer={footer}
      >
        <ServiceDetails
          cms={serviceDetailsCms}
          labels={labels}
          serviceDetails={serviceDetails}
          menuServiceDetails={menuServiceDetails!}
          reviews={reviews}
          serviceId={serviceId}
          venue={venue}
          channelData={channel}
          onLoadMoreReviews={onLoadMoreReviews}
          showRating={showRating}
          showReviews={showReviews}
          showTreatmentGuideLink={showTreatmentGuideLink}
          scrollTo={scrollTo}
          serviceImages={serviceImages}
          pageData={pageData}
          onClick={() => {
            setExpandedFooter(false);
          }}
        />
      </PopupContent>
    </Popup>
  );
}
