import React from 'react';

import { createVenueTags } from 'js/model/view/venue-tags';
import { CmsVenue } from 'js/model/cms/cms-venue';
import {
  DiscountSet,
  SalesPriceDiscountType,
} from 'js/model/rainbow/SalesPriceDiscountType';
import { VenueTypeOutput } from 'js/model/rainbow/venue/VenueTypeOutput';

import { VenueTag } from 'js/components/VenueTag/VenueTag';
import styles from './VenueTags.module.css';

interface Props {
  discountTypes: SalesPriceDiscountType[];
  venueType?: VenueTypeOutput;
  subTypes?: VenueTypeOutput[];
  cms: CmsVenue;
}

export function VenueTags(props: Props): React.ReactElement {
  const discounts: DiscountSet = {
    jit: props.discountTypes.includes(SalesPriceDiscountType.Jit),
    offpeak: props.discountTypes.includes(SalesPriceDiscountType.Offpeak),
  };

  const venueTagsData = createVenueTags(
    discounts,
    props.venueType,
    props.subTypes,
    props.cms
  );

  return (
    <div className={styles.venueTags}>
      {venueTagsData.map(tagData => (
        <VenueTag key={tagData.label} size="large" {...tagData} />
      ))}
    </div>
  );
}
