import React, { ForwardedRef } from 'react';
import clsx from 'clsx';
import { Text } from '@treatwell/ui';
import styles from './VenueMenuTitle.module.css';

interface Props {
  isWidget: boolean;
  title: string;
  subtitle?: string;
}

export const VenueMenuTitle = React.forwardRef(
  (props: Props, ref: ForwardedRef<HTMLDivElement>): React.ReactElement => {
    const { title, subtitle, isWidget } = props;
    const classnames = clsx(styles.venueMenuTitle, {
      [styles.widget]: isWidget,
    });

    return (
      <div className={classnames} ref={ref}>
        {subtitle ? (
          <Text type="caption" className={styles.subtitle}>
            {subtitle}
          </Text>
        ) : null}
        <Text as="h2" type="mdHeader" className={styles.title}>
          {title}
        </Text>
      </div>
    );
  }
);
