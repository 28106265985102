import { PropsType } from 'js/types/react';
import { EmployeeOutput } from 'js/model/rainbow/venue/EmployeeOutput';
import { CmsVenueTeamSection } from 'js/model/cms/cms-venue-page';
import { VenueTeamSection } from 'js/pages/VenuePage/VenueTeamSection';
import { mapTreatmentTypeIdToIcon } from 'js/components/TreatmentTypeIcon';
import { substitute, substituteWithDictionary } from 'js/helpers/i18n';
import { TeamMember } from 'js/pages/VenuePage/VenueTeamSection/VenueTeamSection';
import { CmsCommonPopup } from 'js/model/cms/cms-common';
import { Sections } from 'js/pages/VenuePage/VenueTeamSection/TeamListItemContent/TeamListItemContent';
import { Icon } from 'js/components/TreatmentTypeIcon/TreatmentTypeIcon';
import { TeamListItemPortfolioSection } from 'js/pages/VenuePage/VenueTeamSection/TeamListItemPortfolioSection/TeamListItemPortfolioSection';

function createTeamMemberViewModel(
  teamMember: EmployeeOutput,
  venueTeamSectionCms: CmsVenueTeamSection,
  commonPopupCms: CmsCommonPopup
): TeamMember {
  const nameSegments = teamMember.name.split(' ');
  const forenameInital = nameSegments[0].charAt(0);
  const surnameInital =
    nameSegments.length > 1
      ? nameSegments[nameSegments.length - 1].charAt(0)
      : '';
  const reviewCount =
    (teamMember.employeeReviews &&
      teamMember.employeeReviews.show &&
      teamMember.employeeReviews.count.toString()) ||
    '0';
  const reviewCountLabel =
    teamMember.employeeReviews && teamMember.employeeReviews.show
      ? substitute(venueTeamSectionCms['review-count'], reviewCount)
      : '';

  const contentSection = createContentSectionViewModel(
    teamMember,
    venueTeamSectionCms,
    commonPopupCms
  );
  return {
    image: (teamMember.image && teamMember.image.uris['68x68']) || '',
    initials: (forenameInital + surnameInital).toUpperCase(),
    name: nameSegments[0],
    rating:
      (teamMember.employeeReviews &&
        teamMember.employeeReviews.show &&
        teamMember.employeeReviews.overallRating) ||
      0,
    reviewCount: reviewCountLabel,
    displayRating: teamMember.employeeReviews
      ? teamMember.employeeReviews.displayRating
      : '',
    title: teamMember.title || '',
    sections: contentSection,
    id: teamMember.id,
  };
}

function createContentSectionViewModel(
  teamMember: EmployeeOutput,
  venueTeamSectionCms: CmsVenueTeamSection,
  commonPopupCms: CmsCommonPopup
): Sections {
  let about: { content: string; heading: string } | undefined;
  if (teamMember.description && teamMember.description.trim().length > 0) {
    about = {
      heading: venueTeamSectionCms['section-headings'].about,
      content: teamMember.description.trim(),
    };
  }

  let services:
    | {
        id: number;
        heading: string;
        services: {
          id: number;
          icon: Icon;
          name: string;
        }[];
      }
    | undefined;
  if (teamMember.treatmentCategoryGroups.length > 0) {
    services = {
      id: teamMember.id,
      heading: venueTeamSectionCms['section-headings'].services,
      services: teamMember.treatmentCategoryGroups.map(
        treatmentCategoryGroup => ({
          id: treatmentCategoryGroup.id,
          icon: mapTreatmentTypeIdToIcon(treatmentCategoryGroup.id),
          name: treatmentCategoryGroup.name,
        })
      ),
    };
  }

  let portfolioImages:
    | Pick<
        PropsType<typeof TeamListItemPortfolioSection>,
        'closePopupLabel' | 'heading' | 'images' | 'imageCountLabel'
      >
    | undefined;
  const imageCount = teamMember.portfolioImages.length;

  if (imageCount > 0) {
    const imageCountLabelTemplate =
      imageCount === 1
        ? venueTeamSectionCms['portfolio-count'].singular
        : venueTeamSectionCms['portfolio-count'].plural;
    const imageCountLabel = substituteWithDictionary(imageCountLabelTemplate, {
      count: imageCount.toString(),
    });

    portfolioImages = {
      closePopupLabel: commonPopupCms['close-button'],
      heading: venueTeamSectionCms['section-headings'].portfolio,
      images: teamMember.portfolioImages.map(image => ({
        '128x170': image.uris['128x170'],
        '800x1066': image.uris['800x1066'],
      })),
      imageCountLabel,
    };
  }

  let sentiments:
    | {
        heading: string;
        sentiments: {
          label: string;
          count: number;
          id: number;
        }[];
        stylistId: number;
      }
    | undefined;

  if (teamMember.portfolioSentiments.length > 0) {
    sentiments = {
      heading: substitute(
        venueTeamSectionCms['section-headings'].sentiments,
        teamMember.name.split(' ')[0]
      ),
      sentiments: teamMember.portfolioSentiments
        .sort((a, b) => b.count - a.count)
        .slice(0, 4),
      stylistId: teamMember.id,
    };
  }

  return {
    about,
    services,
    portfolioImages,
    sentiments,
  };
}

export function createVenueTeamSectionViewModel(
  teamMembers: EmployeeOutput[],
  venueTeamSectionCms: CmsVenueTeamSection,
  commonPopupCms: CmsCommonPopup
): PropsType<typeof VenueTeamSection> {
  const bookableTeamMembers = teamMembers
    .filter(teamMember => teamMember.providesServices)
    .map(teamMember =>
      createTeamMemberViewModel(teamMember, venueTeamSectionCms, commonPopupCms)
    );

  return {
    heading: venueTeamSectionCms.heading,
    teamMembers: bookableTeamMembers,
  };
}
