import React from 'react';
import styles from './ProgressBar.module.css';

export function ProgressBar(): React.ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.progress} />
    </div>
  );
}
