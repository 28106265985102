import React from 'react';
import { VenueReviewsList } from 'js/pages/VenuePage/VenueReviewsSection/VenueReviews/VenueReviewsList';
import { MenuItemVenue } from 'js/service/venueMenuItemService';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { ReviewOutput } from 'js/model/ReviewOutput';
import { FormatterChannelData } from 'js/helpers/formatters';
import { VenueMenuItemReview } from 'js/model/rainbow/venue/VenueMenuItemReviewsOutput';
import { Context } from 'js/components/LocaleWrapper';
import styles from './ServiceReviews.module.css';
import { ServiceSection } from '../ServiceSection/ServiceSection';

export interface ReviewsRef {
  refs: {
    reviews: {
      offsetTop: number;
    };
  };
}

interface Props {
  header: string;
  channelData: FormatterChannelData;
  reviews: VenueMenuItemReview[];
  reviewsCount: number;
  venue: MenuItemVenue;
  onLoadMoreReviews: (reviews?: ReviewOutput[]) => void;
  setElementRef: (element: HTMLDivElement | null) => void;
}

export function ServiceReviews(props: Props): React.ReactElement | null {
  const { i18n } = React.useContext(Context);
  const onLoadMoreReviews = (reviews?: ReviewOutput[]) =>
    props.onLoadMoreReviews(reviews);

  const {
    header,
    channelData,
    venue,
    reviews,
    reviewsCount,
    setElementRef,
  } = props;
  if (!reviewsCount) {
    return null;
  }

  return (
    <ServiceSection header={header}>
      <div className={styles.reviewsListHeader}>
        <VenueReviewsList
          i18n={i18n}
          more={reviews.length < reviewsCount}
          ref={node => {
            setElementRef(node);
          }}
          channelData={channelData as Channel}
          venueId={venue.id}
          venueNormalisedName={venue.normalisedName}
          reviews={reviews}
          onLoadMoreReviews={onLoadMoreReviews}
        />
      </div>
    </ServiceSection>
  );
}
