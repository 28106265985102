import PropTypes from 'prop-types';
import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import { browseLocationValue } from 'js/helpers/uri-util';
import { LoadingButton } from 'js/components/Button/LoadingButton';
import { LocationSearchInput } from 'js/components/controls/LocationSearchInput/LocationSearchInput';
import {
  readTreatmentTabData,
  saveTreatmentSearch,
} from 'js/pages/HomePage/search-data';
import styles from './Search.module.css';
import { Context } from './LocaleWrapper';

class Search extends Component {
  static propTypes = {
    browseUriValues: PropTypes.object.isRequired,
    popularLocations: PropTypes.array,
    onSelectedLocationChange: PropTypes.func,
    onSearchButtonClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      location: '',
      recentLocations: [],
      isValidLocationSearchInput: true,
    };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { recentLocations: recentLocationSearches } = readTreatmentTabData(
      null,
      null,
      null,
      null
    );
    this.setState({ recentLocations: recentLocationSearches });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  onLocationSearchInputChange = (value, selectedKey, data, index) => {
    const { onSelectedLocationChange = () => {} } = this.props;

    if (data) {
      this.setState({ location: data, isValidLocationSearchInput: true });
    }

    if (selectedKey) {
      onSelectedLocationChange(data.name, index);
    }
  };

  async navigateToBrowsePage(selectedLocation, browseUriValues) {
    const searchParamsData = await browseLocationValue({}, selectedLocation);
    const uri = this.context.generateUri('browse', {
      ...searchParamsData,
      ...browseUriValues,
    });

    this.setState({ processing: false });

    if (uri) {
      window.location = uri;
    }
  }

  async submit() {
    const { onSearchButtonClick = () => {} } = this.props;

    if (this.state.processing) {
      return;
    }

    const selectedLocation = this.state.location || null;
    const browseUriValues = this.props.browseUriValues;

    if (browseUriValues.offerResultType) {
      browseUriValues.offerResultType = browseUriValues.offerResultType.toUpperCase();
    }
    if (selectedLocation === null) {
      this.setState({ isValidLocationSearchInput: false });
      onSearchButtonClick('failed: no selected location');
      return;
    }

    this.setState({
      processing: true,
    });

    saveTreatmentSearch(null, selectedLocation, null, null);
    this.navigateToBrowsePage(selectedLocation, browseUriValues);
    onSearchButtonClick('success');
  }

  render() {
    const { processing } = this.state;
    const { i18n } = this.context;

    return (
      <>
        <div className={styles.searchInput}>
          <LocationSearchInput
            cmsCommonControls={this.context.i18n('common.controls')}
            pageData={{}}
            recentLocations={this.state.recentLocations}
            popularLocations={this.props.popularLocations}
            hasCurrentLocationOption={!this.state.isValidLocationSearchInput}
            isErrorStyling={!this.state.isValidLocationSearchInput}
            onChange={this.onLocationSearchInputChange}
            onSearchResultsChange={() => {}}
            isPatternedBackground
          />
        </div>

        <LoadingButton
          disableLoading={!this.state.isValidLocationSearchInput}
          type="submit"
          label={i18n('venue-search.submit')}
          processing={processing}
          onClick={this.submit}
          fullWidth
        />
      </>
    );
  }
}
Search.contextType = Context;

export default Search;
