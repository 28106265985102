import React from 'react';
import { Inline } from '@treatwell/ui';
import { CmsWidgetFooter } from 'js/model/cms/cms-footer';
import { parseCmsBoolean } from 'js/model/cms/boolean';
import clsx from 'clsx';
import styles from './WidgetFooterLinks.module.css';

interface Props {
  cms: CmsWidgetFooter;
}

export function WidgetFooterLinks({ cms }: Props): React.ReactElement {
  function generateLinks(): React.ReactNode {
    if (!cms) {
      return;
    }

    const cookieSettingsLabel = cms['link-groups'].find(
      group => group['cookie-settings']
    )?.['cookie-settings'];

    const linkGroups = cms['link-groups'].map(group => group.links);

    // Flatten linkGroups[][] to be a single array
    const allLinkGroups = linkGroups.reduce(
      (prev, next) => prev.concat(next),
      []
    );

    const enabledLinks = allLinkGroups.filter(group =>
      parseCmsBoolean(group['widget-enabled'])
    );

    const linkNode = enabledLinks.map((link, index) => {
      if (!link.text || !enabledLinks || !link.uri) {
        return null;
      }
      return (
        <a href={link.uri} key={index} className={styles.link}>
          {link.text}
        </a>
      );
    });

    return (
      <>
        {linkNode}
        {cookieSettingsLabel && (
          <a
            href="#"
            className={clsx(styles.link)}
            onClick={event => {
              event.preventDefault();
              if (!window.TreatwellCookieConsent) {
                return null;
              }
              window
                .TreatwellCookieConsent()
                .then(api => {
                  api.openPreferences();
                })
                .catch(() => {});
            }}
            title={cookieSettingsLabel}
          >
            {cookieSettingsLabel}
          </a>
        )}
      </>
    );
  }

  return <Inline className={styles.linksContainer}>{generateLinks()}</Inline>;
}
