import range from 'lodash.range';

interface CalculatedPagination extends Pages, Navigation, Ellipsis {}

interface Pages {
  pages: number[];
}

interface Navigation {
  previousNavigation: boolean;
  nextNavigation: boolean;
}

interface Ellipsis {
  ellipsisStart: boolean;
  ellipsisEnd: boolean;
}

export function displayNavigation(
  currentPage: number,
  totalPages: number
): Navigation {
  let previousNavigation = false;
  let nextNavigation = false;

  if (currentPage > 0 && currentPage < totalPages) {
    previousNavigation = true;
  }

  if (currentPage < totalPages - 1) {
    nextNavigation = true;
  }

  return { previousNavigation, nextNavigation };
}

function addEllipsis(
  candidatePages: number[],
  lastPage: number
): Pages & Ellipsis {
  let ellipsisStart = false;
  let ellipsisEnd = false;

  const pages = [...candidatePages];

  if (candidatePages[0] > 0) {
    pages[0] = 0;
    ellipsisStart = true;
  }

  if (candidatePages[candidatePages.length - 1] < lastPage) {
    pages[pages.length - 1] = lastPage;
    ellipsisEnd = true;
  }

  return {
    pages,
    ellipsisStart,
    ellipsisEnd,
  };
}

export function calculatePagination(
  currentPage: number,
  totalPages: number,
  maxPagesToDisplay: number
): CalculatedPagination {
  if (maxPagesToDisplay % 2 === 0) {
    throw new Error('Pagination only works for an odd amount of pages');
  }

  if (currentPage >= totalPages) {
    // Current page is beyond the last page.
    // An unlikely situation, but it can happen. Perhaps for a bookmarked page.
    //
    // Provide a page list starting from the first page
    // But with no navigation, because we're not on any of the listed pages.

    const pages = range(0, Math.min(totalPages, maxPagesToDisplay));
    return {
      pages,
      ellipsisStart: false,
      ellipsisEnd: false,
      previousNavigation: false,
      nextNavigation: false,
    };
  }

  if (totalPages <= maxPagesToDisplay) {
    const pages = range(0, totalPages);
    return {
      pages,
      ellipsisStart: false,
      ellipsisEnd: false,
      ...displayNavigation(currentPage, totalPages),
    };
  }

  const lastPage = totalPages - 1;
  const actualMaxPages = Math.min(totalPages, maxPagesToDisplay);

  let startPage = currentPage;
  let endPage = currentPage;

  for (let pageCount = 1; pageCount < actualMaxPages; ) {
    if (startPage > 0) {
      startPage--;
      pageCount++;
    }

    if (pageCount < actualMaxPages && endPage < lastPage) {
      endPage++;
      pageCount++;
    }
  }

  // lodash.range is not end-inclusive
  const candidatePages = range(startPage, endPage + 1);
  const { ellipsisStart, ellipsisEnd, pages } = addEllipsis(
    candidatePages,
    lastPage
  );
  return {
    pages,
    ellipsisStart,
    ellipsisEnd,
    ...displayNavigation(currentPage, totalPages),
  };
}
