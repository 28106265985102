import React from 'react';
import clsx from 'clsx';
import { Text } from '@treatwell/ui';
import styles from './PriceView.module.css';

interface Props {
  pricePrefix?: string;
  price: string;
  priceSuffix?: string;
  strikeThroughPrice?: string;
  discountText?: string;
  inline?: boolean;
  hasParentItem?: boolean;
  alignStart?: boolean;
}

export class PriceView extends React.PureComponent<Props> {
  protected static defaultProps: Props = {
    pricePrefix: '',
    price: '',
    priceSuffix: '',
    strikeThroughPrice: '',
    discountText: '',
    inline: false,
    hasParentItem: false,
    alignStart: false,
  };

  public render(): React.ReactNode {
    const {
      pricePrefix,
      price,
      priceSuffix,
      strikeThroughPrice,
      discountText,
      inline,
      hasParentItem,
      alignStart,
    } = this.props;

    const wrapperClasses = clsx({
      [styles.priceWrapper]: true,
      [styles.inline]: inline,
      [styles.alignStart]: alignStart,
      [styles.stacked]: !inline,
      [styles.childItem]: hasParentItem,
    });

    const type = 'bodyHeavy';
    return (
      <div className={wrapperClasses}>
        <div className={styles.priceLabel}>
          {pricePrefix !== '' && <Text type={type}>{pricePrefix}&nbsp;</Text>}

          <Text type={type}>{price}</Text>

          {priceSuffix !== '' && <Text type={type}>&nbsp;{priceSuffix}</Text>}
        </div>

        {strikeThroughPrice !== '' && (
          <div className={styles.strikethroughPrice}>
            <Text as="s">{strikeThroughPrice}</Text>
            {priceSuffix !== '' && <Text as="s">&nbsp;{priceSuffix}</Text>}
          </div>
        )}

        {discountText !== '' && (
          <Text className={styles.discountText}>{discountText}</Text>
        )}
      </div>
    );
  }
}
