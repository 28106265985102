import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

export function trackEvent(
  category: Category,
  property: Property,
  action: Action | string,
  label?: string,
  value?: number
): Promise<void> {
  return trackStructuredEvent({
    category,
    property,
    action,
    label,
    value,
  });
}

export enum Category {
  OfflineVenue = 'offline_venue',
  ChurnedVenue = 'churned_venue',
  VenueLocator = 'venue_locator',
  VenueLocatorOnChurnedVenue = 'venue_locator_on_churned_venue',
}

export enum Property {
  LocationField = 'location_field',
  SearchButton = 'search_button',
  BrowseMoreVenues = 'browse_more_venues',
  GoToVenue = 'go_to_venue',
}

export enum Action {
  Click = 'click',
}
