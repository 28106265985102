import React from 'react';
import clsx from 'clsx';
import gridStyles from 'src/assets/style/grid.module.css';

import { PageSection } from 'js/components/PageSection';
import { Stack } from '@treatwell/ui';
import { createOpeningHoursViewModel } from 'js/model/view/venue-opening-times';
import { formatAddress } from 'js/helpers/formatters';
import { VenueOpeningTimes } from 'js/components/VenueOpeningTimes';
import { CmsBrowsePage } from 'js/model/cms/cms-browse-page';
import { WhenVisible } from 'js/components/WhenVisible';
import { getStaticMapUrl, VenueLocation } from 'js/helpers/static-map-url';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';
import { hasOpeningHours } from 'js/helpers/venue-relative-opening-time';
import { VenueDescription } from './VenueDescription';
import { VenueContacts } from './VenueContacts';
import venueContactsStyles from './VenueContacts.module.css';
import venuePageStyles from '../styles.module.css';

const aboutSectionId = 'about';
const descriptionSectionId = 'description';

interface Props {
  venue: VenueOutput;
  openingHoursClosedLabel: string;
  dayNames: CmsBrowsePage['common']['day-names'];
  dayNamesShort: CmsBrowsePage['common']['calendar']['day-names-short'];
  countryCode: string;
  channelCode: string;
  titleAbout: string;
}

export function VenueAboutSection(props: Props): React.ReactElement {
  const formattedAddressLines = React.useRef<string[]>(
    formatAddress(
      props.venue.location.address.addressLines,
      props.venue.location.address.postalCode,
      props.channelCode === 'WHN_GB' || props.channelCode === 'WHN_IE'
        ? 'newline'
        : 'prefix'
    )
  );

  const handleMapClick = () => {
    const query = encodeURIComponent(formattedAddressLines.current.join(' '));

    window.open(
      `https://www.google.com/maps/search/?api=1&query=${query}`,
      '_blank'
    );
  };

  const renderMap = (): React.ReactNode => {
    const staticMapUrl = getStaticMapUrl(props.venue.location as VenueLocation);

    return (
      <WhenVisible
        placeholder={<div className={venueContactsStyles.staticMapImage} />}
      >
        <div
          className={venueContactsStyles.staticMapImage}
          style={{
            backgroundImage: `url(${staticMapUrl})`,
          }}
          onClick={handleMapClick}
        />
      </WhenVisible>
    );
  };

  const venue = props.venue;

  const openingTimes = createOpeningHoursViewModel(
    venue.openingHours,
    props.openingHoursClosedLabel,
    props.dayNames,
    props.dayNamesShort,
    props.countryCode
  );

  const classes = clsx(
    [gridStyles['col-xs-12']],
    [gridStyles['col-sm-6']],
    [gridStyles['col-md-7']],
    venueContactsStyles.mapContainer
  );

  return (
    <>
      <PageSection id={aboutSectionId} title={props.titleAbout}>
        <div className={clsx(gridStyles.row, venuePageStyles.addressRow)}>
          <div className={classes}>{renderMap()}</div>
          <VenueContacts
            name={venue.name}
            addressLines={formattedAddressLines.current}
          />
        </div>
      </PageSection>
      <PageSection id={descriptionSectionId}>
        <div className={venuePageStyles.descriptionContainer}>
          <div className={venuePageStyles.gridDescription}>
            <Stack space="xs">
              <VenueDescription htmlDescription={venue.description!} />
            </Stack>
          </div>
          {hasOpeningHours(venue.openingHours) && (
            <VenueOpeningTimes
              items={openingTimes}
              positioningClassName={venuePageStyles.gridOpeningTimes}
            />
          )}
        </div>
      </PageSection>
    </>
  );
}
