import React from 'react';
import { FormatterChannelData } from 'js/helpers/formatters';
import { VenueTypeOutput } from 'js/model/rainbow/venue/VenueTypeOutput';
import { Venue as ProximityVenue } from 'js/model/rainbow/venue/ProximityVenue';
import gridStyles from 'assets/style/grid.module.css';
import { Context } from 'js/components/LocaleWrapper';
import styles from './ProximityVenue.module.css';
import { NearbyVenueType } from './NearbyVenueType';
import { NearbyVenue } from './NearbyVenue';

export interface Props {
  location: string;
  nearbyVenues: ProximityVenue[];
  nearbyVenueTypes: VenueTypeOutput | VenueTypeOutput[];
  nearbyVenueSubTypes: VenueTypeOutput | VenueTypeOutput[];
  channelData: FormatterChannelData;
}

function sortVenuesByProximityDistance(
  venues: ProximityVenue[]
): ProximityVenue[] {
  return venues.sort((a, b) => a.proximityDistance - b.proximityDistance);
}

export function ProximityVenues(props: Props): React.ReactElement {
  const {
    location,
    nearbyVenues,
    nearbyVenueTypes,
    nearbyVenueSubTypes,
    channelData,
  } = props;

  const sortedVenues = sortVenuesByProximityDistance(nearbyVenues).map(
    (venue, index) => (
      <Context.Consumer key={venue.id}>
        {value => (
          <NearbyVenue
            name={venue.name}
            uri={value.generateUri('venue', {
              normalisedName: venue.normalisedName,
            })}
            image={venue.primaryImage.uris['360x240']}
            rating={venue.rating.average || 0}
            reviews={venue.rating.count || 0}
            distance={venue.proximityDistance}
            channelData={channelData}
            venueRank={index}
            venueId={venue.id}
          />
        )}
      </Context.Consumer>
    )
  );

  return (
    <div>
      <div className={styles.venues}>{sortedVenues}</div>
      <div className={gridStyles['col-xs-12']}>
        {!nearbyVenueTypes ? null : (
          <NearbyVenueType
            location={location}
            types={nearbyVenueTypes}
            isType
          />
        )}
        {!nearbyVenueSubTypes ? null : (
          <NearbyVenueType location={location} types={nearbyVenueSubTypes} />
        )}
      </div>
    </div>
  );
}
