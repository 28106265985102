import { formatPrice } from 'js/helpers/price/priceUtilities';
import {
  ChannelLoyalty,
  LoyaltyAchievement,
} from 'js/model/rainbow/content/ChannelOutput';

export const getAchievements = ({
  achievements,
  channel,
}: {
  channel: ChannelLoyalty;
  achievements: LoyaltyAchievement[];
}) => {
  const lowestAchievement = achievements[0];
  const highestAchievement = achievements[achievements.length - 1];
  const lowestAchievementValue = formatPrice(
    lowestAchievement.giftcardAmount,
    channel
  );
  const highestAchievementValue = formatPrice(
    highestAchievement.giftcardAmount,
    channel
  );
  const lowestMilestone = lowestAchievement.points;
  const highestMilestone = highestAchievement.points;
  return {
    lowestAchievementValue,
    highestAchievementValue,
    lowestMilestone,
    highestMilestone,
  };
};
