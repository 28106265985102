import React, { useEffect } from 'react';
import { setCookie, getExpiryDate, storage } from '@treatwell/ui';
import { Context } from 'js/components/LocaleWrapper';
import { ActiveVenue } from 'js/pages/VenuePage/ActiveVenue';
import { ClosedVenue } from 'js/pages/VenuePage/ClosedVenue';
import {
  Label,
  trackVenueHeaderExperiment,
} from 'js/components/Header/VenuePageHeader/tracking';
import { useTrackPageAndSessionView } from 'js/hooks/useTrackPageAndSessionView';

const CLOSED_STATUSES = [
  'churned',
  'closed',
  'disabled',
  'temporarily_disabled',
];

export function MarketplaceVenuePage(): React.ReactElement {
  const { pageData, i18n, generateUri } = React.useContext(Context);
  const venuePage = pageData.venue;
  const venueStatus = venuePage.venue.status;
  const isClosed = CLOSED_STATUSES.includes(venueStatus);
  const isNotListedOnMarketplace =
    venueStatus === 'live' && venuePage.venue.listedOnMarketplace === false;
  const isChurned = venueStatus === 'churned';

  useTrackPageAndSessionView(venuePage.venue.id);

  useEffect(() => {
    const label = pageData.showVenueHeaderExperiment
      ? Label.ExperimentEnabled
      : Label.ExperimentDisabled;
    trackVenueHeaderExperiment(label);
  }, [pageData.showVenueHeaderExperiment]);

  useEffect(() => {
    const urlObject = new URL(location.href);
    const rwgToken = urlObject.searchParams.get('rwg_token');
    const venueId = pageData.venue.venue.id;

    if (!rwgToken) {
      return;
    }
    const rwgCookie = {
      venue_id: venueId,
      rwg_token: decodeURIComponent(rwgToken),
    };

    setCookie({
      name: 'rwg_cookie',
      value: JSON.stringify(rwgCookie),
      expires: getExpiryDate(30),
    });

    storage.session.setItem('rwg', venueId);

    urlObject.searchParams.delete('rwg_token');
    window.history.replaceState('', '', urlObject.toString());
  }, [pageData.venue.venue.id]);

  if (isClosed || isNotListedOnMarketplace) {
    return <ClosedVenue isChurned={isChurned} />;
  }
  return (
    <ActiveVenue i18n={i18n} pageData={pageData} generateUri={generateUri} />
  );
}
