import React from 'react';
import styles from './Logo.module.css';

export function Logo(): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 595.28 220.4"
      className={styles.container}
    >
      <path
        d="M550.88,64.79l-16.98,2.02v90.02h16.98V64.79z M518.7,64.79l-16.98,2.02v90.02h16.98V64.79z M327.5,147.69
	c-7.96,0-12.71-5.22-12.71-13.89V80.83h-1.43l-25.65,23.39v1.31h10.09v33.25c0,12.59,7.72,19.24,18.29,19.24
	c8.2,0,14.61-3.92,19-10.93v-0.83C332.37,147.21,329.99,147.69,327.5,147.69 M83.93,147.69c-7.84,0-12.59-5.22-12.59-13.89V80.83
	h-1.42l-25.77,23.39v1.31h10.21v33.25c0,12.59,7.72,19.24,18.29,19.24c8.08,0,14.49-3.92,19-10.93v-0.83
	C88.91,147.21,86.42,147.69,83.93,147.69 M259.09,98.28v58.55h16.98V96.38L259.09,98.28z M120.27,96.38l-16.98,1.9v58.55h16.98
	V96.38z M457.3,130.94c2.26,0.71,5.11,1.19,7.96,1.19c13.06,0,23.99-8.79,23.99-19.24c0-9.14-8.67-16.39-21.26-16.51v0.83
	c4.04,2.14,4.99,7.24,4.99,11.76c0,11.64-7.24,20.43-15.67,21.14V130.94z M172.28,130.94c2.26,0.71,4.99,1.19,7.96,1.19
	c13.06,0,23.87-8.79,23.87-19.24c0-9.14-8.67-16.39-21.26-16.51v0.83c4.04,2.14,4.99,7.24,4.99,11.76
	c0,11.64-7.25,20.43-15.56,21.14V130.94z M464.66,96.38c-17.46,0-30.4,13.18-30.4,31.23c0,18.05,12.35,30.4,29.57,30.4
	c11.52,0,20.9-5.82,25.53-16.15v-1.07c-3.56,3.21-9.5,4.87-15.44,4.87c-13.54,0-21.97-8.55-21.97-22.92
	c0-10.57,4.63-21.73,12.71-25.53V96.38z M179.64,96.38c-17.46,0-30.52,13.18-30.52,31.23c0,18.05,12.35,30.4,29.57,30.4
	c11.64,0,21.02-5.82,25.53-16.15v-1.07c-3.56,3.21-9.5,4.87-15.44,4.87c-13.42,0-21.97-8.67-21.97-22.92
	c0-10.57,4.75-21.73,12.83-25.53V96.38z M215.04,129.4c0,16.39,9.38,28.62,23.16,28.62c8.08,0,14.13-4.87,17.93-12.35v-0.71
	c-2.02,1.07-4.51,1.78-7.13,1.78c-9.26-0.12-16.03-8.19-16.03-20.54c0-16.15,9.86-27.08,22.8-27.08v-0.95
	c-2.73-1.19-6.29-1.78-9.98-1.78C228.34,96.38,215.04,110.28,215.04,129.4 M318.95,105.53h16.51v-7.96h-16.51V105.53z M75.49,105.53
	H92v-7.96H75.49V105.53z M395.55,97.57h-18.29l24.58,60.09h1.19l9.14-19.12L395.55,97.57z M362.41,97.57h-18.29l24.58,60.09h1.19
	l9.15-19.12L362.41,97.57z M412.41,97.57c2.97,5.82,5.7,17.22,5.7,22.92h0.83l11.64-22.92H412.41z M122.88,113.84v0.59
	c3.68-3.8,8.91-4.87,14.01-4.87c3.33,0,5.23,0.48,6.77,1.19V96.98c-0.95-0.36-2.37-0.59-4.16-0.59
	C130.12,96.38,124.9,102.8,122.88,113.84"
        fillRule="nonzero"
      />
    </svg>
  );
}
