/* eslint-disable no-var */
import React from 'react';
import { Stack, Inline, useViewport } from '@treatwell/ui';
import { spacingsPx } from '@treatwell/design-tokens';
import { Direction } from 'js/components/Icons/Chevron/Chevron';
import { isElementInView } from 'js/helpers/isElementInView';
import { TeamListItemPortfolioImage } from '../../TeamListItemPortfolioSection';
import { GalleryImage } from './GalleryImage/GalleryImage';
import { GalleryNavigationItem } from './GalleryNavigationItem/GalleryNavigationItem';
import { containerWidth as carouselItemWidth } from './GalleryImage/GalleryImage.module.css';
import { containerWidth as navigationItemWidth } from './GalleryNavigationItem/GalleryNavigationItem.module.css';
import styles from './Gallery.module.css';

const MAX_CAROUSEL_WIDTH = 500; // A carousel width is static on tablet/desktop.
const CAROUSEL_ITEM_SPACING = spacingsPx.sm;
const CONTAINER_HORIZONTAL_PADDING = spacingsPx.md;

interface Props {
  images: TeamListItemPortfolioImage[];
  selectedImage: TeamListItemPortfolioImage;
  onImageClick: (image: TeamListItemPortfolioImage) => void;
}

export function Gallery(props: Props): React.ReactElement {
  var NAVIGATION_ITEM_WIDTH = Number.parseInt(navigationItemWidth, 10);
  const { images, selectedImage, onImageClick } = props;
  const isMobile = useViewport({ device: 'mobile' });
  const carouselRef = React.useRef<HTMLElement>(null);
  const selectedGalleryImageRef = React.useRef<HTMLDivElement | null>(null);
  const CAROUSEL_ITEM_WIDTH = Number.parseInt(carouselItemWidth, 10);
  const CAROUSEL_HORIZONTAL_MARGIN = Number.parseInt(
    styles.carouselHorizontalMargin,
    10
  );
  const getCarouselWidth = (): string | number => {
    if (isMobile) {
      return '100%';
    }
    const MAX_CAROUSEL_ITEMS_WIDTH =
      MAX_CAROUSEL_WIDTH -
      CONTAINER_HORIZONTAL_PADDING * 2 -
      NAVIGATION_ITEM_WIDTH * 2 -
      CAROUSEL_HORIZONTAL_MARGIN * 2;

    const pageSize = Math.floor(
      MAX_CAROUSEL_ITEMS_WIDTH / (CAROUSEL_ITEM_WIDTH + CAROUSEL_ITEM_SPACING)
    );

    return (
      (CAROUSEL_ITEM_WIDTH + CAROUSEL_ITEM_SPACING) * pageSize -
      CAROUSEL_ITEM_SPACING
    );
  };
  const [carouselWidth, setCarouselWidth] = React.useState<string | number>(
    getCarouselWidth
  );
  const [hasReachedLeftEdge, setHasReachedLeftEdge] = React.useState<boolean>(
    false
  );
  const [hasReachedRightEdge, setHasReachedRightEdge] = React.useState<boolean>(
    false
  );

  React.useLayoutEffect(() => {
    scrollSelectedGalleryImageIntoView();
  }, []);

  React.useLayoutEffect(() => {
    function onScroll(): void {
      requestAnimationFrame(updateCarouselScrollPositionStatuses);
    }

    carouselRef.current?.addEventListener('scroll', onScroll);

    onScroll();

    return () => {
      carouselRef.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  React.useLayoutEffect(() => {
    setCarouselWidth(getCarouselWidth());
    updateCarouselScrollPositionStatuses();
  }, [isMobile]);

  React.useLayoutEffect(() => {
    scrollSelectedGalleryImageIntoView();
  }, [carouselWidth]);

  React.useLayoutEffect(() => {
    if (
      selectedGalleryImageRef.current &&
      carouselRef.current &&
      !isElementInView(selectedGalleryImageRef.current, carouselRef.current)
    ) {
      scrollSelectedGalleryImageIntoView();
    }
  }, [selectedImage]);

  function updateCarouselScrollPositionStatuses(): void {
    const carouselElement = carouselRef.current;
    if (carouselElement) {
      const { scrollLeft, scrollWidth, clientWidth } = carouselElement;
      const maxScrollLeft = scrollWidth - clientWidth;
      setHasReachedLeftEdge(scrollLeft === 0);
      setHasReachedRightEdge(scrollLeft === maxScrollLeft);
    }
  }

  function scrollSelectedGalleryImageIntoView(keepInViewOnly = false): void {
    if (selectedGalleryImageRef.current && carouselRef.current) {
      if (
        keepInViewOnly &&
        isElementInView(selectedGalleryImageRef.current, carouselRef.current)
      ) {
        return;
      }
      selectedGalleryImageRef.current.scrollIntoView({ inline: 'start' });
    }
  }

  function navigate(direction: 'left' | 'right'): void {
    const carouselElement = carouselRef.current;

    if (!carouselElement) {
      return;
    }

    const toLeft =
      carouselElement.scrollLeft -
      Number(carouselWidth) -
      CAROUSEL_ITEM_SPACING +
      CAROUSEL_ITEM_WIDTH +
      CAROUSEL_ITEM_SPACING;
    const toRight =
      carouselElement.scrollLeft +
      Number(carouselWidth) +
      CAROUSEL_ITEM_SPACING -
      CAROUSEL_ITEM_WIDTH -
      CAROUSEL_ITEM_SPACING;

    carouselElement.scroll({
      left: direction === 'left' ? toLeft : toRight,
      behavior: 'smooth',
    });
  }

  return (
    <Stack className={styles.container} space="xs">
      <Inline justify="between">
        <GalleryNavigationItem
          isHidden={isMobile}
          isDisabled={hasReachedLeftEdge}
          chevronDirection={Direction.Left}
          onClick={() => navigate('left')}
        />
        <Inline
          forwardRef={carouselRef}
          className={styles.carousel}
          space={isMobile ? undefined : 'sm'}
          style={{ width: carouselWidth }}
        >
          {images.map(image => (
            <GalleryImage
              ref={node => {
                if (image['128x170'] === selectedImage['128x170']) {
                  selectedGalleryImageRef.current = node;
                }
              }}
              key={image['128x170']}
              imageUrl={image['128x170']}
              isSelected={image['128x170'] === selectedImage['128x170']}
              onClick={() => {
                onImageClick(image);
              }}
            />
          ))}
        </Inline>
        <GalleryNavigationItem
          isHidden={isMobile}
          isDisabled={hasReachedRightEdge}
          chevronDirection={Direction.Right}
          onClick={() => navigate('right')}
        />
      </Inline>
    </Stack>
  );
}
