import React from 'react';
import clsx from 'clsx';
import { Chevron, Direction } from 'js/components/Icons/Chevron/Chevron';
import styles from './GalleryNavigationItem.module.css';

interface Props {
  isHidden?: boolean;
  isDisabled?: boolean;
  chevronDirection: Direction;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export function GalleryNavigationItem(props: Props): React.ReactElement {
  const {
    isHidden = false,
    isDisabled = false,
    chevronDirection,
    onClick,
  } = props;

  return (
    <div
      className={clsx(styles.container, {
        [styles.hidden]: isHidden,
        [styles.disabled]: isDisabled,
      })}
      onClick={onClick}
    >
      <Chevron
        disabled={isDisabled}
        colour={isDisabled ? 'bloomGreyNavy700' : 'bloomBaseNavy800'}
        direction={chevronDirection}
      />
    </div>
  );
}
