import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

function trackEvent(
  property: Property,
  action: Action,
  label?: string,
  value?: number
): void {
  trackStructuredEvent({
    category: 'patch_test',
    property,
    action,
    label,
    value,
  });
}

enum Property {
  Info = 'patch_test_info',
  Understood = 'patch_test_info_understood ',
}

enum Action {
  View = 'view',
  Click = 'click',
}

export const trackShowPatchTest = (patchTest: boolean) =>
  patchTest
    ? trackEvent(Property.Info, Action.View, 'show')
    : trackEvent(Property.Info, Action.View, 'hide');
export const trackPatchTestInfo = () => trackEvent(Property.Info, Action.Click);
export const trackPatchTestUnderstood = () =>
  trackEvent(Property.Understood, Action.Click);
