import React from 'react';
import clsx from 'clsx';

import { Chevron, Direction } from 'js/components/Icons/Chevron/Chevron';
import { PointyEnd } from 'js/components/generic/PointyEnd';
import { PriceRange } from 'js/helpers/price/PriceRange';
import { IconChecked, Text } from '@treatwell/ui';
import { ColorBloomBaseCoral600 } from '@treatwell/design-tokens';

import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { MenuGroup } from 'js/model/rainbow/venue/VenueOutput';
import { ServicePriceRangeCms } from 'js/helpers/price/ServicePriceRangeCms';
import { Context } from 'js/components/LocaleWrapper';
import styles from './MenuGroup.module.css';
import { isChurnedVenue } from '../LocalMenuItem';

interface Props {
  channel: ChannelOutput;
  group: MenuGroup;
  isSelected: boolean;
  isActive: boolean;
  isSuggested: boolean;
  onClick: () => void;
}

export function MenuGroupHeader({
  channel,
  group,
  isSuggested,
  isActive,
  isSelected,
  onClick,
}: Props): React.ReactElement {
  const priceRange = new PriceRange(group.priceRange);
  return (
    <div
      className={clsx(styles.header, { [styles.open]: !!isActive })}
      onClick={onClick}
      data-cy="menu-group-header"
    >
      <div className={styles.name}>
        <Text className={styles.groupName}>{group.name}</Text>
        {!!group.menuItems.length && (
          <Text className={styles.amount}>({group.menuItems.length})</Text>
        )}
      </div>
      <Context.Consumer>
        {value =>
          !isChurnedVenue(value) && (
            <>
              <div className={styles['group-icon']}>
                {(isSelected && (
                  <div className={styles['group-added']}>
                    <IconChecked size={16} fill={ColorBloomBaseCoral600} />
                  </div>
                )) ||
                  (priceRange.hasYieldDiscount() && (
                    <div className={styles.discounts}>
                      <div className={styles.discountIcon} />
                    </div>
                  ))}
              </div>
              <div className={styles.price}>
                {isSuggested
                  ? ''
                  : priceRange.getPriceWithPrefix(
                      channel,
                      (value.i18n(
                        'page.venue.menu.labels'
                      ) as unknown) as ServicePriceRangeCms
                    )}
              </div>
            </>
          )
        }
      </Context.Consumer>
      {!!isActive && (
        <div className={styles['pointy-end']}>
          <PointyEnd />
        </div>
      )}
      <div className={styles.chevronContainer}>
        <Chevron
          colour="bloomGreyNavy500"
          direction={isActive ? Direction.Up : Direction.Down}
          animated
        />
      </div>
    </div>
  );
}
