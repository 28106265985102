import React from 'react';

import { Context } from 'js/components/LocaleWrapper';
import { VenueMenu } from './VenueMenu/VenueMenu';

export function VenueMenuSection(): React.ReactElement | null {
  return (
    <Context.Consumer>
      {value =>
        value.pageData.venue.venue.menu.menuGroups.length > 0 ? (
          <VenueMenu
            menu={value.pageData.venue.venue.menu}
            pageData={value.pageData}
            isWidget={value.isWidget}
            venueId={value.pageData.venue.venue.id}
            i18n={value.i18n}
          />
        ) : null
      }
    </Context.Consumer>
  );
}
