import { isBrowser } from 'js/helpers/environment';
import { queryToObj } from 'js/helpers/uri-util';
import { VenueOutput } from 'js/model/rainbow/venue/VenueOutput';

const campaignQueryParameterName = 'utm_campaign';
const campaignCookieName = 'utmCampaign';
const pixelIdCookieName = 'fbePixelId';

/**
 * If there is a query parameter of 'utm_campaign', set a
 * session cookie with the query parameter's value.
 *
 * The cookie is referenced in GTM triggers.
 */
export function setUtmCampaignCookie(): void {
  if (!isBrowser) {
    return;
  }

  // Get the query parameter value, if there is such a parameter.
  const query = queryToObj(window.location.search);
  if (!query[campaignQueryParameterName]) {
    clearCookie(campaignCookieName);
    return;
  }

  // Persist the campaign name.
  const campaign = query[campaignQueryParameterName];
  setCookie(campaignCookieName, campaign);
}

/**
 * If there is a Facebook Business Extension (FBE) pixel ID, set a session cookie
 * with the ID.
 *
 * The cookie is referenced in GTM triggers.
 */
export function setFBEPixelIdCookie(venue: VenueOutput): void {
  if (!isBrowser) {
    return;
  }

  if (!venue.pixelId) {
    clearCookie(pixelIdCookieName);
    return;
  }

  // Persist the pixel ID.
  setCookie(pixelIdCookieName, venue.pixelId);
}

function clearCookie(name: string): void {
  setCookie(name, '');
}

function setCookie(name: string, value: string): void {
  document.cookie = `${name}=${value}; Secure; SameSite=Strict; path=/`;
}
