import React from 'react';

import { ChannelOutput } from 'js/model/rainbow/content/ChannelOutput';
import { EmployeeLevelItem } from 'js/model/rainbow/venue/EmployeeOutput';
import { EmployeeLevelListItem } from './EmployeeLevelListItem';

import styles from './EmployeeLevelList.module.css';

interface Props {
  items: EmployeeLevelItem[];
  showDuration: boolean;
  channel: ChannelOutput;
  i18n: (key: string, count?: number | string) => string;
  selectId: (id: string) => void;
}

export function EmployeeLevelList(props: Props): React.ReactElement {
  const [selectedItemId, setSelectedItemId] = React.useState<string>(
    props.items[0].id
  );

  function handleChange(value: string): void {
    props.selectId(value);
    setSelectedItemId(value);
  }

  const { items, channel, showDuration, i18n } = props;

  return (
    <div>
      {items.map((item, index) => (
        <div key={index} className={styles.listItem}>
          <EmployeeLevelListItem
            data={item}
            isSelected={item.id === selectedItemId}
            onChange={handleChange}
            showDuration={showDuration}
            channel={channel}
            i18n={i18n}
          />
        </div>
      ))}
    </div>
  );
}
