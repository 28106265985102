import React from 'react';
import { Inline, Stack, IconCompass } from '@treatwell/ui';
import styles from './TreatmentGuide.module.css';

interface Props {
  treatmentGuideDescription?: string;
  treatmentGuideUrl?: string;
  treatmentGuideLabel?: string;
  showTreatmentGuideLink?: boolean;
  requiresPatchTest?: boolean;
}

export function TreatmentGuide(props: Props): React.ReactElement | null {
  const {
    treatmentGuideDescription,
    treatmentGuideUrl,
    treatmentGuideLabel,
    showTreatmentGuideLink = false,
  } = props;

  const showGuideLink =
    showTreatmentGuideLink && treatmentGuideUrl && treatmentGuideLabel;

  if (!treatmentGuideDescription && !showGuideLink) {
    return null;
  }

  return (
    <Inline space="lg" align="start" className={styles.container}>
      <IconCompass size={16} className={styles.icon} />
      <Stack className={styles.content} space="xs">
        {treatmentGuideDescription && <div>{treatmentGuideDescription}</div>}
        {showGuideLink && (
          <a className={styles.link} href={treatmentGuideUrl}>
            {treatmentGuideLabel}
          </a>
        )}
      </Stack>
    </Inline>
  );
}
