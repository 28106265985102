import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

enum Property {
  Expand = 'expand',
  Collapse = 'collapse',
  On = 'on',
  Off = 'off',
}

enum Action {
  SelectRating = 'select_rating',
  SelectTreatment = 'select_treatment',
}

const trackReviewFilterEvent = (
  action: Action | string,
  value?: number,
  property?: Property,
  label?: string,
  category = 'venue_review_filter'
) =>
  trackStructuredEvent({
    category,
    action,
    value,
    property,
    label,
  });

export const trackRatingSelect = (rating: number, selected: boolean) => {
  const property = selected ? Property.On : Property.Off;

  return trackReviewFilterEvent(Action.SelectRating, rating, property);
};

export const trackTreatmentSelect = (treatment: string | number) => {
  const value = treatment === 'ALL' ? 0 : Number(treatment);

  return trackReviewFilterEvent(Action.SelectTreatment, value);
};

export const trackShowMore = () =>
  trackReviewFilterEvent(
    'show_more',
    undefined,
    undefined,
    undefined,
    'venue_review_list'
  );

export const trackResetFilters = () => trackReviewFilterEvent('reset');

export const trackRatingToggle = (isExpanded: boolean) => {
  const property = isExpanded ? Property.Expand : Property.Collapse;

  return trackReviewFilterEvent('rating_toggle', undefined, property);
};
