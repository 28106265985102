import {
  ALL_TREATMENT_TYPES_ID,
  BODY_ID,
  FACE_ID,
  HAIR_ID,
  HAIR_REMOVAL_ID,
  MASSAGE_ID,
  NAILS_ID,
  YOGA_PILATES_ID,
  DANCE_ID,
  FITNESS_ID,
  PHYSICAL_THERAPY_ID,
  COUNSELLING_HOLISTIC_ID,
  MEDICAL_DENTAL_ID,
} from 'js/constants/treatment-types';
import { Icon } from './TreatmentTypeIcon';

export function mapTreatmentTypeIdToIcon(
  treatmentTypeId: number,
  defaultIcon: Icon = Icon.All
): Icon {
  switch (treatmentTypeId) {
    case ALL_TREATMENT_TYPES_ID:
      return Icon.All;
    case BODY_ID:
      return Icon.Body;
    case FACE_ID:
      return Icon.Face;
    case HAIR_ID:
      return Icon.Hair;
    case HAIR_REMOVAL_ID:
      return Icon.HairRemoval;
    case MASSAGE_ID:
      return Icon.Massage;
    case NAILS_ID:
      return Icon.Nails;
    case YOGA_PILATES_ID:
      return Icon.YogaPilates;
    case DANCE_ID:
      return Icon.Dance;
    case FITNESS_ID:
      return Icon.Fitness;
    case PHYSICAL_THERAPY_ID:
      return Icon.PhysicalTherapy;
    case COUNSELLING_HOLISTIC_ID:
      return Icon.CounsellingHolistic;
    case MEDICAL_DENTAL_ID:
      return Icon.MedicalDental;
    default:
      return defaultIcon;
  }
}
