export type DotType =
  // indicates there are more pages
  | 'smallDot'
  // unselected (not current) page
  | 'dot'
  // selected (current) page
  | 'selectedDot';

type DotsConstraints = {
  // The total number of dots to render.
  totalDots: number;
  // The maximum number of dots that are to be visible.
  visibleDots: number;
  // The index of the dot that is to rendered as the selected dot.
  currentIndex: number;
  // The index of the dot that was previously rendered as the selected dot.
  previousIndex: number;
  // See the comments on selectedDotPosition in RenderableDots.
  previousSelectedDotPosition: number;
};

type RenderableDots = {
  firstVisibleIndex: number;
  // The index in the visible dots of the selected dot.
  // For example, if the visible dots are ". ○ ○ ● .", then the
  // value will be 3.
  selectedDotPosition: number;
  dots: DotType[];
};

export function renderableDots({
  totalDots,
  visibleDots,
  currentIndex,
  previousIndex,
  previousSelectedDotPosition,
}: DotsConstraints): RenderableDots {
  const dots = new Array<DotType>(totalDots).fill('dot');
  const scrollingRight = currentIndex >= previousIndex;

  let firstVisibleIndex: number;

  if (scrollingRight) {
    // scrolling right

    firstVisibleIndex = 0;

    if (currentIndex >= visibleDots - 2) {
      // The preferred position in the visible dots is
      // the penultimate position
      //
      //  ○ ○ ○ ○ ● ○
      const preferredFirstVisibleIndex = currentIndex - (visibleDots - 2);

      firstVisibleIndex = Math.max(0, preferredFirstVisibleIndex);
      firstVisibleIndex = Math.min(
        totalDots - visibleDots,
        preferredFirstVisibleIndex
      );
    }
  } else {
    // scrolling left

    firstVisibleIndex = totalDots - visibleDots;

    if (currentIndex <= totalDots - visibleDots) {
      // The preferred position in the visible dots is
      // the 2nd position
      //
      //  ○ ● ○ ○ ○ ○
      const preferredFirstVisibleIndex = currentIndex - 1;

      firstVisibleIndex = Math.max(0, preferredFirstVisibleIndex);
    }
  }

  let selectedDotPosition = currentIndex - firstVisibleIndex;

  /*
   * Ensure that the selected dot does not move more than one
   * position in the visibles dots compared with previously.
   * This could happen when switching navigation direction.
   *
   * Consider thathe previously visible dots might be these (having been
   * navigating to the right).
   *    . ○ ○ ○ ● .
   * The start navigating left. It would be a jaring UX to jump to this.
   *    . ● ○ ○ ○ .
   * Instead we want this.
   *    . ○ ○ ● ○ .
   * And navigating left again.
   *    . ○ ● ○ ○ .
   * And navigating left again.
   *    . ○ ● ○ ○ .
   * And navigating left again, it stays the same.
   *    . ○ ● ○ ○ .
   */
  if (scrollingRight) {
    // scrolling right

    if (selectedDotPosition > previousSelectedDotPosition + 1) {
      firstVisibleIndex +=
        selectedDotPosition - previousSelectedDotPosition - 1;
      selectedDotPosition -=
        selectedDotPosition - previousSelectedDotPosition - 1;
    }
  } else if (selectedDotPosition < previousSelectedDotPosition - 1) {
    // scrolling left
    firstVisibleIndex -= previousSelectedDotPosition - selectedDotPosition - 1;
    selectedDotPosition +=
      previousSelectedDotPosition - selectedDotPosition - 1;
  }

  // Navigating to page 0 from a page number greater than the number of
  // visible dots can trigger some edge cases. Rather than complicate
  // the calculations above further, take a somewhat brute force approach
  //
  // Navigating to page 0 can happen in VenuePortfolioCarouselGallery.tsx
  // when the window is resized, so it's important to make sure that works.
  if (currentIndex === 0) {
    firstVisibleIndex = 0;
    selectedDotPosition = 0;
  }

  if (firstVisibleIndex > 0) {
    // There are more dots to the left of the first visible dot.
    dots[firstVisibleIndex] = 'smallDot';
  }

  if (firstVisibleIndex + visibleDots < totalDots) {
    dots[firstVisibleIndex + visibleDots - 1] = 'smallDot';
  }

  // Set the selected dot.
  dots[firstVisibleIndex + selectedDotPosition] = 'selectedDot';

  return {
    firstVisibleIndex,
    selectedDotPosition,
    dots,
  };
}
