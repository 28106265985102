import React from 'react';
import { Chevron } from 'js/components/Icons/Chevron/Chevron';

import styles from './ShowMoreLink.module.css';

interface Props {
  title: string;
  isMore: boolean;
  onClick: () => void;
}

export function ShowMoreLink({
  title,
  isMore,
  onClick,
}: Props): React.ReactElement | null {
  return isMore ? (
    <div
      className={styles.showMoreLink}
      onClick={onClick}
      data-cy="show-more-link"
    >
      <Chevron colour="bloomBaseCoral600" containerStyle={{ marginRight: 8 }} />
      {title}
    </div>
  ) : null;
}
