import PropTypes from 'prop-types';
import React, { Component } from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import clsx from 'clsx';
import gridStyles from 'assets/style/grid.module.css';
import getNumberOfColumns from './number-of-columns';
import styles from './style.module.css';
import blockStyles from './Block.module.css';
import { Context } from '../LocaleWrapper';

class InfoColumnBlocks extends Component {
  static propTypes = {
    title: PropTypes.string,
    blocks: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    customBlock: PropTypes.object,
    contentBlock: PropTypes.any,
    onClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      nrColumns: 1,
    };
    this.getBlock = this.getBlock.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  componentWillUnmout() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize() {
    this.setState({
      nrColumns: getNumberOfColumns(),
    });
  }

  // Insert a custom block, at an index that will position it at as the
  // first item in the last column.
  insertCustomBlock(blocks, customBlock) {
    const blockCount = blocks.length;
    const customBlockIndex = Math.min(this.state.nrColumns - 1, blockCount);

    const blocksBeforeCustom = blocks.slice(0, customBlockIndex);
    const blocksAfterCustom = blocks.slice(customBlockIndex, blockCount);

    return [
      ...blocksBeforeCustom,
      {
        type: 'custom',
        component: customBlock,
      },
      ...blocksAfterCustom,
    ];
  }

  orderBlocks(blocks, customBlock) {
    const allBlocks = customBlock
      ? this.insertCustomBlock(blocks, customBlock)
      : blocks;
    // Create an empty array for each column.
    const columns = [...new Array(this.state.nrColumns)].map(() => []);

    //  Distribute the blocks across the columns.
    allBlocks.forEach((block, index) => {
      const columnsIndex = index % this.state.nrColumns;

      columns[columnsIndex].push(block);
    });

    return columns;
  }

  getBlock(columnIndex, rowIndex, block) {
    const { onClick = () => {} } = this.props;
    const blockType = block.type || 'default';
    const key = `${columnIndex}=${rowIndex}`;

    if (blockType === 'custom') {
      return (
        <div key={key} className={blockStyles.block}>
          {block.component}
        </div>
      );
    }
    if (!block) {
      return null;
    }

    return React.createElement(this.props.contentBlock, {
      ...block,
      i18n: this.context.i18n,
      key,
      onClick: () => {
        onClick(block.id, key);
      },
    });
  }

  generateColumnBlocks(columns) {
    return columns.map((column, index) => {
      const blockClasses = clsx(
        styles.columns,
        gridStyles['col-xs-12'],
        gridStyles['col-sm-6'],
        gridStyles['col-md-4']
      );

      return (
        <div key={index} className={blockClasses}>
          {column.map((item, row) => this.getBlock(index, row, item))}
        </div>
      );
    });
  }

  render() {
    const { title, blocks, customBlock } = this.props;

    const titleNode = title ? <h2 className={styles.title}>{title}</h2> : null;
    const columns = this.orderBlocks(blocks, customBlock);
    const generatedColumns = this.generateColumnBlocks(columns);

    if ((!blocks || !blocks.length) && !customBlock) {
      // No blocks or custom block. Nothing to render.
      return null;
    }

    return (
      <section className={styles.blocks}>
        {titleNode}
        {generatedColumns}
      </section>
    );
  }
}

InfoColumnBlocks.contextType = Context;

export default InfoColumnBlocks;
