import { Amenities } from 'js/components/Amenities/Amenities';
import { choiceFormatter } from 'js/helpers/choice-formatter';
import { CmsBrowsePage } from 'js/model/cms/cms-browse-page';
import { CmsCommonPopup } from 'js/model/cms/cms-common';
import { CmsOurWork, CmsVenuePage } from 'js/model/cms/cms-venue-page';
import { StateData } from 'js/model/rainbow/StateData';
import { createVenueTeamSectionViewModel } from 'js/model/view/venue-page/venue-team-section';
import { VenueReviewsSection } from 'js/pages/VenuePage/VenueReviewsSection';
import { interleaveImages } from 'js/pages/VenuePage/interleave-images';
import React from 'react';
import { BreadcrumbsSection } from './BreadcrumbsSection/BreadcrumbsSection';
import { PopupsSection } from './PopupsSection/PopupsSection';
import { ProductsSection } from './ProductsSection/ProductsSection';
import { ProximityVenuesSection } from './ProximityVenuesSection/ProximityVenuesSection';
import { VenueAboutSection } from './VenueAboutSection';
import { VenueHeader } from './VenueHeader/VenueHeader';
import { VenueMenuSection } from './VenueMenuSection/VenueMenuSection';
import { VenuePortfolioCarouselGallery } from './VenuePortfolioGallery/VenuePortfolioCarouselGallery';
import { VenueTeamSection } from './VenueTeamSection';
import { VisitConfiguratorBar } from './VisitConfiguratorBar/VisitConfiguratorBar';
import styles from './styles.module.css';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  i18n: (key: string, count?: number | string) => any;
  pageData: StateData & { isMobileDevice: boolean };
  generateUri: (pageType: string, ...args: unknown[]) => string;
}

export function ActiveVenue({
  i18n,
  pageData,
  generateUri,
}: Props): React.ReactElement {
  const channel = pageData.channel;
  const venuePageData = pageData.venue;
  const venue = venuePageData.venue;
  const teamSectionViewModel = createVenueTeamSectionViewModel(
    venue.employees,
    pageData.cms.page.venue['venue-team-section'],
    i18n('common.popup') as CmsCommonPopup
  );

  const employeePortfolioImages = venue.employees.map(
    employee => employee.portfolioImages
  );
  const allEmployeesPortfolioImages = interleaveImages(employeePortfolioImages);

  return (
    <div data-venue-id={venue.id}>
      <div className={styles.divider} />
      <div className={styles.wrapper}>
        <VenueHeader
          venue={venue}
          channelData={channel}
          cmsCommon={pageData.cms.common}
          cmsVenue={pageData.cms.venue}
          cmsHeaderNavigation={
            i18n(
              'page.venue.header-navigation'
            ) as CmsVenuePage['page']['venue']['header-navigation']
          }
          cmsChainTemplate={i18n('page.venue.details.chain') as string}
          hasReviews={venuePageData.hasReviews}
        />
        <VenueMenuSection />
        {allEmployeesPortfolioImages.length > 0 && (
          <VenuePortfolioCarouselGallery
            pageData={pageData}
            images={allEmployeesPortfolioImages}
            cms={
              {
                ...(i18n('page.venue.our-work') as {}),
                ...(i18n('page.venue.head.review-text.short') as {}),
                ...(i18n('page.venue.vc-bar') as {}),
                ...(i18n('page.venue.menu.labels') as {}),
              } as CmsOurWork
            }
          />
        )}
        <VenueReviewsSection />
        <VenueTeamSection {...teamSectionViewModel} />
        <Amenities
          cms={{
            sectionTitle: i18n('page.venue.amenities.amenities') as string,
            buttonLabel: amenityCount =>
              `${(i18n(
                'page.venue.amenities.see-all-amenities'
              ) as string).replace(
                '{0}',
                choiceFormatter(
                  i18n('page.venue.amenities.amenity-pluralisation') as string,
                  amenityCount
                )
              )}`,
            popupTitle: i18n('page.venue.amenities.all-amenities') as string,
            popupCloseText: i18n('common.popup.close-button') as string,
          }}
          amenityGroups={venue.amenityGroups}
        />
        <VenueAboutSection
          venue={pageData.venue.venue}
          openingHoursClosedLabel={i18n('venue.opening-hours.closed') as string}
          dayNames={
            i18n('common.day-names') as CmsBrowsePage['common']['day-names']
          }
          dayNamesShort={
            i18n(
              'common.calendar.day-names-short'
            ) as CmsBrowsePage['common']['calendar']['day-names-short']
          }
          countryCode={pageData.channel.country.countryCode}
          channelCode={pageData.channel.code}
          titleAbout={i18n('page.venue.section-titles.about') as string}
        />
        <ProductsSection />
        <BreadcrumbsSection />
        <ProximityVenuesSection />
        <PopupsSection
          serviceImages={allEmployeesPortfolioImages}
          pageData={pageData}
        />
      </div>
      <VisitConfiguratorBar
        channel={channel}
        cms={pageData.cms.page.venue}
        generateUri={generateUri}
        pageData={pageData}
      />
    </div>
  );
}
