import React from 'react';
import styles from './ServiceLoading.module.css';

export function ServiceLoading(): React.ReactElement {
  return (
    <div className={styles.container}>
      <div className={styles.loading} />
    </div>
  );
}
