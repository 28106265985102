import React from 'react';
import clsx from 'clsx';
import { Inline, Text, Rating } from '@treatwell/ui';
import { Chevron, Direction } from 'js/components/Icons/Chevron/Chevron';
import styles from './TeamListItemHeading.module.css';

export enum ClickArea {
  Reviews,
  Details,
  Chevron,
}

interface Props {
  id: number;
  image: string;
  initials: string;
  name: string;
  rating: number;
  reviewCount: string;
  displayRating?: string;
  title: string;
  onClick?: (clickArea: ClickArea, isActive: boolean) => void;
  isActive: boolean;
}

export function TeamListItemHeading(props: Props): React.ReactElement {
  const onDetailsClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    if (props.onClick) {
      props.onClick(ClickArea.Details, props.isActive);
    }
  };

  const onReviewsClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    if (props.onClick) {
      props.onClick(ClickArea.Reviews, props.isActive);
    }
  };

  const onChevronClick = (event: React.MouseEvent<HTMLElement>): void => {
    if (props.onClick) {
      event.stopPropagation();
      props.onClick(ClickArea.Chevron, props.isActive);
    }
  };

  const {
    id,
    image,
    initials,
    name,
    rating,
    displayRating,
    reviewCount,
    title,
    isActive,
  } = props;

  const imageBackground = image
    ? {
        style: {
          backgroundImage: `url('${image}')`,
        },
      }
    : {};
  const containerClassNames = clsx(styles.member, {
    [styles.active]: isActive,
  });

  const detailsClassNames = clsx(styles.details, {
    [styles.active]: isActive,
  });

  return (
    <button
      id={`team-member-${id}-tab`}
      className={containerClassNames}
      onClick={onDetailsClick}
      role="tab"
      aria-selected={props.isActive}
    >
      <Inline align="center" splitAfter={0}>
        <div className={detailsClassNames}>
          <Inline align="center">
            <div className={styles.selectedIndicator} />
            <Inline
              align="center"
              justify="center"
              className={styles.image}
              {...imageBackground}
            >
              <Text as="div" type="mdHeader">
                {!image && initials}
              </Text>
            </Inline>
            <div>
              <Text as="h3" type="bodyHeavy" className={styles.name}>
                {name}
              </Text>
              {title && (
                <Text as="div" type="caption" className={styles.title}>
                  {title}
                </Text>
              )}
            </div>
          </Inline>
        </div>
        <div className={styles.ratingContainer} onClick={onReviewsClick}>
          {rating > 0 && (
            <div className={styles.rating}>
              <Rating
                rating={displayRating || rating.toPrecision(2)}
                size={16}
                showRatingValue
              />
            </div>
          )}
          {reviewCount && (
            <Text as="div" type="caption" className={styles.reviews}>
              {reviewCount}
            </Text>
          )}
        </div>
        <span className={styles.chevronContainer} onClick={onChevronClick}>
          <Chevron
            containerStyle={{ marginLeft: 15 }}
            colour="bloomGreyNavy500"
            direction={isActive ? Direction.Up : Direction.Down}
            animated
          />
        </span>
      </Inline>
    </button>
  );
}
