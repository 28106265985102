import { trackStructuredEvent } from 'js/helpers/google-tag-manager';

enum Category {
  Venue = 'venue',
  VenueAmenities = 'venue_amenities',
}

enum Property {
  Amenities = 'amenities',
  SeeMore = 'see_more',
}

enum Action {
  View = 'view',
  Click = 'click',
}

function trackEvent(
  category: Category,
  property: Property,
  action: Action | string,
  label?: string,
  value?: number
): void {
  trackStructuredEvent({
    category,
    property,
    action,
    label,
    value,
  });
}

export function trackScrollAmenitiesIntoView(
  initiallyVisible: string,
  totalNumber: number
): void {
  trackEvent(
    Category.Venue,
    Property.Amenities,
    Action.View,
    initiallyVisible,
    totalNumber
  );
}

export function trackSeeMoreClicked(
  initiallyVisible: string,
  totalNumber: number
): void {
  trackEvent(
    Category.VenueAmenities,
    Property.SeeMore,
    Action.Click,
    initiallyVisible,
    totalNumber
  );
}
