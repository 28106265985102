import React from 'react';
import { Carousel } from 'js/components/generic/Carousel';
import { Context } from 'js/components/LocaleWrapper';
import { NavigateBackLinkAsButton } from 'js/components/Header/Experiment';
import { t } from 'i18next';
import { generateNavigationTextAndLink } from 'js/components/Header/VenuePageHeader/VenuePageHeaderMobileContainer';
import styles from './style.module.css';
import { CarouselAccoladeBadge } from './AccoladeBadge';

interface Props {
  children?: React.ReactNode;
  isMobile?: boolean;
  [key: string]: unknown;
}

export function ImageGallery(props: Props): React.ReactElement {
  const { ...rest } = props;

  return (
    <Context.Consumer>
      {value => {
        const {
          navigateLink,
          navigateText,
          onNavigateLinkClick,
        } = generateNavigationTextAndLink({
          pageData: value.pageData,
          t,
          i18n: value.i18n,
        });
        const showBackButton =
          value.pageData.showVenueHeaderExperiment &&
          value.pageData.venuePageReferrer &&
          props.isMobile;

        return (
          <div className={styles.container}>
            <Carousel
              images={value.pageData.venue.venue.images || []}
              className={styles.carousel}
              altAttr={[
                value.pageData.venue.venue.name,
                value.pageData.venue.venue.location.tree.name,
              ].join(', ')}
              {...rest}
            />

            <CarouselAccoladeBadge
              accolades={value.pageData.venue.venue.accolades || []}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              cms={(value.pageData.cms as any).entities.accolade}
            />

            {showBackButton && (
              <div className={styles.backButtonPosition}>
                <NavigateBackLinkAsButton
                  navigateLink={navigateLink}
                  navigateText={navigateText}
                  onNavigateLinkClick={onNavigateLinkClick}
                />
              </div>
            )}
          </div>
        );
      }}
    </Context.Consumer>
  );
}
