import { Rating } from '@treatwell/ui';
import { formatAddressInline } from 'js/helpers/formatters';
import PropTypes from 'prop-types';
import React from 'react';
import { Context } from '../LocaleWrapper';

import styles from './Block.module.css';

const IMAGE_SIZE = '360x240';

interface Props {
  name: string;
  normalisedName: string;
  channelCode: string;
  location: {
    address: {
      addressLines: string[];
      postalCode: string;
    };
  };
  primaryImage: {
    uris: {
      '360x240': string;
    };
  };
  rating: {
    count: number;
    average: string | number;
  };
  onClick: () => Promise<void>;
}

export class NearbyVenueBlock extends React.Component<Props> {
  static contextType = Context;

  declare context: React.ContextType<typeof Context>;

  // TODO remove propTypes once consumer is TypeScript
  public static readonly propTypes = {
    name: PropTypes.string,
    normalisedName: PropTypes.string,
    channelCode: PropTypes.string,
    location: PropTypes.object,
    primaryImage: PropTypes.object,
    rating: PropTypes.object,
    onClick: PropTypes.func,
  };

  public static readonly defaultProps = {
    onClick: () => Promise.resolve(),
  };

  public render(): React.ReactNode {
    const {
      name,
      normalisedName,
      channelCode,
      location,
      primaryImage,
      rating,
      onClick,
    } = this.props;
    const { generateUri, i18n } = this.context;
    const uri = generateUri('venue', { normalisedName });
    const imageUri = primaryImage.uris[IMAGE_SIZE];
    const reviewCount = !rating.count
      ? i18n('venue.reviews.no-reviews')
      : i18n('venue.reviews.review-count', rating.count);
    const inlineAddress = formatAddressInline(
      location.address.addressLines,
      location.address.postalCode,
      channelCode
    );

    return (
      <div
        className={styles.block}
        onClick={async () => {
          await onClick();
          window.location = uri;
        }}
      >
        <img className={styles.image} src={imageUri} />
        <h3 className={styles.title}>
          <a className={styles.link} href={uri}>
            {name}
          </a>
        </h3>
        <div className={styles.rating}>
          {rating.count ? (
            <div className={styles.ratingStars}>
              <Rating
                rating={rating.average}
                size={16}
                showRatingValue={false}
              />
            </div>
          ) : null}
          <span className={styles.reviewCountMessage}>{reviewCount}</span>
        </div>
        <div className={styles.description}>{inlineAddress}</div>
      </div>
    );
  }
}

NearbyVenueBlock.contextType = Context;
