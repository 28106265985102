import React from 'react';
import { Stack, Text } from '@treatwell/ui';
import { HorizontalRule } from 'js/components/HorizontalRule/HorizontalRule';
import { Portal } from './Portal/Portal';
import { ProgressBar } from './ProgressBar/ProgressBar';
import styles from './HandoverModal.module.css';

interface Props {
  cms: {
    heading: string;
    message: string;
  };
}

export function HandoverModal({ cms }: Props) {
  return (
    <Portal id="handover-modal">
      <div className={styles.overlay} />
      <div className={styles.modal}>
        <div className={styles.container}>
          <HorizontalRule colorScheme="orange-pink-blue" />
          <Stack space="xl" className={styles.content}>
            <Text as="div" type="lgHeader">
              {cms.heading}
            </Text>
            <div className={styles.treatwellUalaRedirect} />
            <Text as="div">{cms.message}</Text>
            <ProgressBar />
          </Stack>
        </div>
      </div>
    </Portal>
  );
}
