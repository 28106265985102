import * as React from 'react';
import { LazyImage } from '@treatwell/ui';
import { Image } from 'js/model/rainbow/ImageOutput';

/**
 * The way in which sizes are chosen depends directly on how they are displayed on the page
 */
const CAROUSEL_IMAGE_SIZES = {
  /**
   * Instructions under class `.image`
   *
   * Ref: src/js/components/generic/Carousel.module.css
   */
  VENUE_PAGE:
    '(max-width: 519px) 100vw, (max-width: 1159px) calc((100vw - 64px) / 1.8), calc(1096px / 1.8)',
  /**
   * Instructions under class `.imageAreaContainer`
   *
   * Ref: src/js/pages/BrowsePage/BrowseResult/BrowseResultSummary.module.css
   */
  BROWSE_PAGE: '(max-width: 519px) calc(100vw - 34px), 300px',
} as const;

type CarouselImageSizesType = keyof typeof CAROUSEL_IMAGE_SIZES;

interface Props {
  imageData: Image;
  className: string;
  sizes: CarouselImageSizesType;
  alt?: string;
  isLazy?: boolean;
}

export const LazyCarouselImage = ({
  imageData,
  alt,
  className,
  sizes,
  isLazy = true,
}: Props) => {
  const imgProps = {
    className,
    sizes: CAROUSEL_IMAGE_SIZES[sizes],
    srcSet: `${imageData['360x240']} 360w, ${imageData['720x480']} 720w, ${imageData['1080x720']} 1080w, ${imageData['1280x800']} 1280w`,
    src: imageData['1080x720'],
    alt: alt ?? '',
  };

  if (isLazy) {
    return <LazyImage {...imgProps} />;
  }

  return <img {...imgProps} fetchpriority="high" />;
};
