import React, { useContext } from 'react';
import { PageSection } from 'js/components/PageSection';
import { Context } from 'js/components/LocaleWrapper';
import { ProductsAccoladesSection } from './ProductsAccoladesSection';

export const ProductsSection = () => {
  const { pageData } = useContext(Context);
  const { venue } = pageData.venue;
  const { productBrands, accolades } = venue;

  if (productBrands.length === 0 && accolades.length === 0) {
    return null;
  }

  return (
    <PageSection id="products">
      <ProductsAccoladesSection
        products={productBrands}
        accolades={accolades}
      />
    </PageSection>
  );
};
