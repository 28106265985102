import React from 'react';
import { ColorBloomTeal700 } from '@treatwell/design-tokens';

interface Props {
  size?: number;
}

const defaultValues = {
  colourTW: ColorBloomTeal700,
  size: '16',
};

export function VerifiedReviewIcon({ size }: Props): React.ReactElement {
  const sizeTemp = size || defaultValues.size;
  const colour = defaultValues.colourTW;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={sizeTemp}
      height={sizeTemp}
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="a"
          d="M16.138 7.956l1.542 1.543-7.316 7.317L6.32 12.77l1.542-1.542 2.502 2.5 5.774-5.773zM12 21.818c5.422 0 9.818-4.396 9.818-9.818S17.422 2.182 12 2.182 2.182 6.578 2.182 12 6.578 21.818 12 21.818zM12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z"
        />
      </defs>
      <use fill={colour} fillRule="evenodd" href="#a" />
    </svg>
  );
}
