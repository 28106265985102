import React, { ForwardedRef } from 'react';
import clsx from 'clsx';
import { Text } from '@treatwell/ui';
import { Pagination } from 'js/components/Pagination/Pagination';
import { VenueReview } from 'js/pages/VenuePage/VenueReviewsSection/VenueReviews/VenueReview';
import { createVenueReviewViewModel } from 'js/model/view/venue-reviews/venue-review';
import { Channel } from 'js/model/rainbow/content/ChannelOutput';
import { PaginationOutput } from 'js/model/rainbow/PaginationOutput';
import { ReviewOutput } from 'js/model/ReviewOutput';
import { Context } from 'js/components/LocaleWrapper';
import { usePrevious } from 'js/hooks/usePrevious';
import styles from './VenueReviewsList.module.css';
import { trackShowMore } from './reviews-tracking';

interface Props {
  channelData: Channel;
  venueId: number;
  venueNormalisedName: string;
  reviews: ReviewOutput[];
  generateReviewsUri?: boolean;
  more: boolean;
  fetchFailed?: boolean;
  loading?: boolean;
  nextPage?: number;
  onLoadMoreReviews: (reviews?: ReviewOutput[]) => void;
  i18n: (key: string, count?: number | string) => string;
  showTreatmentNames?: boolean;
  pagination?: PaginationOutput;
  generatePaginationUri?: (pageNumber: number) => string;
  onPaginationClick?: (page: number) => void;
}

export const VenueReviewsList = React.forwardRef(
  (
    props: Props,
    ref: ForwardedRef<HTMLDivElement | null>
  ): React.ReactElement => {
    const [transitionToVisible, setTransitionToVisible] = React.useState<
      boolean
    >(true);
    const [loadMore, setLoadMore] = React.useState<boolean>(false);

    // On changing from not loading (reviews) to loading, update state to
    // cause rendering to use a css transition.
    //
    // Need to be careful to not do the transition when loading more reviews
    // for the list.

    const prevLoading = usePrevious(props.loading);
    React.useEffect(() => {
      if (!loadMore && transitionToVisible && props.loading && !prevLoading) {
        updateTransitionToVisibleState();
      }
    }, [props.loading, transitionToVisible, loadMore]);

    React.useEffect(() => {
      if (loadMore) {
        props.onLoadMoreReviews();
        setLoadMore(false);
      }
    }, [loadMore]);

    const context = React.useContext(Context);

    const updateTransitionToVisibleState = (): void => {
      setTransitionToVisible(false);
      setTimeout(() => {
        setTransitionToVisible(true);
      }, 100);
    };

    const handleLoadMore = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      event.preventDefault();

      if (props.loading) {
        return;
      }

      setLoadMore(true);
      trackShowMore();
    };

    const generateVenueReviewsUri = (): string | undefined => {
      if (props.nextPage === undefined) {
        return undefined;
      }
      return context.generateUri('venue', {
        normalisedName: props.venueNormalisedName,
        reviewPage: props.nextPage + 1,
      });
    };

    const renderReviews = (): React.ReactNode[] => {
      const { channelData, i18n, showTreatmentNames } = props;

      return props.reviews.map(review => (
        <VenueReview
          {...createVenueReviewViewModel(
            review,
            channelData,
            i18n,
            showTreatmentNames
          )}
          key={review.id}
        />
      ));
    };

    const loadMoreLink = (text: string): React.ReactNode => {
      if (props.generateReviewsUri === undefined) {
        return;
      }

      const venueReviewsUri = generateVenueReviewsUri();
      if (venueReviewsUri === undefined) {
        return;
      }

      return (
        <Text
          // @ts-ignore
          as="a"
          href={venueReviewsUri}
          className={styles['load-more']}
          onClick={handleLoadMore}
        >
          {text}
        </Text>
      );
    };

    const renderMoreTextSection = (
      i18n: (key: string, count?: number | string) => string
    ): React.ReactNode => {
      const { more, loading, fetchFailed } = props;

      if (!more) {
        return null;
      }

      if (loading) {
        return (
          <div className={styles.loading}>{i18n('common.labels.loading')}</div>
        );
      }

      if (fetchFailed) {
        return (
          <div>
            {i18n('common.labels.something-went-wrong')}{' '}
            {loadMoreLink(i18n('common.labels.try-again'))}
          </div>
        );
      }

      return loadMoreLink(i18n('venue.reviews.show-more'));
    };

    const renderPagination = (): React.ReactNode => {
      const {
        pagination,
        generatePaginationUri,
        onPaginationClick,
        loading,
        i18n,
      } = props;

      if (pagination === undefined || generatePaginationUri === undefined) {
        return;
      }

      if (loading) {
        return (
          <div className={styles.loadingPagination}>
            {i18n('common.labels.loading')}
          </div>
        );
      }

      return (
        <Pagination
          pagination={pagination}
          createPageUri={generatePaginationUri}
          onPageChange={onPaginationClick}
        />
      );
    };

    const { i18n, pagination, generatePaginationUri } = props;

    const classes = clsx({
      [styles.list]: true,
      [styles.hide]: !transitionToVisible,
      [styles.show]: transitionToVisible,
    });

    return (
      <div className={classes} ref={ref}>
        {renderReviews()}
        {pagination && generatePaginationUri
          ? renderPagination()
          : renderMoreTextSection(i18n)}
      </div>
    );
  }
);
